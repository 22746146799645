import { useTheme } from '@emotion/react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import PageHeader from 'components/Common/PageHeader';
import { PatientContext } from 'pages/Dashboard/AddPatientContext';
import AddPatientFormDetails from 'pages/Dashboard/AddPatientFormDetails';
import AddPatientWayPoints from 'pages/Dashboard/AddPatientWayPoints';
import * as React from 'react';

export default function AddPatientForm() {
  const theme = useTheme();
  const { addingPatient, state } = React.useContext(PatientContext);
  return (
    <Box sx={{ margin: { sm: theme.spacing(3) } }}>
      <PageHeader title='Add a patient' titleVariant='h4' />
      <Stack spacing={4}>
        <AddPatientFormDetails />
        <Divider />
        <AddPatientWayPoints />
        <Box>
          <Button
            color='success'
            variant='contained'
            type='submit'
            disabled={addingPatient || state.waypoints.length < 3}
            sx={{ width: { xs: '100%', md: '20%' } }}
          >
            ADD PATIENT
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}
