import { CreateWaypointPayload } from 'pages/Dashboard/types';
import { ListPatientWaypointsAPIResponse } from 'pages/Dashboard/types/waypointSuggestion.types';
import { ListWaypointAPIResponse } from 'pages/Dashboard/types/waypoints.types';
import { QueryFunctionContext } from 'react-query';
import { wayPointsUrls } from 'utils/apiUrls';
import { HttpType } from 'utils/http';

export const fetchWaypointList = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<ListWaypointAPIResponse> {
  const [, providerId] = queryKey;
  return (
    method(wayPointsUrls.listWayPoints.apiUrls(
      providerId as string,
    ).list)
  );
};

export const fetchPatientWaypointList = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<ListPatientWaypointsAPIResponse> {
  const [, patientId] = queryKey;
  return (
    method(wayPointsUrls.listPatientWaypoints.apiUrls(
      patientId as string,
    ).list)
  );
};

export const createWaypoint = (
  method: HttpType['post'],
  providerId: string,
  payload: CreateWaypointPayload,
) => method(wayPointsUrls.createWayPoint.apiUrls(
  providerId as string,
).create, payload);
