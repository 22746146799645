import { Box, Card, CardContent, Grid, Link, Typography } from '@mui/material';
import Spinner from 'components/Common/Spinner';
import { useHttp } from 'hooks/use-fetch';
import CardHeader from 'pages/Dashboard/components/CardHeader';
import NoData from 'pages/Dashboard/components/NoData';
import HealthRecordCategories from 'pages/Dashboard/HealthRecordCategories';
import { fetchLabListReport } from 'pages/Dashboard/services/labs.services';
import {
  ShowMoreButton,
  ShowMoreDrawerChildProps,
  ShowMoreDrawerTitle,
  sliceForShowMore,
} from 'pages/Dashboard/ShowMoreDrawer';
import { LabReport, LabReportAPIResponse } from 'pages/Dashboard/types/lab.types';
import * as React from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import theme from 'theme';
import { googleDateToDateString } from 'utils/dateUtils';
import { downloadUrl } from 'utils/service';

export interface LabCardProps extends ShowMoreDrawerChildProps {
  labReports?: LabReport[];
}

interface LabDetailsProps {
  labData: LabReport[];
  reportResponseIgnite: boolean;
  fetchPdf: (labId: string) => Promise<void>;
}

function LabDetails({ labData, reportResponseIgnite, fetchPdf }: LabDetailsProps): JSX.Element {
  const nonDatedLabReports = labData.filter((labs) => !labs.date);
  return (
    <>
      {labData.map((report) => {
        if (!report.date) return null;
        return (
          <Box key={`lab-${report.id}`} mb={2}>
            <Typography variant='body1' color={theme.custom.colors.lightTextSecondary}>{googleDateToDateString(report.date)}</Typography>
            <Typography>{report?.lab?.name}</Typography>
            {report.doctor && (
              <Typography variant='subtitle1' color={theme.custom.colors.lightTextSecondary}>
                {`${report.doctor.firstName} ${report.doctor.lastName}`}
              </Typography>
            )}
            <Link
              component='a'
              variant='subtitle1'
              underline='hover'
              sx={{ cursor: 'pointer' }}
              onClick={() => fetchPdf(report.id)}
            >
              {report.name}
            </Link>
          </Box>
        );
      })}
      {nonDatedLabReports.length ? (
        <>
          <Typography mb={2} variant='subtitle1'>
            Unknown dates
          </Typography>
          {nonDatedLabReports.map((report) => (
            <Box key={`lab-${report.id}`} mb={2}>
              <Typography>{report?.lab?.name}</Typography>
              {report.doctor && (
                <Typography variant='subtitle1'>
                  {`${report.doctor.firstName} ${report.doctor.lastName}`}
                </Typography>
              )}
              {reportResponseIgnite ? (
                <Spinner color='secondary' />
              ) : (
                <Link
                  component='a'
                  variant='subtitle1'
                  underline='hover'
                  sx={{ cursor: 'pointer' }}
                  onClick={() => fetchPdf(report.id)}
                >
                  {report.name}
                </Link>
              )}
            </Box>
          ))}
        </>
      ) : null}
    </>
  );
}

export default function LabCard({
  closeDrawer,
  drawerItem,
  drawerMode = false,
  labReports = [],
  onShowMore,
}: LabCardProps): JSX.Element {
  const { id } = useParams();
  const { http, providerId } = useHttp();
  const [fetchLabReportResponse, setFetchLabReportResponse] = React.useState<LabReportAPIResponse>(
    {} as LabReportAPIResponse,
  );

  const datedLabReports = labReports.filter((labs) => labs.date);
  const nonDatedLabReports = labReports
    .filter((labs) => !labs.date)
    .sort((a, b) => a.lab.name.localeCompare(b.lab.name));

  const sortedLabReports = [...datedLabReports, ...nonDatedLabReports];
  const data = sliceForShowMore(sortedLabReports, !drawerMode);

  const { mutate: fetchLabReport, isLoading } = useMutation({
    mutationFn: (labId: string) => fetchLabListReport(http.post, providerId, id as string, labId),
    onSettled: (d) => {
      setFetchLabReportResponse(d);
    },
  });

  React.useEffect(() => {
    if (fetchLabReportResponse?.signed_url && !isLoading) {
      downloadUrl(fetchLabReportResponse?.signed_url, '', true);
    }
  }, [fetchLabReportResponse?.signed_url, isLoading]);

  const fetchPdf = async (labId: string) => {
    fetchLabReport(labId);
  };

  return (
    <Grid item xs={12} md={!drawerMode ? 4 : undefined} key='labs'>
      <Card sx={{ height: '100%' }}>
        {!drawerMode && (
          <CardHeader
            icon={HealthRecordCategories.diagnosis.value.icon}
            title='Labs'
          />
        )}
        {drawerMode && <ShowMoreDrawerTitle closeDrawer={closeDrawer} drawerItem={drawerItem} />}
        <CardContent>
          {!labReports.length ? (
            <NoData />
          ) : (
            <LabDetails
              labData={data}
              reportResponseIgnite={isLoading || false}
              fetchPdf={fetchPdf}
            />
          )}
          <ShowMoreButton
            isDrawer={drawerMode}
            onShowMore={onShowMore as VoidFunction}
            items={labReports}
          />
        </CardContent>
      </Card>
    </Grid>
  );
}
