import { useTheme } from '@emotion/react';
import {
  Bedtime,
  Coffee,
  Fastfood,
  Female,
  FitnessCenter,
  Forest,
  KeyboardArrowDown,
  KeyboardArrowUp,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  LiveTv,
  LocationOn,
  People,
  SmokingRooms,
  ThumbUp,
  WbSunny,
  WineBar,
} from '@mui/icons-material';
import {
  styled,
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  IconProps,
  capitalize,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { PatientObj } from 'pages/Dashboard/types/patient.types';
import { WaypointSuggestion } from 'pages/Dashboard/types/waypointSuggestion.types';
import {
  GroupedWaypoints,
  WaypointTracking,
  WaypointValues,
} from 'pages/Dashboard/types/waypoints.types';
import {
  ArrowTypes,
  formatIntervalDate,
  getArrows,
  getTimeFrameInterval,
  getWaypointColorByIndex,
  getWaypointDataFromId,
  getWaypointFormattedValues,
} from 'pages/Dashboard/utils/trackingUtils';
import * as React from 'react';
import Theme from 'theme';

enum TableCategories {
  Substance = 'substance',
  Digital = 'digital',
  Behavior = 'behavior',
  Other = 'other',
  Custom = 'custom',
}

enum WaypointIconNames {
  Social = 'social',
  Diet = 'diet',
  Exercise = 'exercise',
  Sleep = 'sleep',
  Alcohol = 'alcohol',
  TV = 'television',
  Marijuana = 'marijuana',
  Caffeine = 'caffeine',
  Nicotine = 'nicotine',
  SocialMedia = 'socialmedia',
  Meditation = 'meditation',
  Libido = 'libido',
  Custom = 'Custom',
}

const tableCategoriesOrder = [
  TableCategories.Behavior,
  TableCategories.Substance,
  TableCategories.Digital,
  TableCategories.Other,
  TableCategories.Custom,
];

interface WaypointsTrackingTabelProps {
  timeFrame: string;
  currentWaypoints: WaypointTracking[];
  previousWaypoints: WaypointTracking[];
  allWaypoints: GroupedWaypoints;
  waypointSuggestions: WaypointSuggestion[];
  patient: PatientObj | null;
}

export default function WaypointsTrackingTable({
  timeFrame,
  currentWaypoints,
  previousWaypoints,
  allWaypoints: groupedWaypoints,
  patient,
  waypointSuggestions,
}: WaypointsTrackingTabelProps): JSX.Element {
  const theme = useTheme();
  const interval = getTimeFrameInterval(timeFrame);
  const allWaypoints = Object.keys(groupedWaypoints).reduce((acc, key) => {
    acc[key.toLowerCase()] = groupedWaypoints[key];
    return acc;
  }, {} as GroupedWaypoints);


  const getArrowSymbol = (currentValue: WaypointValues, prevValue: WaypointValues) => {
    const arrowType = getArrows(
      getWaypointFormattedValues(currentValue),
      getWaypointFormattedValues(prevValue),
    );
    switch (arrowType) {
      case ArrowTypes.SINGLE_UP:
        return <KeyboardArrowUp color='action' />;
      case ArrowTypes.SINGLE_DOWN:
        return <KeyboardArrowDown color='action' />;
      case ArrowTypes.DOUBLE_UP:
        return <KeyboardDoubleArrowUp color='action' />;
      case ArrowTypes.DOUBLE_DOWN:
        return <KeyboardDoubleArrowDown color='action' />;
      default:
        return null;
    }
  };

  const getWaypointsIcon = (waypointName: string, color: IconProps['color'] = 'primary') => {
    switch (waypointName) {
      case WaypointIconNames.Social:
        return <People color={color} />;
      case WaypointIconNames.Diet:
        return <Fastfood color={color} />;
      case WaypointIconNames.Exercise:
        return <FitnessCenter color={color} />;
      case WaypointIconNames.Sleep:
        return <Bedtime color={color} />;
      case WaypointIconNames.Alcohol:
        return <WineBar color={color} />;
      case WaypointIconNames.TV:
        return <LiveTv color={color} />;
      case WaypointIconNames.Caffeine:
        return <Coffee color={color} />;
      case WaypointIconNames.Marijuana:
        return <Forest color={color} />;
      case WaypointIconNames.Libido:
        return <Female color={color} />;
      case WaypointIconNames.SocialMedia:
        return <ThumbUp color={color} />;
      case WaypointIconNames.Meditation:
        return <WbSunny color={color} />;
      case WaypointIconNames.Nicotine:
        return <SmokingRooms color={color} />;
      case WaypointIconNames.Custom:
        return <LocationOn color={color} />;
      default:
        return <LocationOn color={color} />;
    }
  };

  return (
    <TableContainer sx={{ boxShadow: 'none' }} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ backgroundColor: theme.palette.background.default }} width='60%' />
            <TableHeaderCell>
              <Box textAlign='center'>
                <Typography>{timeFrame.replace('last', 'past')}</Typography>
                <Typography variant='subtitle1' color='gray'>
                  {formatIntervalDate(interval.currInterval)}
                </Typography>
              </Box>
            </TableHeaderCell>
            <TableHeaderCell>
              <Box textAlign='center'>
                <Typography>comparison</Typography>
                <Typography variant='subtitle1' color='gray'>
                  {formatIntervalDate(interval.previousInterval)}
                </Typography>
              </Box>
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableCategoriesOrder.map?.((category, index) => {
            const categoryWaypointsLength = allWaypoints[category.toLowerCase()]?.length;
            if (!categoryWaypointsLength) return null;
            return (
              <>
                <TableRow key={category} sx={{ backgroundColor: getWaypointColorByIndex(index) }}>
                  <TableWaypointHeaderCell>
                    <Typography fontWeight='bold' sx={{ textTransform: 'capitalize' }}>
                      {category}
                    </Typography>
                  </TableWaypointHeaderCell>
                  <TableWaypointHeaderCell />
                  <TableWaypointHeaderCell />
                </TableRow>
                {allWaypoints[category.toLowerCase()]?.map?.((parentWaypoint) => {
                  const currentWaypoint = getWaypointDataFromId(
                    currentWaypoints,
                    parentWaypoint.waypoint.id,
                  );
                  const previousWaypoint = getWaypointDataFromId(
                    previousWaypoints,
                    parentWaypoint.waypoint.id,
                  );
                  return (
                    <>
                      <TableRowStyled key={parentWaypoint.waypoint.id}>
                        <TableWaypointBodyCell>
                          <Stack direction='row' alignItems='center' gap={1}>
                            {getWaypointsIcon(
                              parentWaypoint.waypoint.category.toLowerCase()
                                === TableCategories.Custom
                                ? TableCategories.Custom
                                : parentWaypoint.waypoint.name,
                            )}
                            <Typography variant='body2'>
                              {parentWaypoint.waypoint.displayName}
                            </Typography>
                          </Stack>
                        </TableWaypointBodyCell>
                        <TableWaypointBodyCell sx={{ borderLeft: cellLeftBorder }} align='center'>
                          <Typography display='flex' justifyContent='center' variant='body2'>
                            {currentWaypoint?.value ? (
                              getWaypointFormattedValues(
                                currentWaypoint.value,
                                currentWaypoint.waypoint.name,
                              )
                            ) : (
                              <Typography variant='subtitle1' color='gray'>
                                no data
                              </Typography>
                            )}
                            {previousWaypoint?.value && currentWaypoint?.value
                              ? getArrowSymbol(currentWaypoint.value, previousWaypoint.value)
                              : null}
                          </Typography>
                        </TableWaypointBodyCell>
                        <TableWaypointBodyCell sx={{ borderLeft: cellLeftBorder }} align='center'>
                          <Typography variant='body2'>
                            {previousWaypoint?.value ? (
                              getWaypointFormattedValues(
                                previousWaypoint.value,
                                previousWaypoint.waypoint.name,
                              )
                            ) : (
                              <Typography variant='subtitle1' color='gray'>
                                no data
                              </Typography>
                            )}
                          </Typography>
                        </TableWaypointBodyCell>
                      </TableRowStyled>
                      {parentWaypoint?.waypoint?.children?.map?.((childWaypoint) => {
                        const currentChildWaypoint = getWaypointDataFromId(
                          currentWaypoints,
                          childWaypoint.waypoint.id,
                        );
                        const previousChildWaypoint = getWaypointDataFromId(
                          previousWaypoints,
                          childWaypoint.waypoint.id,
                        );
                        return (
                          <TableRowStyled key={childWaypoint.waypoint.id}>
                            <TableWaypointBodyCell>
                              <Typography ml={4} variant='body2'>
                                {childWaypoint.waypoint.displayName}
                              </Typography>
                            </TableWaypointBodyCell>
                            <TableWaypointBodyCell
                              sx={{ borderLeft: cellLeftBorder }}
                              align='center'
                            >
                              <Typography display='flex' justifyContent='center' variant='body2'>
                                {currentChildWaypoint?.value ? (
                                  getWaypointFormattedValues(
                                    currentChildWaypoint.value,
                                    currentChildWaypoint.waypoint.name,
                                  )
                                ) : (
                                  <Typography variant='subtitle1' color='gray'>
                                    no data
                                  </Typography>
                                )}
                                {previousChildWaypoint?.value && currentChildWaypoint?.value
                                  ? getArrowSymbol(
                                    currentChildWaypoint.value,
                                    previousChildWaypoint.value,
                                  )
                                  : null}
                              </Typography>
                            </TableWaypointBodyCell>
                            <TableWaypointBodyCell
                              sx={{ borderLeft: cellLeftBorder }}
                              align='center'
                            >
                              <Typography variant='body2'>
                                {previousChildWaypoint?.value ? (
                                  getWaypointFormattedValues(
                                    previousChildWaypoint.value,
                                    previousChildWaypoint.waypoint.name,
                                  )
                                ) : (
                                  <Typography variant='subtitle1' color='gray'>
                                    no data
                                  </Typography>
                                )}
                              </Typography>
                            </TableWaypointBodyCell>
                          </TableRowStyled>
                        );
                      })}
                    </>
                  );
                })}
              </>
            );
          })}
          {waypointSuggestions?.length ? (
            <>
              <TableRow
                key='category-suggestions'
                sx={{ backgroundColor: Theme.custom.colors.lightTextSecondary }}
              >
                <TableWaypointHeaderCell>
                  <Typography fontWeight='bold'>
                    Suggestions sent to
                    {' '}
                    {capitalize(patient?.name?.firstName ?? '') ?? 'patient'}
                  </Typography>
                </TableWaypointHeaderCell>
                <TableWaypointHeaderCell />
                <TableWaypointHeaderCell />
              </TableRow>
              {waypointSuggestions.map((suggestion) => (
                <TableRowStyled key={suggestion.id}>
                  <TableWaypointBodyCell>
                    <Stack direction='row' alignItems='center' gap={1}>
                      {getWaypointsIcon(
                        suggestion.category === TableCategories.Custom
                          ? TableCategories.Custom
                          : suggestion.name,
                        'disabled',
                      )}
                      <Typography variant='body2' color='GrayText'>
                        {`${suggestion.displayName} (${suggestion.suggestionType?.toLowerCase()})`}
                      </Typography>
                    </Stack>
                  </TableWaypointBodyCell>
                  <TableWaypointBodyCell />
                  <TableWaypointBodyCell />
                </TableRowStyled>
              ))}
            </>
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const cellLeftBorder = `1px solid ${grey[300]}`;

const TableRowStyled = styled(TableRow)(
  ({ theme }) => `
  &:nth-of-type(odd) {
    background-color: ${theme.palette.background.default};
  }
  &:nth-of-type(even) {
    background-color: ${
  theme.palette.mode === 'dark' ? theme.palette.background.default : grey[100]
};
  }
`,
);

const TableHeaderCell = styled(TableCell)(({ theme }) => ({
  borderLeft: cellLeftBorder,
  padding: 0,
  backgroundColor: theme.palette.background.default,
}));

const TableWaypointHeaderCell = styled(TableCell)(({ theme }) => ({
  borderBottom: 'none',
  color: 'white',
  padding: theme.spacing(1),
}));

const TableWaypointBodyCell = styled(TableCell)({
  borderBottom: 'none',
});
