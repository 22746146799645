import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { EProfessions, Practice, Provider } from 'types/Entitites';
import * as React from 'react';
import PageHeader from 'components/Common/PageHeader';
import EditIcon from '@mui/icons-material/Edit';
import { capitalize, useTheme } from '@mui/material';
import UpperText from 'components/Common/UpperText';
import CreateProviderForm from 'pages/Authentication/components/CreateProviderForm';
import PracticeForm from 'pages/Authentication/components/PracticeForm';

interface ReviewProps {
  practices: Partial<Practice>[];
  provider: Partial<Provider>;
  updateProvider: (p: Partial<Provider>) => void;
  onUpdatePractices: (p: Partial<Practice>[]) => void;
}

export default function Review({
  practices,
  provider,
  updateProvider,
  onUpdatePractices,
}: ReviewProps) {
  const theme = useTheme();
  const [editProvider, setEditProvider] = React.useState(false);
  const [editPracticeIndex, setEditPracticeIndex] = React.useState(-1);
  const [providerData, setProviderData] = React.useState<Partial<Provider>>({});

  React.useEffect(() => {
    setProviderData(provider);
  }, [provider]);

  const onEditProvider = () => {
    updateProvider(providerData);
    setEditProvider(false);
  };

  const cancelProvider = () => {
    setEditProvider(false);
  };

  const updatePractice = (values: Partial<Practice>, index: number) => {
    setEditPracticeIndex(-1);
    practices.splice(index, 1, values);
    onUpdatePractices(practices);
  };

  const cancelPractice = () => {
    setEditPracticeIndex(-1);
  };

  if (!provider || !provider?.name) {
    return null;
  }

  const {
    npiNumber,
    name: { firstName, lastName },
    email,
    designation,
  } = provider;

  return (
    <Box width={{ xs: '100%', md: '50%' }}>
      <Stack spacing={2}>
        <Box>
          <PageHeader title='Create An Account' titleVariant='h4' />
        </Box>
        <Typography fontWeight='normal' variant='h2'>
          Review
        </Typography>

        {editProvider ? (
          <CreateProviderForm
            key='edit-mode-provider'
            provider={providerData}
            updateProvider={setProviderData}
            isEdit
            onEdit={onEditProvider}
            onCancel={cancelProvider}
          />
        ) : (
          <Box key={npiNumber}>
            <Box display='flex' gap={1}>
              <Typography>{`${capitalize(firstName ?? '')} ${capitalize(lastName ?? '')}`}</Typography>
              <EditIcon
                sx={{ cursor: 'pointer' }}
                onClick={() => setEditProvider(true)}
                color='primary'
                fontSize='small'
              />
            </Box>
            <Typography sx={{ lineHeight: theme.spacing(3) }} color='black' variant='body2'>
              {email}
            </Typography>
            <Typography sx={{ lineHeight: theme.spacing(3) }} color='black' variant='body2'>
              {designation ? EProfessions[designation as unknown as keyof typeof EProfessions] : ''}
            </Typography>
            <Typography sx={{ lineHeight: theme.spacing(3) }} color='black' variant='body2'>
              {`NPI#: ${npiNumber}`}
            </Typography>
          </Box>
        )}

        {practices.map((practice, index) => {
          const { address, id, name } = practice;
          if (index === editPracticeIndex) {
            return (
              <PracticeForm
                key={id}
                isNew={false}
                data={practice}
                index={index}
                onPracticeUpdate={updatePractice}
                onEditCancel={cancelPractice}
              />
            );
          }

          if (!address || !practice) {
            return null;
          }

          const { street, zip, city, state } = address;

          const addressString = `${street}\n${city}, ${state} ${zip}`;

          return (
            <Box key={`${id}${street}${zip}`}>
              <Box display='flex' gap={1}>
                <Typography>{name}</Typography>
                <EditIcon
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setEditPracticeIndex(index)}
                  color='primary'
                  fontSize='small'
                />
              </Box>
              <Typography sx={{ lineHeight: theme.spacing(3) }} color='black' variant='body2'>
                {addressString}
              </Typography>
            </Box>
          );
        })}
        <Box width={{ xs: '100%', sm: 'max-content' }}>
          <Button type='submit' sx={{ width: '100%' }} color='primary' variant='contained'>
            <UpperText>Sign up</UpperText>
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}
