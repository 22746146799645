import { ListProviderRequestAPIResponse } from 'pages/Authentication/types/CreateProvider';
import { CreateProviderRequestPayload } from 'types/Entitites';
import { providerSignUpUrls } from 'utils/apiUrls';
import { HttpType } from 'utils/http';

export const listProviderRequest = (method: HttpType['get']) => function async(): Promise<ListProviderRequestAPIResponse> {
  return (
    method(providerSignUpUrls.listProviderRequest.apiUrls().list)
  );
};

export const createProvider = (
  method: HttpType['post'],
  payload: CreateProviderRequestPayload,
) => method(providerSignUpUrls.createProvider.apiUrls().create, payload);
