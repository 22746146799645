
import TExplorerActionResponse from 'pages/Rewards/types/explorer-actions.types';
import { QueryFunctionContext } from 'react-query';
import { rewardsUrls } from 'utils/apiUrls';
import { HttpType } from 'utils/http';

const fetchExplorerActions = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<TExplorerActionResponse> {
  const [, providerId] = queryKey;

  return method(rewardsUrls.explorerActions.apiUrl(providerId as string));
};

export default fetchExplorerActions;
