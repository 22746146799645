import { CategoriesResponse, CategoryResultsResponse } from 'pages/Dashboard/types/whoiam.types';
import { QueryFunctionContext } from 'react-query';
import { patientExport, whoIam } from 'utils/apiUrls';
import { HttpType } from 'utils/http';

export const fetchWhoIamCategories = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<CategoriesResponse> {
  const [, providerId, patientId] = queryKey;
  return method(whoIam.categories.apiUrl(providerId as string, patientId as string).list);
};

export const fetchPatientExportData = (method: HttpType['post'], providerId: string, patientId: string, asPDF?: boolean): Promise<Blob> => method(
  patientExport.apiUrl(providerId as string, patientId as string, asPDF as boolean).getData,
  {},
  {
    headers: {
      'Content-Type': asPDF ? 'application/pdf' : 'application/octet-stream',
      Accept: asPDF ? 'application/pdf' : 'application/octet-stream',
    },
  },
);

export const fetchWhoIamCategoryResults = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<CategoryResultsResponse> {
  const [, providerId, patientId, categoryId] = queryKey;

  return method(
    whoIam.categoryResults.apiUrls(
      providerId as string,
      patientId as string,
      categoryId as string,
    ).getCategoryResult,
  );
};
