import * as React from 'react';
import Spinner from 'components/Common/Spinner';
import AddPractice from 'pages/Authentication/components/AddPractice';
import CreateProviderForm from 'pages/Authentication/components/CreateProviderForm';
import { CreateProviderRequestPayload, Practice, Provider } from 'types/Entitites';
import Review from 'pages/Authentication/components/Review';
import AwaitVerification from 'pages/Authentication/components/AwaitVerification';
import EmailAlreadySignedUp from 'pages/Authentication/components/EmailAlreadySignedUp';
import { useMutation } from 'react-query';
import { createProvider } from 'pages/Dashboard/services/providersignup.services';
import http from 'utils/http';

export default function Signup() {
  const [step, setStep] = React.useState<number>(1);
  const [practices, updatePractices] = React.useState<Partial<Practice>[]>([]);
  const [provider, updateProvider] = React.useState<Partial<Provider>>({});

  const { mutate: signUp, isLoading, isError, isSuccess } = useMutation({
    mutationFn: (payload: CreateProviderRequestPayload) => createProvider(
      http.post,
      { ...payload },
    ),
  });

  const handleSignup = async () => {
    signUp({ request: { ...provider, practices } });
  };

  const onNext = async (e: React.FormEvent) => {
    e.preventDefault();
    switch (step) {
      case 1:
        setStep(2);
        break;
      case 2:
        setStep(3);
        break;
      case 3:
        handleSignup();
        setStep(4);
        break;
      case 4:
        handleSignup();
        break;
    }
  };
  const getComponent = () => {
    switch (step) {
      case 1:
        return (
          <CreateProviderForm provider={provider} updateProvider={updateProvider} isEdit={false} />
        );
      case 2:
        return <AddPractice practices={practices} updatePractices={(p) => updatePractices(p)} />;
      case 3:
        return (
          <Review
            practices={practices}
            provider={provider}
            updateProvider={updateProvider}
            onUpdatePractices={updatePractices}
          />
        );
      case 4:
        if (isError) {
          return <EmailAlreadySignedUp />;
        }

        if (isSuccess) {
          return <AwaitVerification />;
        }

        return (
          <Review
            practices={practices}
            provider={provider}
            updateProvider={updateProvider}
            onUpdatePractices={updatePractices}
          />
        );
      default:
        return null;
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return <form onSubmit={onNext}>{getComponent()}</form>;
}
