import { HospitalizationAPIResponse } from 'pages/Dashboard/types/hospitalizations.types';
import { QueryFunctionContext } from 'react-query';
import { hospitalizationsUrls } from 'utils/apiUrls';
import { HttpType } from 'utils/http';

const fetchHospitalizationList = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<HospitalizationAPIResponse> {
  const [, providerId, patientId] = queryKey;
  return (
    method(hospitalizationsUrls.listHospitalizations.apiUrls(
      providerId as string,
      patientId as string,
    ).list)
  );
};

export default fetchHospitalizationList;

