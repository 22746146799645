import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { createTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { helpEmail } from 'utils/errorConstants';
import { auth } from 'utils/spaUrls';

const year = new Date().getFullYear();
function Trademark() {
  return (
    <Typography variant='body2'>{`©${year} Headlamp Health, Inc. All Rights Reserved`}</Typography>
  );
}

export default function Footer({ footerRef } : { footerRef?: React.RefObject<HTMLDivElement> }) {
  const theme = createTheme();
  const location = useLocation();
  const isContactUs = location.pathname === auth.contact;
  return (
    <footer style={{ marginTop: theme.spacing(7.5) }} ref={footerRef}>
      <Grid container textAlign='center'>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {!isContactUs ? (
          <Grid item xs={12}>
            <Typography variant='h6' m={3}>
              Need help?
            </Typography>
            <Typography variant='body1'>
              Email&nbsp;
              <Link underline='hover' href={`mailto:${helpEmail}`}>{helpEmail}</Link>
            </Typography>
          </Grid>
        ) : null}
        <Grid item xs={12} mt={3} mb={2.5}>
          <Trademark />
        </Grid>
      </Grid>
    </footer>
  );
}
