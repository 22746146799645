import { Waypoints } from 'pages/Dashboard/types';
import { Pagination } from 'pages/SuperAdmin/types';
import { TimeAudit, UserAudit } from 'types/audit';
import { GoogleDate } from 'utils/dateUtils';

export enum WaypointType {
  WAYPOINT_TYPE_UNSPECIFIED = 'WAYPOINT_TYPE_UNSPECIFIED',
  WAYPOINT_TYPE_DEFAULT = 'WAYPOINT_TYPE_DEFAULT',
  WAYPOINT_TYPE_PATIENT_CREATED = 'WAYPOINT_TYPE_PATIENT_CREATED',
  WAYPOINT_TYPE_PROVIDER_CREATED = 'WAYPOINT_TYPE_PROVIDER_CREATED',
  WAYPOINT_TYPE_APPLE_HEALTH = 'WAYPOINT_TYPE_APPLE_HEALTH',
}

export enum WaypointValues {
  WAYPOINT_VALUE_LESS = 10,
  WAYPOINT_VALUE_TYPICAL = 20,
  WAYPOINT_VALUE_MORE = 30,
}

type WaypointOptionObject = {
  id: string;
  name: string;
  display_name: string;
}


export interface WaypointObject {
  id: string,
  timeAudit: TimeAudit,
  userAudit: UserAudit,
  name: string,
  displayName: string,
  description: string,
  category: string,
  type: WaypointType,
  parentWaypoint: WaypointObject | null
  options: WaypointOptionObject[]
  groupId?: string;
}

export interface WaypointTrackingMetadata {
  timeSeries: { startDate: Date, endDate: Date }[];
  key: string;
  value: string;
}

export interface WaypointTracking {
  id: string,
  timeAudit: TimeAudit,
  userAudit: UserAudit,
  patientId: string,
  waypoint: WaypointObject,
  providerId: string,
  value: WaypointValues,
  date?: GoogleDate,
  absoluteValue?: string,
  metadata?: WaypointTrackingMetadata[],
  timestamp?: number,
}

export type ListWaypointDetails = {
  recommendedAvgValue: number;
  waypointId: string;
  waypointType: string;
  unit: string;
  categoryAvgData: {
    id: SleepCategory;
    description: string;
    absoluteValue: number;
    absoluteUnit: string;
    rating: string;
  }[];
  categoryTimeSeriesData: {
    timestamp: number;
    metadata: {
      key: SleepCategory;
      type: string;
      value: string;
      unit: string;
      description: string;
      timeSeries: {
        startDate: string;
        endDate: string;
        value: number;
      }[];
      avgStartTimestamp: number;
      occurenceCount: number;
    }[];
  }[];
  nationalAvgData: {
    key: SleepCategory;
    type: string;
    value: string;
    unit: string;
    description: string;
    timeSeries: never[];
    avgStartTimestamp: number;
    occurenceCount: number;
  }[];
  previousIntervalCategoryAvgData: {
    id: SleepCategory;
    absoluteValue: number;
  }[];
  minChartRange: number;
  maxChartRange: number;
};

export interface NestedWaypoints {
  waypoint: WaypointObject & { children?: NestedWaypoints[] },
  value: WaypointValues
}

export interface GroupedWaypoints {
  [k: string]: Array<NestedWaypoints>;
}

export interface WaypointTrackingAPIResponse {
  waypoints: WaypointTracking[]
  goals?: Record<string, string>[],
  averages: { key: string; value: string; }[],
  absoluteAvgValue: number;
  absoluteValueUnit: string;
  previousIntervalAverages: { key: string; value: string; unit: string }[],
}

export type WaypointSourcesAPIResponse = {
  sources: {
    waypointId: string;
    sourceId: string;
    sourceName: string;
    sourceType: string;
    isSelected: boolean;
  }[];
};

export type MostLoggedMetricsResponse = {
  percents: {
    parameter: string;
    count: number;
  }[];
  startDate: {
    year: number;
    month: number;
    day: number;
  };
  endDate: {
    year: number;
    month: number;
    day: number;
  };
};

export type SomaticScoreResponse = {
  data: {
    somaticScore: number;
    timestamp: number;
  }[];
  absoluteAvgValue: number;
};

export interface ListWaypointAPIResponse {
  waypoints: Waypoints[]
}


export interface StroopTimeSeriesAPIResponse extends Pagination {
  data: {
    timestamp: number,
    moodValue: number,
    score: number
  }[],
  absoluteAvgValue: number
}

export interface TimeInterval {
  start: string;
  end: string;
}


export enum SleepCategory {
  INBED_TIME = 'inbed_time_sleep',
  ASLEEP_TIME = 'asleep_time_sleep',
  AWAKE_TIME = 'awake_time_sleep',
  DEEP_TIME = 'deep_time_sleep',
  CORE_TIME = 'core_time_sleep',
  REM_TIME = 'rem_time_sleep',
}


const ASLEEP_COLOUR = '#33739D';
const IN_BED_COLOUR = '#32A597';
const REM_TIME_COLOUR = '#03A9F4';
const DEEP_TIME_COLOUR = '#22516B';
const CORE_TIME_COLOUR = '#0288D1';
const AWAKE_TIME_COLOUR = '#E5772C';

type SleepCategoryObj = {
  label: string;
  key: SleepCategory;
  color: string;

  averageLabel?: string;
  periodAverageLabel?: string;
  averageTimeLabel?: string;
  isAsleepCategory?: boolean;
  averageLabelColor?: string;
  averageTimeLabelColor?: string;
  index: number;
};

export const SleepCategoryMap: Record<SleepCategory, SleepCategoryObj> = {
  [SleepCategory.INBED_TIME]: {
    index: 0,
    label: 'In bed',
    key: SleepCategory.INBED_TIME,
    color: IN_BED_COLOUR,
    averageLabel: 'Time in bed',
    periodAverageLabel: 'Time in bed',
    averageTimeLabel: 'Time in bed',
  },
  [SleepCategory.AWAKE_TIME]: {
    index: 2,
    label: 'Awake',
    key: SleepCategory.AWAKE_TIME,
    color: AWAKE_TIME_COLOUR,
    averageLabel: 'Awakenings',
    averageLabelColor: '#B85A4A',
    periodAverageLabel: 'Time awake',
    averageTimeLabel: 'Time awake',
    averageTimeLabelColor: '#ED7A65',
  },
  [SleepCategory.ASLEEP_TIME]: {
    index: 1,
    label: 'Asleep',
    key: SleepCategory.ASLEEP_TIME,
    color: ASLEEP_COLOUR,
    averageLabel: 'Time asleep',
    periodAverageLabel: 'Asleep',
    averageTimeLabel: 'Time asleep',
  },
  [SleepCategory.CORE_TIME]: {
    index: 3,
    label: 'Light',
    key: SleepCategory.CORE_TIME,
    color: CORE_TIME_COLOUR,
    isAsleepCategory: true,
    periodAverageLabel: 'Light',
  },
  [SleepCategory.DEEP_TIME]: {
    index: 4,
    label: 'Deep',
    key: SleepCategory.DEEP_TIME,
    color: DEEP_TIME_COLOUR,
    isAsleepCategory: true,
    periodAverageLabel: 'Deep',
  },
  [SleepCategory.REM_TIME]: {
    index: 5,
    label: 'REM',
    key: SleepCategory.REM_TIME,
    color: REM_TIME_COLOUR,
    isAsleepCategory: true,
    periodAverageLabel: 'REM',
  },
};
