import { PropTypes } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';

type TColor = {
  color?: Exclude<PropTypes.Color, 'default'>;
  size?: string | number;
  testId?: string;
};

export default function CircularIndeterminate({ color = 'secondary', size, testId }: TColor) {
  return (
    <Box
      data-testid={testId}
      sx={{
        margin: 0,
        position: 'absolute',
        top: '35%',
        left: '50%',
      }}
    >
      <CircularProgress color={color} {...(size ? { size } : {})} />
    </Box>
  );
}
