import * as React from 'react';
import { Tabs as MuiTabs, Tab, Box, Typography, Link } from '@mui/material';
import { Tabs } from 'pages/Rewards/types/tabs.types';
import ContentLoader from 'components/Common/ContentLoader';
import RewardsContext from 'pages/Rewards/context/RewardsContext';
import PointsList from 'pages/Rewards/components/PointsList';
import trackMixpanelEvent, { tabItems } from 'pages/Rewards/utilMethods';
import { MixpanelEventName } from 'pages/Rewards/types/mixpanel.types';

const redeemEmail = 'redeem@headlamp.com';

export default function RewardsTabs() {
  const {
    explorerActions,
    explorerActionsFetching,
    isExplorerActionsError,
    rewards,
    rewardsFetching,
    isRewardsError,
    transactions,
    transactionsFetching,
    isTransactionsError,
    activeTab,
    setActiveTab,
  } = React.useContext(RewardsContext);

  const handleChange = (__: React.SyntheticEvent, newValue: Tabs) => {
    if (newValue === Tabs.REWARDS) {
      trackMixpanelEvent(MixpanelEventName.REWARDS_TAB_CLICKED);
    }
    else if (newValue === Tabs.WAYS_TO_EARN) {
      trackMixpanelEvent(MixpanelEventName.WAYS_TO_EARN_TAB_CLICKED);
    }
    else {
      trackMixpanelEvent(MixpanelEventName.TRANSACTION_HISTORY_TAB_CLICKED);
    }
    setActiveTab(newValue);
  };

  return (
    <Box data-testid='rewards-tabs'>
      <MuiTabs
        value={activeTab}
        onChange={handleChange}
        aria-label='rewards tabs'
        variant='fullWidth'
      >
        {tabItems.map((tab) => (
          <Tab key={tab.key} value={tab.key} label={tab.label} />
        ))}
      </MuiTabs>
      <Box maxHeight={460} overflow='auto' height='100%'>
        {activeTab === Tabs.REWARDS && (
          <ContentLoader
            isFetching={rewardsFetching}
            isError={isRewardsError}
            minHeight={200}
            noDataText={!rewards?.length ? 'No rewards are listed yet!' : ''}
          >
            <>
              <Typography variant='h5' mt={3}>
                Redeem
              </Typography>
              <Typography>
                Email
                {' '}
                <Link underline='hover' href={`mailto:${redeemEmail}`} onClick={() => trackMixpanelEvent(MixpanelEventName.REDEEM_LINK_CLICKED)}>
                  {redeemEmail}
                </Link>
                {' '}
                to redeem
              </Typography>
              <PointsList list={rewards} />
            </>
          </ContentLoader>
        )}
        {activeTab === Tabs.WAYS_TO_EARN && (
          <ContentLoader
            isFetching={explorerActionsFetching}
            isError={isExplorerActionsError}
            minHeight={200}
            noDataText={!explorerActions?.length ? 'No ways to earn yet!' : ''}
          >
            <PointsList list={explorerActions} />
          </ContentLoader>
        )}
        {activeTab === Tabs.TRANSACTION_HISTORY && (
          <ContentLoader
            isFetching={transactionsFetching}
            isError={isTransactionsError}
            minHeight={200}
            noDataText={!transactions?.length ? 'No transactions yet!' : ''}
          >
            <PointsList list={transactions} type='detailed' />
          </ContentLoader>
        )}
      </Box>
    </Box>
  );
}
