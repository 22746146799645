import React from 'react';
import Card from '@mui/material/Card';
import { CardContent } from '@mui/material';
import PatientDemographicForm from 'pages/Dashboard/components/PatientDempgraphicForm';
import PatientInviteForm from 'pages/Dashboard/components/PatientInviteForm';
import PatientInfoFormHeader from 'pages/Dashboard/components/PatientInfoFormHeader';

export interface PatientInfoFormProps {
  isDemographicForm: boolean;
  setIsDemographicForm: (value: boolean) => void;
}

export default function PatientInfoForm({
  isDemographicForm,
  setIsDemographicForm,
}: PatientInfoFormProps) {
  return (
    <Card sx={{ height: 1, width: 0.9 }}>
      <PatientInfoFormHeader
        isDemographicForm={isDemographicForm}
        setIsDemographicForm={setIsDemographicForm}
      />
      <CardContent>
        {isDemographicForm ? (
          <PatientDemographicForm />
        ) : (
          <PatientInviteForm />
        )}
      </CardContent>
    </Card>
  );
}
