import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AddedPracticesList from 'pages/Authentication/components/AddedPracticesList';
import * as React from 'react';
import { Practice } from 'types/Entitites';


interface SavedPracticesProps{
    practices: Partial<Practice>[],
    onPracticeSave: (values: Partial<Practice>) => void,
    isEditing: number,
    onEditClick: (index: number) => void,
    onPracticeUpdate: (values: Partial<Practice>, index: number) => void;
    onEditCancel: () => void;
}

export default function SavedPractices({
  practices, onPracticeSave, isEditing, onEditClick, onPracticeUpdate, onEditCancel,
}: SavedPracticesProps) {
  return (
    <Stack spacing={2}>
      <Typography color='#0000008a'>Added practice(s)</Typography>
      <AddedPracticesList
        practices={practices}
        onPracticeSave={onPracticeSave}
        onPracticeUpdate={onPracticeUpdate}
        isEditing={isEditing}
        onEditClick={onEditClick}
        onEditCancel={onEditCancel}
      />
      <Typography fontSize='large'>Add another practice?</Typography>
    </Stack>
  );
}
