import React from 'react';
import { Box, Checkbox, FormGroup, Stack, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { lightBlue, orange } from '@mui/material/colors';
import WarningIcon from '@mui/icons-material/Warning';
import { useFormContext, Controller } from 'react-hook-form';

export default function MinorConsentForm() {
  const { control } = useFormContext();

  return (
    <Stack sx={{ p: 2, bgcolor: lightBlue[50] }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
        <WarningIcon sx={{ color: orange[600] }} />
        <Typography variant='h6'>Consent required</Typography>
      </Box>
      <Typography variant='body1'>Patients under the age of 18 require parent / legal guardian consent to use the Headlamp app.</Typography>
      <FormGroup>
        <FormControlLabel
          control={(
            <Controller
              name='consent'
              control={control}
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  checked={value}
                  required
                  onChange={(e) => onChange(e.target.checked)}
                />
              )}
            />
          )}
          label='I acknowledge that parent / legal guardian consent has been obtained'
        />
      </FormGroup>
    </Stack>
  );
}
