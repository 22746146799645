import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import * as React from 'react';
import { convertWayPointsData } from 'utils/service';
import { Waypoints } from 'pages/Dashboard/types';
import { CircularProgress } from '@mui/material';
import { useHttp } from 'hooks/use-fetch';
import { useQuery } from 'react-query';
import { wayPointsUrls } from 'utils/apiUrls';
import { fetchWaypointList } from 'pages/Dashboard/services/waypoints.services';

interface WayPointsProps {
  checkHandler: (key: Waypoints, e: React.ChangeEvent<HTMLInputElement>) => void;
}

enum WaypointCategories {
  Substance = 'Substance',
  Digital = 'Digital',
  Other = 'Other',
  Behavior = 'Behavior',
  Custom = 'custom',
}

export const categoriesOrder = [
  WaypointCategories.Behavior,
  WaypointCategories.Substance,
  WaypointCategories.Digital,
  WaypointCategories.Other,
  WaypointCategories.Custom,
];

export default function StandardWaypoints({ checkHandler }: WayPointsProps) {
  const { http, providerId } = useHttp();

  const { data: waypointsResponse, isFetching } = useQuery([
    wayPointsUrls.listWayPoints.queryUrl,
    providerId,
  ], {
    queryFn: fetchWaypointList(http.get),
    enabled: !!providerId,
  });

  if (isFetching) {
    return <CircularProgress />;
  }

  const convertedWaypoints = convertWayPointsData(waypointsResponse?.waypoints);

  return (
    <Grid container spacing={2}>
      {categoriesOrder.map((key) => {
        const value = convertedWaypoints[key];
        if (!value) {
          return null;
        }
        return (
          <Grid key={key} item md={2} xs={6}>
            <FormControl>
              <FormLabel sx={{ textTransform: 'capitalize' }}>{key}</FormLabel>
              {value.map(({ id, name, displayName }) => (
                <FormControlLabel
                  key={id}
                  value={name}
                  control={(
                    <Checkbox
                      onChange={(e) => checkHandler({ id, name, category: key }, e)}
                      name={name}
                    />
                  )}
                  label={displayName}
                />
              ))}
            </FormControl>
          </Grid>
        );
      })}
    </Grid>
  );
}
