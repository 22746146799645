import { PatientSymptomsAPIResponse } from 'pages/Dashboard/types/symptoms.types';
import { QueryFunctionContext } from 'react-query';
import { symptomsUrls } from 'utils/apiUrls';
import { HttpType } from 'utils/http';

export const fetchSymptomList = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<PatientSymptomsAPIResponse> {
  const [, providerId, patientId] = queryKey;
  return (
    method(symptomsUrls.listSymptoms.apiUrls(
      providerId as string,
      patientId as string,
    ).list)
  );
};


export const fetchCurrentSymptomList = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<PatientSymptomsAPIResponse> {
  const [, providerId, patientId] = queryKey;
  return (
    method(symptomsUrls.listSymptoms.apiUrls(
      providerId as string,
      patientId as string,
    ).listCurrent)
  );
};
