/* eslint-disable max-len */
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  capitalize,
  useTheme,
} from '@mui/material';
import { ACTION_CONFIRMATION_VIEW } from 'pages/Dashboard/enums';
import { PatientObj } from 'pages/Dashboard/types/patient.types';
import { WaypointSuggestion } from 'pages/Dashboard/types/waypointSuggestion.types';
import React, { useCallback, useMemo, useState } from 'react';
import { Waypoints } from 'pages/Dashboard/types';
import Theme from 'theme';
import {
  CheckCircle,
  Bedtime,
  Coffee,
  Fastfood,
  Female,
  FitnessCenter,
  Forest,
  LiveTv,
  LocationOn,
  People,
  SmokingRooms,
  ThumbUp,
  WbSunny,
  WineBar,
} from '@mui/icons-material';
import mixpanel from 'mixpanel-browser';
import { MixpanelEventName } from 'pages/Dashboard/types/mixpanel.types';

enum TableCategories {
  Substance = 'substance',
  Digital = 'digital',
  Behavior = 'behavior',
  Other = 'other',
  Custom = 'custom',
}

interface SendWaypointSuggestionsProps {
  patient: PatientObj;
  standardWaypoints: Waypoints[];
  patientWaypoints: Waypoints[];
  handleCloseDrawer: () => void;
  handleSendSuggestions: (suggestions: Partial<WaypointSuggestion>[]) => Promise<void>;
}

enum WaypointIconNames {
  Social = 'social',
  Diet = 'diet',
  Exercise = 'exercise',
  Sleep = 'sleep',
  Alcohol = 'alcohol',
  TV = 'television',
  Marijuana = 'marijuana',
  Caffeine = 'caffeine',
  Nicotine = 'nicotine',
  SocialMedia = 'socialmedia',
  Meditation = 'meditation',
  Libido = 'libido',
  Custom = 'Custom',
}

const tableCategoriesOrder = [
  TableCategories.Behavior,
  TableCategories.Substance,
  TableCategories.Digital,
  TableCategories.Other,
  TableCategories.Custom,
];

const getWaypointsIcon = (waypointName: string, color: string) => {
  switch (waypointName) {
    case WaypointIconNames.Social:
      return <People fontSize='small' sx={{ color }} />;
    case WaypointIconNames.Diet:
      return <Fastfood fontSize='small' sx={{ color }} />;
    case WaypointIconNames.Exercise:
      return <FitnessCenter fontSize='small' sx={{ color }} />;
    case WaypointIconNames.Sleep:
      return <Bedtime fontSize='small' sx={{ color }} />;
    case WaypointIconNames.Alcohol:
      return <WineBar fontSize='small' sx={{ color }} />;
    case WaypointIconNames.TV:
      return <LiveTv fontSize='small' sx={{ color }} />;
    case WaypointIconNames.Caffeine:
      return <Coffee fontSize='small' sx={{ color }} />;
    case WaypointIconNames.Marijuana:
      return <Forest fontSize='small' sx={{ color }} />;
    case WaypointIconNames.Libido:
      return <Female fontSize='small' sx={{ color }} />;
    case WaypointIconNames.SocialMedia:
      return <ThumbUp fontSize='small' sx={{ color }} />;
    case WaypointIconNames.Meditation:
      return <WbSunny fontSize='small' sx={{ color }} />;
    case WaypointIconNames.Nicotine:
      return <SmokingRooms fontSize='small' sx={{ color }} />;
    case WaypointIconNames.Custom:
      return <LocationOn fontSize='small' sx={{ color }} />;
  }
  return null;
};

export default function SendWaypointSuggestions({
  patient,
  standardWaypoints,
  patientWaypoints,
  handleCloseDrawer,
  handleSendSuggestions,
}: SendWaypointSuggestionsProps) {
  const [view, setView] = useState(ACTION_CONFIRMATION_VIEW.DEFAULT);
  const theme = useTheme();

  const [suggestions, setSuggestions] = useState<WaypointSuggestion[]>([]);

  const [customWaypointName, setCustomWaypointName] = useState('');
  const [customWaypointError, setCustomWaypointError] = useState('');
  const [showCustomWaypointForm, setShowCustomWaypointForm] = useState(false);
  const [sendingSuggestions, setSendingSuggestions] = useState(false);

  const [comment, setComment] = useState('');

  const patientName = patient?.name?.firstName && patient?.name?.firstName?.length > 0
    ? capitalize(patient?.name?.firstName ?? '')
    : 'the patient';

  const mergedWaypoints = [
    ...standardWaypoints,
    ...patientWaypoints.filter((pw) => !standardWaypoints.some((sw) => sw.id === pw.id)),
    // Add suggestions that are not in patientWaypoints or standardWaypoints
    ...suggestions.filter((suggestion) => !patientWaypoints.some((pw) => pw.id === suggestion.id)),
  ];
  const uniqueWaypoints = mergedWaypoints.filter(
    (waypoint, index, array) => array.findIndex((w) => w.id === waypoint.id) === index,
  );

  // Loop through tableCategoriesOrder and group uniqueWaypoints by category
  const groupedWaypoints = tableCategoriesOrder.reduce<{ [key: string]: Waypoints[] }>(
    (acc, category) => {
      acc[category] = uniqueWaypoints.filter((waypoint) => waypoint.category.toLowerCase() === category.toLowerCase());
      return acc;
    },
    {},
  );

  const handleCheckboxChange = (waypoint: Waypoints, isChecked: boolean) => {
    const isSuggested = suggestions.find((sw) => sw.id === waypoint.id);
    let newSuggestedWaypoints;

    if (isChecked && !isSuggested) {
      // Add to suggestions with type ADD
      newSuggestedWaypoints = [
        ...suggestions,
        {
          id: waypoint.id,
          name: waypoint.name,
          type: waypoint.type,
          displayName: waypoint.displayName || waypoint.name,
          category: waypoint.category,
          comment: '', // You might want to handle comments differently
          suggestionType: 'ADD',
        },
      ];
    }
    else if (!isChecked && isSuggested && isSuggested.suggestionType === 'ADD') {
      // Remove from suggestions if it was added before
      newSuggestedWaypoints = suggestions.filter((sw) => sw.id !== waypoint.id);
    }
    else if (isChecked && isSuggested) {
      // Checkbox is checked and waypoint is already suggested, remove from suggestions
      newSuggestedWaypoints = suggestions.filter((sw) => sw.id !== waypoint.id);
    }
    else if (!isChecked && !isSuggested) {
      // If unchecking a checkbox not in suggestions, add with REMOVE type
      newSuggestedWaypoints = [
        ...suggestions,
        {
          id: waypoint.id,
          name: waypoint.name,
          type: waypoint?.type,
          displayName: waypoint.displayName || waypoint.name,
          category: waypoint.category,
          comment: '', // Handle comments as needed
          suggestionType: 'REMOVE',
        },
      ];
    }
    else {
      // Fallback case, typically should not reach here
      newSuggestedWaypoints = [...suggestions];
    }

    setSuggestions(newSuggestedWaypoints);
  };

  const handleSendSuggestionClick = () => {
    mixpanel.track(MixpanelEventName.SEND_SUGGESTIONS_BUTTON_CLICK);
    setView(ACTION_CONFIRMATION_VIEW.CONFIRMATION);
  };

  const handleAddCustomWaypoint = () => {
    if (!customWaypointName) {
      setCustomWaypointError('Custom waypoint label is required');
      return;
    }
    const isDuplicate = uniqueWaypoints.find(
      (w) => w.name.toLowerCase() === customWaypointName.toLowerCase(),
    );
    if (isDuplicate) {
      setCustomWaypointError('Waypoint already exists');
      return;
    }
    setSuggestions([
      ...suggestions,
      {
        name: customWaypointName.toLowerCase(),
        displayName: customWaypointName,
        category: 'Custom',
        comment: '',
        suggestionType: 'ADD',
        type: 'WAYPOINT_TYPE_PROVIDER_CREATED',
        // generate a random uuid for the id
        id: Math.random().toString(36).substring(7),
      },
    ]);
    setCustomWaypointName('');
    setShowCustomWaypointForm(false);
  };

  const handleConfirmSendSuggestions = async () => {
    mixpanel.track(MixpanelEventName.SEND_SUGGESTIONS_CONFIRMATION_BUTTON_CLICK, {
      'waypoints to add': suggestions
        .filter((s) => s.suggestionType === 'ADD')
        .map((s) => s.name)
        .join(', '),
      'waypoints to remove': suggestions
        .filter((s) => s.suggestionType === 'REMOVE')
        .map((s) => s.name)
        .join(', '),
    });
    setSendingSuggestions(true);
    await handleSendSuggestions(
      suggestions.map((suggestion) => ({
        id: suggestion.id,
        type: suggestion.type,
        name: suggestion.name,
        displayName: suggestion.displayName,
        category: suggestion.category,
        suggestionType: suggestion.suggestionType,
        comment,
      })),
    );
    setSendingSuggestions(false);
    setView(ACTION_CONFIRMATION_VIEW.SUCCESS);
  };

  const handleCancelSendSuggestions = useCallback(() => {
    mixpanel.track(MixpanelEventName.SEND_SUGGESTIONS_CANCEL_BUTTON_CLICK);
    setView(ACTION_CONFIRMATION_VIEW.DEFAULT);
  }, []);

  const newWaypoints = useMemo(() => {
    let newWaypointsCount = patientWaypoints.length;
    suggestions.forEach((suggestion) => {
      if (suggestion.suggestionType === 'ADD') {
        newWaypointsCount += 1;
      }
      else if (suggestion.suggestionType === 'REMOVE') {
        newWaypointsCount -= 1;
      }
    });
    return newWaypointsCount;
  }, [patientWaypoints, suggestions]);

  const showMinimumWaypointsAlert = newWaypoints < 3 && !!suggestions.length && !!patientWaypoints.length;

  return (
    <Box display='flex' flexDirection='column' gap={2} sx={{ width: '100%' }}>
      {view === ACTION_CONFIRMATION_VIEW.DEFAULT && (
        <>
          <Typography variant='body2'>
            {`Suggest waypoints to ${patientName} to ${
              patientWaypoints.length ? 'start/stop' : 'start'
            } tracking.`}
          </Typography>
          <Grid container spacing={2}>
            {Object.entries(groupedWaypoints).map(([category, waypoints]) => {
              const categoryPatientWaypoints = [...waypoints];
              const isCustom = category.toLowerCase() === 'custom';
              return (
                <Grid key={`category-${category}`} item xs={isCustom ? 12 : 3}>
                  <Box display='flex' flexDirection='column'>
                    <Typography variant='body2' color='gray' sx={{ textTransform: 'capitalize' }}>
                      {category}
                    </Typography>
                    <Box display='flex' flexDirection='column'>
                      {categoryPatientWaypoints.map((categoryPatientWaypoint, index) => {
                        const displayName = categoryPatientWaypoint.displayName ?? categoryPatientWaypoint.name;
                        const suggestedWaypoint = suggestions.find(
                          (sw) => sw.id === categoryPatientWaypoint.id,
                        );
                        const isChecked = suggestedWaypoint
                          ? suggestedWaypoint.suggestionType === 'ADD'
                          : patientWaypoints.some((pw) => pw.id === categoryPatientWaypoint.id);

                        return (
                          <Box
                            key={`${categoryPatientWaypoint.id}-${isChecked ? '-checked' : ''}`}
                            display='flex'
                            alignItems='flex-start'
                            sx={{
                              border: suggestedWaypoint
                                ? `1px dashed ${Theme.palette.primary.main}`
                                : 'none',
                              mt: index ? 1 : 0,
                              backgroundColor: suggestedWaypoint
                                ? `${Theme.palette.primary.main}0D`
                                : 'transparent',
                              fontStyle: suggestedWaypoint ? 'italic' : 'normal',
                              textDecoration:
                                suggestedWaypoint && !isChecked ? 'line-through' : 'none',
                              borderRadius: '4px',
                              ...(isCustom ? { width: 'fit-content', pr: 4 } : {}),
                            }}
                          >
                            <FormControlLabel
                              control={(
                                <Checkbox
                                  size='small'
                                  checked={isChecked}
                                  onChange={() => handleCheckboxChange(categoryPatientWaypoint, !isChecked)}
                                />
                              )}
                              label={displayName}
                              sx={{ pl: 1, mr: 0, fontSize: theme.typography.body2.fontSize }}
                            />
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          {showCustomWaypointForm ? (
            <Grid container spacing={1} alignItems='flex-start'>
              <Grid item xs={9}>
                <TextField
                  label='Custom Waypoint'
                  variant='outlined'
                  placeholder='Waypoint Name'
                  size='small'
                  sx={{ width: '100%' }}
                  value={customWaypointName}
                  onChange={(e) => {
                    if (e.target.value.length > 0) setCustomWaypointError('');
                    setCustomWaypointName(e.target.value);
                  }}
                  // handleAddCustomWaypoint will be called on enter key press
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleAddCustomWaypoint();
                    }
                  }}
                  autoFocus
                  error={!!customWaypointError}
                />
                <Typography variant='caption' color='error'>
                  {customWaypointError}
                </Typography>
              </Grid>
              <Grid item xs={3} display='flex' gap={1}>
                <Button variant='contained' onClick={handleAddCustomWaypoint}>
                  ADD
                </Button>
                <Button
                  variant='outlined'
                  onClick={() => {
                    setShowCustomWaypointForm(false);
                    setCustomWaypointName('');
                  }}
                >
                  CANCEL
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Button
              variant='outlined'
              sx={{ width: 'fit-content' }}
              onClick={() => setShowCustomWaypointForm(true)}
            >
              ADD CUSTOM WAYPOINT
            </Button>
          )}
          <TextField
            label='Comment to patient (optional)'
            variant='outlined'
            placeholder='Comment to patient (optional)'
            size='small'
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          {showMinimumWaypointsAlert ? (
            <Alert severity='error'>The patient must have at least 3 waypoints.</Alert>
          ) : null}
          <Button
            variant='contained'
            sx={{ width: 'fit-content' }}
            onClick={handleSendSuggestionClick}
            disabled={suggestions.length === 0 || showMinimumWaypointsAlert}
          >
            SEND SUGGESTIONS
          </Button>
          <Typography variant='body2'>{`${patientName} will be notified in their Headlamp mobile app.`}</Typography>
        </>
      )}
      {view === ACTION_CONFIRMATION_VIEW.CONFIRMATION && (
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          gap={2}
          justifyContent='space-around'
        >
          <Typography variant='h5' fontWeight={400}>
            Please confirm
          </Typography>
          <Typography variant='h6' fontWeight={500}>
            Send these waypoint suggestions?
          </Typography>
          {suggestions.map((suggestion) => (
            <Box display='flex' gap={1} alignItems='center'>
              {getWaypointsIcon(
                suggestion.category.toLowerCase() === 'custom'
                  ? WaypointIconNames.Custom
                  : suggestion.name.toLowerCase(),
                theme.custom.colors.lightTextSecondary,
              )}
              <Typography variant='body2' color={theme.custom.colors.lightTextSecondary}>
                {`${suggestion.displayName} ${
                  suggestion.suggestionType === 'ADD' ? '(add)' : '(remove)'
                }`}
              </Typography>
            </Box>
          ))}
          {comment && (
            <Typography variant='body1' sx={{ textAlign: 'center' }}>
              {`Comment to ${patientName}:`}
              <br />
              <Typography component='i' variant='body1'>
                {comment}
              </Typography>
            </Typography>
          )}
          <Box display='flex' gap={2}>
            <Button
              variant='contained'
              onClick={handleConfirmSendSuggestions}
              disabled={sendingSuggestions}
            >
              YES, SEND
              {sendingSuggestions && <CircularProgress size={14} sx={{ ml: 1 }} />}
            </Button>
            <Button variant='outlined' onClick={handleCancelSendSuggestions}>
              CANCEL
            </Button>
          </Box>
        </Box>
      )}
      {view === ACTION_CONFIRMATION_VIEW.SUCCESS && (
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          gap={2}
          justifyContent='space-around'
        >
          <Box display='flex' alignItems='center' gap={1}>
            <CheckCircle fontSize='large' color='success' />
            <Typography variant='h5' sx={{ mb: 0 }} fontWeight={400}>
              Success!
            </Typography>
          </Box>
          <Typography variant='h6' fontWeight={500}>
            Waypoint suggestions have been sent.
          </Typography>
          <Button variant='contained' onClick={handleCloseDrawer}>
            CLOSE
          </Button>
        </Box>
      )}
    </Box>
  );
}
