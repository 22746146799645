import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

export default function NoProviderRequests() {
  const theme = useTheme();

  return (
    <Box
      display='flex'
      sx={{
        height: 120,
        margin: theme.spacing(2),
      }}
    >
      <Typography variant='subtitle1' gutterBottom>
        No provider requests yet
      </Typography>
    </Box>
  );
}
