import { Button, ButtonProps, CircularProgress } from '@mui/material';
import React from 'react';

interface ILoadingButtonProps extends ButtonProps {
  label: string;
  isLoading: boolean;
}

export default function LoadingButton({
  label,
  isLoading,
  disabled,
  startIcon,
  ...rest
}: ILoadingButtonProps): JSX.Element {
  return (
    <Button
      disabled={disabled || isLoading}
      startIcon={isLoading ? <CircularProgress size='20px' color='inherit' /> : startIcon}
      {...rest}
    >
      {label}
    </Button>
  );
}
