import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import PracticeInfoForm from 'pages/Dashboard/components/PracticeInfoForm';
import PatientInfoForm from 'pages/Dashboard/components/PatientInfoForm';
import {
  PatientDemographicFormConsent,
  PatientInviteFormConsent,
} from 'pages/Dashboard/components/AddPatientConsents';
import { PatientForm } from 'pages/Dashboard/types';
import { CircularProgress } from '@mui/material';

interface IProps {
  onSubmit: SubmitHandler<PatientForm>;
  patientFormDemographic: boolean;
  setPatientFormDemographic: (val: boolean) => void;
  isLoading: boolean;
}

export default function AddNewPatientForm({
  onSubmit,
  patientFormDemographic,
  setPatientFormDemographic,
  isLoading,
}: IProps) {
  const { handleSubmit } = useFormContext();

  function getButtonContent() {
    if (isLoading) {
      return <CircularProgress color='primary' size={30} />;
    }
    return patientFormDemographic ? 'ADD PATIENT' : 'SEND INVITE(S)';
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction='column' spacing={4}>
        <Grid item>
          <PracticeInfoForm />
        </Grid>
        <Grid item>
          <PatientInfoForm
            isDemographicForm={patientFormDemographic}
            setIsDemographicForm={setPatientFormDemographic}
          />
        </Grid>
      </Grid>
      <Box sx={{ my: 4 }}>
        <Button
          color='success'
          variant='contained'
          type='submit'
          disabled={isLoading}
          sx={{ width: { xs: 1, md: 1 / 5 }, height: { xs: 20, md: 40 } }}
        >
          {getButtonContent()}
        </Button>
      </Box>
      <Box>
        {patientFormDemographic ? <PatientDemographicFormConsent /> : <PatientInviteFormConsent />}
      </Box>
    </form>
  );
}
