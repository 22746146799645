import { PatientRelationshipCategory, EducationLevel, LegalHistoryType } from 'pages/Dashboard/types/background.types';
import { PatientLifeStyleAPIResponse, consumptionType } from 'pages/Dashboard/types/lifestyle.types';
import { QueryFunctionContext } from 'react-query';
import { lifestyleUrls } from 'utils/apiUrls';
import { NotSpecified } from 'utils/constants';
import { Frequency, frequencyFormatter } from 'utils/dateUtils';
import { HttpType } from 'utils/http';

export const RelationFormatter = (relation: PatientRelationshipCategory) => {
  switch (relation) {
    case PatientRelationshipCategory.PATIENT_RELATIONSHIP_CATEGORY_SINGLE:
      return 'Single';
    case PatientRelationshipCategory.PATIENT_RELATIONSHIP_CATEGORY_MARRIED:
      return 'Married';
    case PatientRelationshipCategory.PATIENT_RELATIONSHIP_CATEGORY_PARTNERED:
      return 'Partnered';
    case PatientRelationshipCategory.PATIENT_RELATIONSHIP_CATEGORY_DIVORCED:
      return 'Divorced';
    case PatientRelationshipCategory.PATIENT_RELATIONSHIP_CATEGORY_WIDOWED:
      return 'Widowed';
    case PatientRelationshipCategory.PATIENT_RELATIONSHIP_CATEGORY_UNSPECIFIED:
      return NotSpecified;
    default:
      return '';
  }
};

export const EducationFormatter = (education: EducationLevel) => {
  switch (education) {
    case EducationLevel.EDUCATION_LEVEL_ASSOCIATE_DEGREE:
      return 'Associate Degree';
    case EducationLevel.EDUCATION_LEVEL_BACHELOR_DEGREE:
      return 'Bachelor\'s Degree';
    case EducationLevel.EDUCATION_LEVEL_COLLEGE_NO_DEGREE:
      return 'Some College (no degree)';
    case EducationLevel.EDUCATION_LEVEL_GRADUATE_DEGREE:
      return 'Graduate Degree';
    case EducationLevel.EDUCATION_LEVEL_HIGH_SCHOOL:
      return 'Some High School';
    case EducationLevel.EDUCATION_LEVEL_HIGH_SCHOOL_GRADUATE:
      return 'High School Graduate';
    case EducationLevel.EDUCATION_LEVEL_UNSPECIFIED:
      return NotSpecified;
    default:
      return '';
  }
};

export const LegalHistoryFormatter = (legalHistory: LegalHistoryType) => {
  switch (legalHistory) {
    case LegalHistoryType.LEGAL_HISTORY_TYPE_ARRESTED:
      return 'Has been arrested';
    case LegalHistoryType.LEGAL_HISTORY_TYPE_BANKRUPT:
      return 'Has been bankrupt';
    case LegalHistoryType.LEGAL_HISTORY_TYPE_FRAUD:
      return 'Has been a fraud';
    case LegalHistoryType.LEGAL_HISTORY_TYPE_UNSPECIFIED:
      return NotSpecified;
    default:
      return '';
  }
};

export const consumption = (metric: consumptionType) => {
  const description = metric?.description || '';
  const quantity = metric?.quantity || '';
  const unit = metric?.unit || '';
  const frequency = metric?.frequency || Frequency.FREQUENCY_NA;

  if (!quantity.length || !unit.length) { return description; }

  return `${quantity ? `${quantity} ` : ''}${unit ? `${unit} ` : ''}${frequencyFormatter(frequency)}`;
};

export const fetchLifeStyleList = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<PatientLifeStyleAPIResponse> {
  const [, providerId, patientId] = queryKey;
  return (
    method(lifestyleUrls.listLifestyle.apiUrls(
      providerId as string,
      patientId as string,
    ).list)
  );
};
