import { Alert, AlertColor, AlertProps } from '@mui/material';
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar';
import * as React from 'react';

export const ToastType: { [key in AlertColor]: AlertColor } = {
  success: 'success',
  info: 'info',
  warning: 'warning',
  error: 'error',
};

export type ToastProps = SnackbarProps & {
  message: string;
  type: AlertProps['severity'];
  onClose?: (event: React.SyntheticEvent | Event, reason?: string) => void;
};

export default function Toast({ message, type, onClose, ...rest }: ToastProps) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={type === 'success' ? 1500 : 5000}
      key={`${message}-${type}`}
      sx={{ width: '40%' }}
      open={rest.open}
      onClose={onClose}
      {...rest}
    >
      <Alert
        onClose={onClose}
        severity={type}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
