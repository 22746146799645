import { Typography, Link } from '@mui/material';
import React from 'react';

function ScaleTitle({
  scaleName,
  isLink,
  onScaleClickHandler,
}: {
  scaleName: string;
  isLink: boolean;
  onScaleClickHandler: (scale: string) => () => void;
}) {
  return isLink ? (
    <Link sx={{ cursor: 'pointer ' }} onClick={onScaleClickHandler(scaleName)} underline='hover'>
      <Typography>{scaleName}</Typography>
    </Link>
  ) : (
    <Typography>{scaleName}</Typography>
  );
}

export default ScaleTitle;
