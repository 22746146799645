import * as React from 'react';
import { Landscape } from '@mui/icons-material';
import { Typography } from '@mui/material';
import theme from 'theme';

interface IProps {
  rewardPoints?: number | string;
  size?: 'medium' | 'large';
  type?: 'added' | 'redeemed' | 'default';
}

export default function RewardPoints({ rewardPoints = 0, size = 'large', type = 'default' }: IProps) {
  const textColor = React.useMemo(() => {
    if (type === 'added') {
      return theme.custom.colors.lightTextPrimary;
    }
    if (type === 'redeemed') {
      return theme.custom.colors.lightErrorMain;
    }

    return theme.custom.colors.lightSuccessMain;
  }, [type]);

  return (
    <>
      <Typography
        variant='body1'
        marginRight='3px'
        fontSize={size === 'large' ? 24 : 16}
        color={textColor}
        data-testid='reward-points'
      >
        {rewardPoints}
      </Typography>
      <Landscape fontSize={size} sx={{ color: theme.custom.colors.lightSecondaryMain }} />
    </>
  );
}
