import { MenuItem, TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { Controller, Control } from 'react-hook-form';

export interface DropdownOption {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    label: string;
}

interface DropdownProps{
    name: string;
    control: Control;
    label: string;
    options: DropdownOption[];
    textFieldProps?: TextFieldProps;
    required?: boolean;
    disabled?:boolean;
}

export default function Dropdown({
  name,
  control,
  label,
  options,
  textFieldProps,
  required,
  disabled,
}: DropdownProps) {
  const generateSingleOptions = () => options.map((option) => (
    <MenuItem key={option.value} value={option.value}>
      {option.label}
    </MenuItem>
  ));
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <TextField
          fullWidth
          label={label}
          onChange={onChange}
          select
          value={value}
          variant='outlined'
          required={required}
          disabled={disabled}
          {...textFieldProps}
        >
          {generateSingleOptions()}
        </TextField>
      )}
    />
  );
}
