import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PageHeader from 'components/Common/PageHeader';
import { Practice } from 'types/Entitites';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import UpperText from 'components/Common/UpperText';
import SavedPractices from 'pages/Authentication/components/SavedPractices';
import PracticeForm from 'pages/Authentication/components/PracticeForm';

interface IProps {
  practices: Partial<Practice>[];
  updatePractices: (p: Partial<Practice>[]) => void;
}

const defaultPracticeValues = () => ({
  name: '',
  address: {},
});

export default function AddPractice({ practices, updatePractices }: IProps) {
  const [unsavedPractices, setUnsavedPractices] = React.useState<Partial<Practice>[]>([
    defaultPracticeValues(),
  ]);
  const [isEditing, setIsEditing] = React.useState(-1);

  const resetUnsavedPractices = () => {
    setUnsavedPractices([
      defaultPracticeValues(),
    ]);
  };

  const onPracticeSave = (values: Partial<Practice>) => {
    updatePractices([...practices, { name: values.name, address: values.address }]);
    resetUnsavedPractices();
  };

  const onPracticeUpdate = (values: Partial<Practice>, index: number) => {
    const newPractices = [...practices];
    newPractices[index] = values;
    updatePractices([...newPractices]);
    setIsEditing(-1);
  };

  const onEditClick = (index: number) => {
    setIsEditing(index);
  };

  const onEditCancel = () => {
    setIsEditing(-1);
  };

  return (
    <Box width={{ xs: '100%', md: '60%' }}>
      <Stack spacing={2}>
        <Box>
          <PageHeader title='Create An Account' titleVariant='h4' />
        </Box>
        {practices.length ? (
          <SavedPractices
            practices={practices}
            onPracticeSave={onPracticeSave}
            onPracticeUpdate={onPracticeUpdate}
            isEditing={isEditing}
            onEditClick={onEditClick}
            onEditCancel={onEditCancel}
          />
        ) : (
          <Box>
            <Typography>Add your practice(s)</Typography>
          </Box>
        )}

        {unsavedPractices.map((unSaved, index) => (
          <PracticeForm
            practices={practices}
            key={`form-${unSaved.id}`}
            isNew
            data={unSaved}
            onPracticeSave={onPracticeSave}
            index={index}
          />
        ))}

        <Box width={{ xs: '100%', sm: 'max-content' }}>
          {practices.length > 0 && (
            <Stack spacing={3}>
              <Button
                type='submit'
                sx={{ width: '100%' }}
                endIcon={<ArrowForwardIcon />}
                color='primary'
                variant='contained'
              >
                <UpperText>Continue</UpperText>
              </Button>
            </Stack>
          )}
        </Box>
      </Stack>
    </Box>
  );
}
