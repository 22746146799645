import React from 'react';
import { ArrowDropUp, ArrowDropDown } from '@mui/icons-material';
import { Box } from '@mui/material';

interface SortingIconProps {
  sortDirection: string;
  isFieldAffected : boolean;
}

function SortingIcon({ sortDirection, isFieldAffected }: SortingIconProps) {
  return (
    <Box
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <ArrowDropUp
        sx={{
          fontSize: 'large',
          color: sortDirection === 'desc' && isFieldAffected ? 'black' : 'gray',
          padding: 0,
        }}
      />
      <ArrowDropDown
        sx={{
          fontSize: 'large',
          color: sortDirection === 'asc' && isFieldAffected ? 'black' : 'gray',
          marginTop: -1,
        }}
      />
    </Box>
  );
}

export default SortingIcon;
