import { useEffect, useState } from 'react';


function useGoogleMapsSDK(): typeof globalThis.google | null {
  const [google, setGoogle] = useState<typeof globalThis.google | null>(
    window?.google ?? null,
  );

  useEffect(() => {
    if (google) {
      return;
    }
    const interval = setInterval(() => {
      if (window.google) {
        setGoogle(window.google);
        clearInterval(interval);
      }
    }, 100);
  }, [google]);

  return google;
}

export default useGoogleMapsSDK;
