import { useSearchParams } from 'react-router-dom';

export default function useSearchParamsState(
  searchParamName: string,
  defaultValue: string,
): readonly [
    searchParamsState: string,
    setSearchParamsState: (newState: string) => void
] {
  const [searchParams, setSearchParams] = useSearchParams();

  const acquiredSearchParam = searchParams.get(searchParamName);
  const searchParamsState = acquiredSearchParam ?? defaultValue;

  const setSearchParamsState = (newState: string) => {
    if (newState) {
      searchParams.set(searchParamName, newState);
    }
    else {
      searchParams.delete(searchParamName);
    }
    setSearchParams(new URLSearchParams(searchParams));
  };
  return [searchParamsState, setSearchParamsState];
}
