import { PatientBackgroundAPIResponse } from 'pages/Dashboard/types/background.types';
import { QueryFunctionContext } from 'react-query';
import { backgroundsUrls } from 'utils/apiUrls';
import { HttpType } from 'utils/http';

const fetchBackgroundList = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<PatientBackgroundAPIResponse> {
  const [, providerId, patientId] = queryKey;
  return (
    method(backgroundsUrls.listBackgrounds.apiUrls(
      providerId as string,
      patientId as string,
    ).list)
  );
};

export default fetchBackgroundList;

