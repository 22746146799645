import { Box, Button, Typography, capitalize, useTheme } from '@mui/material';
import { PatientObj } from 'pages/Dashboard/types/patient.types';
import { WaypointSuggestion } from 'pages/Dashboard/types/waypointSuggestion.types';
import React, { useCallback, useState } from 'react';
import {
  CheckCircle,
  Bedtime,
  Coffee,
  Fastfood,
  Female,
  FitnessCenter,
  Forest,
  LiveTv,
  LocationOn,
  People,
  SmokingRooms,
  ThumbUp,
  WbSunny,
  WineBar,
} from '@mui/icons-material';
import { ACTION_CONFIRMATION_VIEW } from 'pages/Dashboard/enums';
import mixpanel from 'mixpanel-browser';
import { MixpanelEventName } from 'pages/Dashboard/types/mixpanel.types';

interface RevokeWaypointSuggestionsProps {
  patient: PatientObj;
  waypointSuggestions: WaypointSuggestion[];
  handleOpenDrawer: () => void;
  handleCloseDrawer: () => void;
  handleDeleteConfirmation: () => void;
}

enum WaypointIconNames {
  Social = 'social',
  Diet = 'diet',
  Exercise = 'exercise',
  Sleep = 'sleep',
  Alcohol = 'alcohol',
  TV = 'television',
  Marijuana = 'marijuana',
  Caffeine = 'caffeine',
  Nicotine = 'nicotine',
  SocialMedia = 'socialmedia',
  Meditation = 'meditation',
  Libido = 'libido',
  Custom = 'Custom',
}

const getWaypointsIcon = (waypointName: string, color: string) => {
  switch (waypointName) {
    case WaypointIconNames.Social:
      return <People fontSize='small' sx={{ color }} />;
    case WaypointIconNames.Diet:
      return <Fastfood fontSize='small' sx={{ color }} />;
    case WaypointIconNames.Exercise:
      return <FitnessCenter fontSize='small' sx={{ color }} />;
    case WaypointIconNames.Sleep:
      return <Bedtime fontSize='small' sx={{ color }} />;
    case WaypointIconNames.Alcohol:
      return <WineBar fontSize='small' sx={{ color }} />;
    case WaypointIconNames.TV:
      return <LiveTv fontSize='small' sx={{ color }} />;
    case WaypointIconNames.Caffeine:
      return <Coffee fontSize='small' sx={{ color }} />;
    case WaypointIconNames.Marijuana:
      return <Forest fontSize='small' sx={{ color }} />;
    case WaypointIconNames.Libido:
      return <Female fontSize='small' sx={{ color }} />;
    case WaypointIconNames.SocialMedia:
      return <ThumbUp fontSize='small' sx={{ color }} />;
    case WaypointIconNames.Meditation:
      return <WbSunny fontSize='small' sx={{ color }} />;
    case WaypointIconNames.Nicotine:
      return <SmokingRooms fontSize='small' sx={{ color }} />;
    case WaypointIconNames.Custom:
      return <LocationOn fontSize='small' sx={{ color }} />;
    default:
      return <LocationOn fontSize='small' sx={{ color }} />;
  }
};

export default function RevokeWaypointSuggestions({
  patient,
  waypointSuggestions,
  handleOpenDrawer,
  handleCloseDrawer,
  handleDeleteConfirmation,
}: RevokeWaypointSuggestionsProps) {
  const [view, setView] = useState(ACTION_CONFIRMATION_VIEW.DEFAULT);
  const theme = useTheme();

  const handleRevokeSuggestion = useCallback(() => {
    mixpanel.track(MixpanelEventName.REVOKE_SUGGESTIONS_BUTTON_CLICK);
    setView(ACTION_CONFIRMATION_VIEW.CONFIRMATION);
  }, []);

  const handleConfirmRevokeSuggestion = useCallback(async () => {
    mixpanel.track(MixpanelEventName.REVOKE_SUGGESTIONS_CONFIRMATION_BUTTON_CLICK);
    handleDeleteConfirmation();
    setView(ACTION_CONFIRMATION_VIEW.SUCCESS);
  }, [handleDeleteConfirmation]);

  const handleCancelRevokeSuggestion = useCallback(() => {
    mixpanel.track(MixpanelEventName.REVOKE_SUGGESTIONS_CANCEL_BUTTON_CLICK);
    setView(ACTION_CONFIRMATION_VIEW.DEFAULT);
  }, []);

  const handleClose = useCallback(
    ({ shouldOpenNewSuggestion }) => {
      if (shouldOpenNewSuggestion) {
        handleCloseDrawer();
        handleOpenDrawer();
      }
      else {
        handleCloseDrawer();
      }
    },
    [handleOpenDrawer, handleCloseDrawer],
  );

  const patientName = patient?.name?.firstName && patient?.name?.firstName?.length > 0
    ? capitalize(patient?.name?.firstName ?? '')
    : 'The patient';

  return (
    <Box display='flex' flexDirection='column' gap={2} sx={{ width: '100%' }}>
      {view === ACTION_CONFIRMATION_VIEW.DEFAULT && (
        <>
          <Typography variant='h5' fontWeight={400}>
            Pending Suggestions
          </Typography>
          <Box display='flex' flexDirection='column' gap={1} mx={2}>
            {waypointSuggestions.map((waypointSuggestion) => (
              <Box display='flex' gap={1} alignItems='center'>
                {getWaypointsIcon(
                  waypointSuggestion.category.toLowerCase() === 'custom'
                    ? WaypointIconNames.Custom
                    : waypointSuggestion.name.toLowerCase(),
                  theme.custom.colors.lightTextSecondary,
                )}
                <Typography variant='body1' color={theme.custom.colors.lightTextSecondary}>
                  {`${waypointSuggestion.displayName} ${
                    waypointSuggestion.suggestionType === 'ADD' ? '(add)' : '(remove)'
                  }`}
                </Typography>
              </Box>
            ))}
          </Box>
          <Typography>
            {`${patientName} has not accepted/rejected these changes yet, so they can be revoked.`}
          </Typography>
          <Typography>Once revoked, you can send new suggestions.</Typography>
          <Button
            variant='contained'
            color='error'
            sx={{ width: 'fit-content' }}
            onClick={handleRevokeSuggestion}
          >
            REVOKE SUGGESTIONS
          </Button>
        </>
      )}
      {view === ACTION_CONFIRMATION_VIEW.CONFIRMATION && (
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          gap={2}
          justifyContent='space-around'
        >
          <Typography variant='h5' fontWeight={400}>
            Please confirm
          </Typography>
          <Typography variant='h6' fontWeight={500}>
            Are you sure you want to revoke these waypoint suggestions?
          </Typography>
          {waypointSuggestions.map((waypointSuggestion) => (
            <Box key={waypointSuggestion.name} display='flex' gap={1} alignItems='center'>
              {getWaypointsIcon(
                waypointSuggestion.category.toLowerCase() === 'custom'
                  ? WaypointIconNames.Custom
                  : waypointSuggestion.name.toLowerCase(),
                theme.custom.colors.lightTextSecondary,
              )}
              <Typography color={theme.custom.colors.lightTextSecondary}>
                {`${waypointSuggestion.displayName} ${
                  waypointSuggestion.suggestionType === 'ADD' ? '(add)' : '(remove)'
                }`}
              </Typography>
            </Box>
          ))}
          <Box display='flex' gap={2}>
            <Button variant='contained' color='error' onClick={handleConfirmRevokeSuggestion}>
              YES, REVOKE
            </Button>
            <Button variant='outlined' onClick={handleCancelRevokeSuggestion}>
              CANCEL
            </Button>
          </Box>
        </Box>
      )}
      {view === ACTION_CONFIRMATION_VIEW.SUCCESS && (
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          gap={2}
          justifyContent='space-around'
        >
          <Box display='flex' alignItems='center' gap={1}>
            <CheckCircle fontSize='large' color='success' />
            <Typography variant='h5' sx={{ mb: 0 }} fontWeight={400}>
              Success!
            </Typography>
          </Box>
          <Typography variant='h6' fontWeight={500}>
            Waypoint suggestions have been revoked.
          </Typography>
          <Box display='flex' gap={2}>
            <Button
              variant='contained'
              onClick={() => handleClose({ shouldOpenNewSuggestion: true })}
            >
              MAKE NEW SUGGESTIONS
            </Button>
            <Button variant='outlined' onClick={handleClose}>
              CLOSE
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}
