import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import CardHeader from 'pages/Dashboard/components/CardHeader';
import NoData from 'pages/Dashboard/components/NoData';
import HealthRecordCategories from 'pages/Dashboard/HealthRecordCategories';
import { ShowMoreButton, ShowMoreDrawerChildProps, ShowMoreDrawerTitle, sliceForShowMore } from 'pages/Dashboard/ShowMoreDrawer';
import { PatientHospitalization } from 'pages/Dashboard/types/hospitalizations.types';
import * as React from 'react';
import theme from 'theme';
import { googleDateToDateString } from 'utils/dateUtils';


export interface HospitalizationsCardProps extends ShowMoreDrawerChildProps {
  patientHospitalizations?: PatientHospitalization[];
}

export default function HospitalizationsCard({
  closeDrawer,
  drawerItem,
  drawerMode = false,
  patientHospitalizations = [],
  onShowMore,
}: HospitalizationsCardProps): JSX.Element {
  const data = sliceForShowMore(patientHospitalizations, !drawerMode);


  return (
    <Grid item xs={12} md={!drawerMode ? 4 : undefined} key='hospitalizations'>
      <Card sx={{ height: '100%' }}>
        {!drawerMode && (
          <CardHeader
            icon={HealthRecordCategories.diagnosis.value.icon}
            title='Hospitalizations'
          />
        )}
        {drawerMode && <ShowMoreDrawerTitle closeDrawer={closeDrawer} drawerItem={drawerItem} />}

        <CardContent>
          {patientHospitalizations.length ? data.map((hospitalization) => (
            <Box key={`hospitalization-${hospitalization.id}`} mb={2}>
              <Typography variant='body1' color={theme.custom.colors.lightTextSecondary}>
                {`${googleDateToDateString(hospitalization.startDate)}
                        -
                  ${googleDateToDateString(hospitalization.endDate)}`}
              </Typography>

              {hospitalization.locations.map((location) => (
                <Typography>{`${location.name} `}</Typography>
              ))}
              {hospitalization.diagnosis.map((patientDiagnosis) => (
                <Typography
                  key={patientDiagnosis.id}
                  variant='subtitle1'
                >
                  {patientDiagnosis.name}
                </Typography>
              ))}
            </Box>
          )) : <NoData />}
          <ShowMoreButton
            isDrawer={drawerMode}
            onShowMore={onShowMore as VoidFunction}
            items={patientHospitalizations}
          />
        </CardContent>
      </Card>
    </Grid>
  );
}
