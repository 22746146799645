import { ArrowForward } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PageHeader from 'components/Common/PageHeader';
import UpperText from 'components/Common/UpperText';
import * as React from 'react';
import { EProfessions, Provider } from 'types/Entitites';

interface IProps {
  provider: Partial<Provider>;
  updateProvider: (p: Partial<Provider>) => void;
  isEdit: boolean;
  onEdit?: () => void;
  onCancel?: () => void;
}

export default function CreateProviderForm(props: IProps) {
  const designation = React.useMemo(
    () => EProfessions[props.provider.designation as unknown as keyof typeof EProfessions],
    [props.provider.designation],
  );

  return (
    <Box width={{ xs: '100%', md: `${props.isEdit ? '100%' : '50%'}` }}>
      <Stack spacing={2}>
        {!props.isEdit && (
          <>
            <Box>
              <PageHeader title='Create An Account' titleVariant='h4' />
            </Box>
            <Box>
              <Typography>Nice to meet you</Typography>
            </Box>
          </>
        )}
        <Box>
          <TextField
            fullWidth
            label='First Name'
            onChange={(e) => props.updateProvider({
              ...props.provider,
              name: { ...props.provider.name, firstName: e.target.value },
            })}
            required
            value={props.provider.name?.firstName || ''}
          />
        </Box>
        <Box>
          <TextField
            fullWidth
            label='Last Name'
            onChange={(e) => props.updateProvider({
              ...props.provider,
              name: { ...props.provider.name, lastName: e.target.value },
            })}
            required
            value={props.provider.name?.lastName || ''}
          />
        </Box>
        <Box>
          <TextField
            fullWidth
            inputProps={{ type: 'email' }}
            label='Email'
            required
            onChange={(e) => props.updateProvider({
              ...props.provider,
              email: e.target.value,
            })}
            value={props.provider.email || ''}
          />
        </Box>
        <Box>
          <Typography>Tell us about your profession</Typography>
        </Box>
        <Box>
          <TextField
            fullWidth
            label='Professional Designation'
            onChange={(e) => props.updateProvider({
              ...props.provider,
              designation: e.target.value as EProfessions,
            })}
            required
            select
            value={props.provider.designation || ''}
          >
            {Object.entries(EProfessions).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        {props.provider.designation
        && ![
          EProfessions.PROVIDER_DESIGNATION_BILLING_ADMIN,
          EProfessions.PROVIDER_DESIGNATION_OFFICE_STAFF,
        ].includes(designation) ? (
          <Box width={{ md: '50%' }}>
            <TextField
              fullWidth
              helperText='Enter digits without dashes'
              inputProps={{ max: 9999999999, min: 1000000000, type: 'number' }}
              label='NPI Number'
              value={props.provider.npiNumber || ''}
              onChange={(e) => props.updateProvider({
                ...props.provider,
                npiNumber: parseInt(e.target.value, 10),
              })}
              required
            />
          </Box>
          ) : null}
        <Box>
          {props.isEdit ? (
            <Box display='flex' gap={3}>
              <Button color='primary' variant='outlined' onClick={props.onCancel}>
                <UpperText>Cancel</UpperText>
              </Button>
              <Button color='primary' variant='contained' onClick={props.onEdit}>
                <UpperText>Update</UpperText>
              </Button>
            </Box>
          ) : (
            <Tooltip title='Add Practice'>
              <Button color='primary' endIcon={<ArrowForward />} type='submit' variant='contained'>
                <UpperText>Continue</UpperText>
              </Button>
            </Tooltip>
          )}
        </Box>
      </Stack>
    </Box>
  );
}
