import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import { useTheme } from '@mui/material';
import { Practice } from 'types/Entitites';
import PracticeForm from 'pages/Authentication/components/PracticeForm';


interface IProps {
  practices: Partial<Practice>[];
  isEditing: number;
  onEditClick: (index: number) => void;
  onEditCancel: () => void;
  onPracticeSave: (values: Partial<Practice>) => void;
  onPracticeUpdate: (values: Partial<Practice>, index: number) => void;
}

export default function AddedPracticesList({
  practices,
  onPracticeSave,
  onEditClick,
  onEditCancel,
  isEditing,
  onPracticeUpdate,
}: IProps) {
  const theme = useTheme();

  return (
    <>
      {practices.map((practice, index) => {
        if (!practice?.name || !practice?.address) {
          return null;
        }
        const {
          name,
          address: {
            city, state, zip, street,
          },
        } = practice;
        const addressString = `${city}, ${state} ${zip}`;
        return (
          <Box key={name}>
            {index === isEditing ? (
              <PracticeForm
                isNew={false}
                key={`form-${name}`}
                data={practice}
                onPracticeSave={onPracticeSave}
                onPracticeUpdate={onPracticeUpdate}
                onEditCancel={onEditCancel}
                index={index}
              />
            ) : (
              <Box key={`${name}${city}${zip}`}>
                <Box display='flex' gap={1}>
                  <Typography>{name}</Typography>
                  <EditIcon
                    onClick={() => onEditClick(index)}
                    sx={{ cursor: 'pointer' }}
                    fontSize='small'
                  />
                </Box>
                <Typography sx={{ lineHeight: theme.spacing(3) }} color='black' variant='body2'>
                  {street}
                  <br />
                  {addressString}
                </Typography>
              </Box>
            )}
          </Box>
        );
      })}
    </>
  );
}
