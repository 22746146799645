import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import PageHeader from 'components/Common/PageHeader';
import * as React from 'react';


export default function Contact() {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <PageHeader title='Contact Us' titleVariant='h4' />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField fullWidth placeholder='First Name' required />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField fullWidth placeholder='Last Name' required />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField fullWidth inputProps={{ type: 'email' }} placeholder='Email' required />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            fullWidth
            multiline
            rows={4}
            placeholder='Question Or Comment'
            required
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Button color='primary' type='submit' variant='contained'>Get In Touch</Button>
        </Grid>
      </Grid>
    </form>
  );
}
