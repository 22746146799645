import * as React from 'react';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Modal from '@mui/material/Modal';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Spinner from 'components/Common/Spinner';
import Toast, { ToastProps, ToastType } from 'components/Common/Toast';
import { useHttp } from 'hooks/use-fetch';
import {
  ApproveProviderRequestPayload,
  ListProviderRequestAPIResponse,
  PracticeRequestAction,
  ProviderRequest,
} from 'pages/Authentication/types/CreateProvider';
import { Practice } from 'types/Entitites';
import { Logger } from 'utils/logger';
import { addressSerializar } from 'utils/phoneUtils';
import BorderTableRow from 'components/Common/BorderTableRow';
import NoProviderRequests from 'pages/Admin/NoProviderRequests';
import PracticeConnectionForm from 'pages/Admin/PracticeConnectionForm';
import { useMutation, useQuery } from 'react-query';
import { providerSignUpUrls } from 'utils/apiUrls';
import { listProviderRequest } from 'pages/Dashboard/services/providersignup.services';
import approveProviderRequest from 'pages/Dashboard/services/approveprovider.services';

function ProviderPracticeList({ practices }: { practices: Practice[] }) {
  return (
    <List>
      {practices.map((practice) => (
        <ListItem key={`practice-${practice.id}`}>
          <ListItemText primary={practice.name} secondary={addressSerializar(practice.address)} />
        </ListItem>
      ))}
    </List>
  );
}

export default function ProviderRequestList() {
  const { http } = useHttp();

  const [toastProps, setToastProps] = React.useState<ToastProps | undefined>();
  const [editConnection, setEditConnection] = React.useState<Practice | null>(null);
  const [editRequest, setEditRequest] = React.useState<ProviderRequest | null>(null);
  const [providerConnectionData, setProviderConnectionData] = React.useState<{
    practices: Practice[];
  }>({ practices: [] });

  const {
    data,
    isFetching,
    refetch: refetchProviderList,
  } = useQuery([providerSignUpUrls.listProviderRequest.queryUrl], {
    queryFn: listProviderRequest(http.get),
  });

  const { mutate: approveProvider, reset, isLoading, isSuccess, isError, error } = useMutation({
    mutationFn: ({
      providerId,
      payload,
    }: {
      providerId: string;
      payload: ApproveProviderRequestPayload;
    }) => approveProviderRequest(http.post, providerId, { ...payload }),
  });

  const providerRequestListResponse = data as ListProviderRequestAPIResponse;

  React.useEffect(() => {
    if (isLoading) {
      return;
    }

    if (isSuccess) {
      setToastProps({ message: 'Approved', open: true, type: ToastType.success });
      reset();
      setProviderConnectionData({ practices: [] });
      refetchProviderList();
    }
    else if (isError) {
      setToastProps({
        message:
          (error as Error)?.message || 'Something went wrong',
        open: true,
        type: ToastType.error,
      });
      setProviderConnectionData({ practices: [] });
      reset();
    }
  }, [refetchProviderList, reset, http.get, isLoading, isSuccess, isError, error]);

  if (isFetching) {
    return <Spinner />;
  }

  const requests = providerRequestListResponse?.requests ?? [];

  function updateProviderConnection(practice: Practice) {
    const val = providerConnectionData.practices.findIndex((e) => e.id === practice.id);
    const practices = [...providerConnectionData.practices];

    if (val > -1) {
      providerConnectionData.practices.splice(val, 1, practice);
      setProviderConnectionData({
        ...providerConnectionData,
      });
    }
    else {
      setProviderConnectionData({
        ...providerConnectionData,
        practices: [...providerConnectionData.practices, practice],
      });
      practices.push(practice);
    }
    handleApproveProviderRequest(practices);
    setEditConnection(null);
  }

  const handleApproveProviderRequest = (practices: Practice[]) => {
    if (!editRequest || !editRequest.id) {
      Logger.error('Missing request id');
      return;
    }

    const approveProviderRequestPayload: ApproveProviderRequestPayload = {
      id: editRequest.id,
      practices: practices.map((practice) => ({
        ...practice,
        action: PracticeRequestAction.PRACTICE_REQUEST_ACTION_CREATE,
        id: practice.id,
      })),
    };

    approveProvider({
      providerId: editRequest.id,
      payload: approveProviderRequestPayload,
    });

    setEditRequest(null);
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setToastProps(undefined);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label='provider-requests-table'>
          <TableHead>
            <TableRow>
              <TableCell>Provider Name</TableCell>
              <TableCell>Provider Email</TableCell>
              <TableCell>Practices</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requests?.length > 0 ? (
              requests.map((request) => (
                <BorderTableRow key={`provider-request-${request.id}`}>
                  <TableCell>
                    {`${request.name.firstName || ''} ${request.name.lastName || ''}`}
                  </TableCell>
                  <TableCell>{request.email}</TableCell>
                  <TableCell>
                    <ProviderPracticeList practices={request.practices} />
                  </TableCell>
                  <TableCell>
                    {request.practices.map((practice) => (
                      <Button
                        key={practice.id}
                        onClick={() => {
                          setEditConnection(practice);
                          setEditRequest(request);
                        }}
                      >
                        Edit Connection
                      </Button>
                    ))}

                    {/* { approveProviderRequestResponse?.isLoading ? <Spinner />
                    : (
                      <Button
                        disabled={approveProviderRequestResponse?.IGNITE}
                        onClick={() => {
                          // do nothing
                          // handleApproveProviderRequest(request)
                        }}
                      >
                        Edit connections
                      </Button>
                    )} */}
                  </TableCell>
                </BorderTableRow>
              ))
            ) : (
              <NoProviderRequests />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {toastProps ? <Toast {...toastProps} onClose={handleClose} /> : null}

      {editConnection && (
        <Modal
          key={editConnection?.id}
          open
          onClose={() => {
            setEditConnection(null);
          }}
          sx={{
            width: 600,
            margin: 'auto',
            marginTop: 4,
            overflow: 'scroll',
          }}
        >
          <PracticeConnectionForm
            key={editConnection.id}
            practice={editConnection}
            updateProviderConnection={updateProviderConnection}
            providerConnection={providerConnectionData}
          />
        </Modal>
      )}
    </>
  );
}
