export const auth = {
  contact: '/auth/contact-us',
  forgotPassword: '/auth/forgot-password',
  landingPad: '/auth/landing-pad',
  login: '/auth/login',
  logout: '/auth/logout',
  signup: '/auth/signup',
};

export const mainNav = {
  dashboard: '/dashboard',
  dashboardPending: '/dashboard_pending',
  dashboardError: '/dashboard_error',
  patientDetail: (id = ':id') => `/patient/${id}`,
  addPatient: '/add-patient',
  addNewPatient: '/add-new-patient',
  reports: '/reports',
};

export const adminRoutes = {
  providerRequests: '/provider-requests',
};

export const superAdminRoutes = {
  providersPracticeList: '/providers-list',
};

export const landing = mainNav.dashboard;
