import { ApproveProviderRequestPayload } from 'pages/Authentication/types/CreateProvider';
import { providerRequestActionUrls } from 'utils/apiUrls';
import { HttpType } from 'utils/http';

const approveProviderRequest = (method: HttpType['post'], providerId: string, payload: ApproveProviderRequestPayload) => method(providerRequestActionUrls.approveProviderRequest.apiUrls(
      providerId as string,
).approveProviderRequest, payload);


export default approveProviderRequest;
