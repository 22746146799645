import { PatientRecordRetrievalStatus } from 'pages/Dashboard/types';
import { DiagnosisCode } from 'pages/Dashboard/types/diagnosis.types';

export enum RecordRetrievalState {
  Success = 'success',
  Error = 'error',
  Fetching = 'fetching',
}

export const patientRetrievalStatusMap: {
  [key in PatientRecordRetrievalStatus]: RecordRetrievalState;
} = {
  [PatientRecordRetrievalStatus.PATIENT_RECORD_RETRIEVAL_STATUS_FAILED]: RecordRetrievalState.Error,
  [PatientRecordRetrievalStatus.PATIENT_RECORD_RETRIEVAL_STATUS_FETCHED]:
    RecordRetrievalState.Fetching,
  [PatientRecordRetrievalStatus.PATIENT_RECORD_RETRIEVAL_STATUS_FETCHING]:
    RecordRetrievalState.Fetching,
  [PatientRecordRetrievalStatus.PATIENT_RECORD_RETRIEVAL_STATUS_PENDING]:
    RecordRetrievalState.Fetching,
  [PatientRecordRetrievalStatus.PATIENT_RECORD_RETRIEVAL_STATUS_RETRIEVED]:
    RecordRetrievalState.Success,
  [PatientRecordRetrievalStatus.PATIENT_RECORD_RETRIEVAL_STATUS_TRANSFORMED]:
    RecordRetrievalState.Fetching,
  [PatientRecordRetrievalStatus.PATIENT_RECORD_RETRIEVAL_STATUS_TRANSFORMING]:
    RecordRetrievalState.Fetching,
  [PatientRecordRetrievalStatus.PATIENT_RECORD_RETRIEVAL_STATUS_UNSPECIFIED]:
    RecordRetrievalState.Fetching,
};

export function getPatientRecordRetrievalStatus(
  retrievalStatus?: PatientRecordRetrievalStatus,
): RecordRetrievalState {
  if (!retrievalStatus) {
    return RecordRetrievalState.Error;
  }
  return patientRetrievalStatusMap?.[retrievalStatus] ?? RecordRetrievalState.Error;
}

export function findPreferredDiagnosisCode(codes?: DiagnosisCode[]): DiagnosisCode | null {
  if (!codes || !codes.length) {
    return null;
  }
  // Define regular expressions for each code type
  const regexIcd10 = /icd-?10/i;
  const regexIcd9 = /icd-?9/i;
  const regexSct = /sct/i;

  // Check for icd10 codes first
  const icd10Code = codes.find((code) => regexIcd10.test(code.codeType));
  if (icd10Code) {
    return icd10Code;
  }

  // Check for icd9 codes next
  const icd9Code = codes.find((code) => regexIcd9.test(code.codeType));
  if (icd9Code) {
    return icd9Code;
  }

  // Finally, check for sct codes
  const sctCode = codes.find((code) => regexSct.test(code.codeType));
  if (sctCode) {
    return sctCode;
  }

  // Return undefined if no preferred code is found
  return null;
}
