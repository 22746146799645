import { Box, Grid, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import {

} from 'recharts';
import Theme from 'theme';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const activityBarsMap: Record<
  string,
  {
    key: string;
    goalKey: string;
    color: string;
    normalColor: string;
    label: string;
    count: number;
    units: string;
    goalReachedCount: number;
  }
> = {
  activeEnergyBurned: {
    key: 'activeEnergyBurned',
    goalKey: 'activeEnergyBurnedGoal',
    color: '#CC3B3B',
    normalColor: '#ECBBBB',
    label: 'Move',
    count: 0,
    units: 'cal',
    goalReachedCount: 0,
  },
  appleExerciseTime: {
    key: 'appleExerciseTime',
    goalKey: 'appleExerciseTimeGoal',
    color: '#4A7F4B',
    normalColor: '#B8E3B9',
    label: 'Exercise',
    count: 0,
    units: 'mins',
    goalReachedCount: 0,
  },
  appleStandHours: {
    key: 'appleStandHours',
    goalKey: 'appleStandHoursGoal',
    color: '#39AEBE',
    normalColor: '#A5DCE4',
    label: 'Stand',
    count: 0,
    units: 'hrs',
    goalReachedCount: 0,
  },
};


interface AppleHealthActivityChartProps {
  averages: { key: string; value: string }[];
  timeFrame: string;
  previousPeriodAverages: { key: string; value: string }[];
}

function AppleHealthActivitySummary({
  averages,
  timeFrame,
  previousPeriodAverages,
}: AppleHealthActivityChartProps) {
  const averageMap = averages?.reduce(
    (a, avg) => {
      if (avg?.key) {
        return {
          ...a,
          [avg.key]: Math.round(+avg.value),
        };
      }
      return a;
    },
   {} as Record<string, number>,
  );

  const previousPeriodAverageMap = previousPeriodAverages?.reduce(
    (a, avg) => {
      if (avg?.key) {
        return {
          ...a,
          [avg.key]: Math.round(+avg.value),
        };
      }
      return a;
    },
    {} as Record<string, number>,
  );

  const priorAvgText = useMemo(() => `prior ${timeFrame.replace(' ', '-').replace('s', '')} avg.`, [timeFrame]);
  const averageCategories = Object.values(activityBarsMap);

  return (
    <Box flex={1} display='flex' flexDirection='column' width='100%'>
      <Grid container display='flex' width='100%'>
        {averageCategories.map((bar, index) => {
          const isUp = averageMap[bar.key]
          && previousPeriodAverageMap[bar.key]
          && averageMap[bar.key] !== previousPeriodAverageMap[bar.key]
            ? averageMap[bar.key] > previousPeriodAverageMap[bar.key]
            : undefined;

          const ArrowIcon = isUp
            ? ArrowDropUpIcon
            : ArrowDropDownIcon;
          return (
            <Grid item key={bar.goalKey} mb={2} gap={2}>
              <Box pr={index < averageCategories.length ? 1 : 0}>
                <Typography variant='body1' fontWeight='700' color={bar.color} textAlign='left' sx={{ textWrap: 'nowrap' }}>
                  {bar.label}
                </Typography>
                <Box display='flex' alignItems='center' height={20} mb={2}>
                  {averageMap[bar.key] > 0 ? (
                    <Box display='flex' alignItems='flex-end'>
                      <Typography variant='body1' fontSize={20} fontWeight={700} lineHeight='100%' mr={0.5} sx={{ textWrap: 'nowrap' }}>
                        {averageMap[bar.key] ?? 0}
                      </Typography>
                      <Typography lineHeight='100%' color={Theme.custom.colors.lightTextSecondary} sx={{ textWrap: 'nowrap' }}>{bar.units}</Typography>
                    </Box>
                  ) : '-'}
                  {isUp !== undefined && <ArrowIcon color='action' />}
                </Box>
                <Box>
                  <Typography fontSize={12} color={Theme.custom.colors.lightTextSecondary} mb={0.5} textAlign='center' sx={{ textWrap: 'nowrap' }}>
                    {priorAvgText}
                  </Typography>
                  <Typography display='flex' alignItems='baseline' sx={{ textWrap: 'nowrap' }}>
                    <Typography lineHeight='100%' mr={0.5} fontWeight={400} sx={{ textWrap: 'nowrap' }}>
                      {previousPeriodAverageMap[bar.key] || '-'}
                    </Typography>
                    {previousPeriodAverageMap[bar.key] > 0 && <Typography variant='body2' lineHeight='100%' color={Theme.custom.colors.lightTextSecondary}>{bar.units}</Typography>}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>

    </Box>
  );
}

export default AppleHealthActivitySummary;
