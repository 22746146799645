import { ListProviderPracticeAPIResponse } from 'pages/SuperAdmin/types';
import { providerPracticesUrls } from 'utils/apiUrls';
import { HttpType } from 'utils/http';

const listProviderPracticeRequest = (method: HttpType['get']) => function async(): Promise<ListProviderPracticeAPIResponse> {
  return (
    method(providerPracticesUrls.listProviderPractices.apiUrls().list)
  );
};

export default listProviderPracticeRequest;
