import { styled, TableRow } from '@mui/material';
import * as React from 'react';

interface BorderTableRowProps{
    children: React.ReactNode[]
}

const RowComponent = styled(TableRow)(
  {
    '&:last-child td, &:last-child th': { border: 0 },
  },
);

export default function BorderTableRow({ children }: BorderTableRowProps) {
  return (
    <RowComponent>
      {children}
    </RowComponent>
  );
}


