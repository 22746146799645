import React from 'react';
import { Select, MenuItem, Typography, FormControl, SelectChangeEvent, Box } from '@mui/material';
import { PracticeProvider } from 'pages/Dashboard/types/providers.types';

interface PatientDropdownProps {
  providers: PracticeProvider[]; // You can pass the list of patients as a prop
  selectedProvider: string;
  setSelectedProvider: (provider:string) => void;
}

function SelectProvider({
  providers,
  selectedProvider,
  setSelectedProvider,
}: PatientDropdownProps) {
  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedProvider(event.target.value);
  };
  if (providers.length === 0) {
    return null;
  }
  return (
    <Box style={styles.container}>
      <Typography variant='body1' style={styles.label}>
        Clinician:
      </Typography>
      <FormControl variant='outlined' size='small' style={styles.selectControl}>
        <Select
          labelId='patient-select-label'
          value={selectedProvider || 'all'}
          onChange={handleChange}
          style={styles.selectControl}
          defaultValue='all'
          defaultChecked
        >
          {providers.map((provider) => (
            <MenuItem key={provider.id} value={provider.id}>
              {`${provider.firstName} ${provider.lastName}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    padding: '10px',
  },
  label: {
    fontSize: '16px',
    color: '#787878',
  },
  selectControl: {
    minWidth: '200px',
    backgroundColor: '#fff',
  },
};

export default SelectProvider;
