import { Box, Button, CircularProgress, Divider, Drawer } from '@mui/material';
import EditWaypoint from 'components/CustomIcons/EditWaypoint';
import mixpanel from 'mixpanel-browser';
import EditWaypoints from 'pages/Dashboard/EditWaypoints';
import { EDIT_WAYPOINT_SUGGESTIONS_VIEW } from 'pages/Dashboard/enums';
import { Waypoints } from 'pages/Dashboard/types';
import { MixpanelEventName } from 'pages/Dashboard/types/mixpanel.types';
import { PatientObj } from 'pages/Dashboard/types/patient.types';
import { WaypointSuggestion } from 'pages/Dashboard/types/waypointSuggestion.types';
import DrawerHeader from 'pages/Rewards/components/DrawerHeader';
import React, { useState } from 'react';
import theme from 'theme';

interface EditWaypointsButtonProps {
  patient: PatientObj;
  standardWaypoints: Waypoints[];
  patientWaypoints: Waypoints[];
  waypointSuggestions: WaypointSuggestion[];
  handleDeleteConfirmation: () => void;
  handleSendSuggestions: (suggestions: Partial<WaypointSuggestion>[]) => Promise<void>;
  isLoading: boolean;
  refetch: () => Promise<void>;
}

export default function EditWaypointsButton({
  patient,
  standardWaypoints,
  patientWaypoints,
  waypointSuggestions,
  handleDeleteConfirmation,
  handleSendSuggestions,
  isLoading = false,
  refetch,
}: EditWaypointsButtonProps) {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenDrawer = () => {
    setIsOpen(true);
  };

  const handleCloseDrawer = () => {
    mixpanel.track(MixpanelEventName.EDIT_WAYPOINTS_PANEL_CLOSED);
    refetch();
    setIsOpen(false);
  };

  const onEditWaypointsButtonClick = async () => {
    mixpanel.track(MixpanelEventName.EDIT_WAYPOINTS_BUTTON_CLICK, {
      'patient has active waypoints': patientWaypoints.length > 0,
      yes: 'no',
    });
    await refetch();
    handleOpenDrawer();
  };

  return (
    <>
      <Button variant='outlined' onClick={onEditWaypointsButtonClick} disabled={isLoading}>
        <EditWaypoint sx={{ mr: 1 }} />
        {patientWaypoints.length || waypointSuggestions.length ? 'EDIT' : 'SUGGEST'}
        {' '}
        WAYPOINTS
        {isLoading && <CircularProgress sx={{ ml: 1 }} size={14} />}
      </Button>
      <Drawer
        anchor='right'
        open={isOpen}
        onClose={handleCloseDrawer}
        sx={{ zIndex: theme.zIndex.drawer + 2 }}
        PaperProps={{ sx: { maxWidth: '650px', width: '100%' } }}
        data-testid='edit-waypoints-drawer'
      >
        <Box role='grid'>
          <DrawerHeader title='Edit Waypoints' onClose={handleCloseDrawer} showBackIcon={false} />
          <Divider />

          {isOpen && (
            <EditWaypoints
              view={
                waypointSuggestions.length
                  ? EDIT_WAYPOINT_SUGGESTIONS_VIEW.REVOKE_WAYPOINT_SUGGESTIONS
                  : EDIT_WAYPOINT_SUGGESTIONS_VIEW.SEND_WAYPOINT_SUGGESTIONS
              }
              patient={patient}
              standardWaypoints={standardWaypoints}
              patientWaypoints={patientWaypoints}
              waypointSuggestions={waypointSuggestions}
              handleOpenDrawer={handleOpenDrawer}
              handleCloseDrawer={handleCloseDrawer}
              handleDeleteConfirmation={handleDeleteConfirmation}
              handleSendSuggestions={handleSendSuggestions}
            />
          )}
        </Box>
      </Drawer>
    </>
  );
}
