import { Box, Card, CardContent, Grid, Tab, Tabs, Typography } from '@mui/material';
import CardHeader from 'pages/Dashboard/components/CardHeader';
import NoDataForNow from 'pages/Dashboard/components/NoDataForNow';
import HealthRecordCategories from 'pages/Dashboard/HealthRecordCategories';
import {
  ShowMoreButton,
  ShowMoreDrawerChildProps,
  ShowMoreDrawerTitle,
  sliceForShowMore,
} from 'pages/Dashboard/ShowMoreDrawer';
import { GroupedSymptoms, NestedSymptoms, PatientSymptom } from 'pages/Dashboard/types/symptoms.types';
import * as React from 'react';
import theme from 'theme';

enum SymptomsTabLabels{
  WITHIN_30_DAYS = 'WITHIN 30 DAYS',
  PRIOR = 'PRIOR',
}

export interface SymptomsCardProps extends ShowMoreDrawerChildProps {
  reportedSymptoms?: PatientSymptom[],
  reportedSymptomsHistory?: PatientSymptom[],
  onShowMoreSymptoms?:(idx?: number) => void;
  drawerTabIndex?: number;
}

interface TabPanelsProps{
  children: React.ReactNode;
  groupedSymptom: GroupedSymptoms,
  value: number,
  index: number,
}

function SymptomsCardTabPanels(
  { children, groupedSymptom, value, index }: TabPanelsProps,
): JSX.Element {
  return (
    <Box hidden={value !== index}>
      { !Object.keys(groupedSymptom).length ? <NoDataForNow />
        : Object.keys(groupedSymptom).map((categoryKey) => (
          <React.Fragment key={`health-record-${categoryKey}`}>
            <Typography variant='body1' color={theme.custom.colors.lightTextSecondary}>
              {categoryKey}
            </Typography>
            <ul>
              {
                groupedSymptom[categoryKey].map((data) => (
                  <li>
                    <Typography>{data.symptom.name}</Typography>
                  </li>
                ))
              }
            </ul>
          </React.Fragment>
        ))}
      {children}
    </Box>
  );
}

export default function SymptomsCard({
  closeDrawer,
  drawerItem,
  drawerMode = false,
  onShowMoreSymptoms = () => null,
  drawerTabIndex,
  reportedSymptoms = [],
  reportedSymptomsHistory = [],
}: SymptomsCardProps): JSX.Element {
  const [value, setValue] = React.useState(0);

  const groupSymptomsByCategory = (slicedSymptoms: PatientSymptom[]) => {
    const symptomsData: GroupedSymptoms = {};

    slicedSymptoms.forEach((data) => {
      const {
        symptom: { category },
      } = data;
      const requiredData: NestedSymptoms = {
        symptom: data.symptom,
        startDate: data.startDate,
      };

      if (Object.hasOwn(symptomsData, category)) {
        symptomsData[category] = [...symptomsData[category], requiredData];
      }
      else {
        symptomsData[category] = [requiredData];
      }
    });
    return symptomsData;
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const withinMonthSymptoms = reportedSymptoms.sort(
    (a, b) => a.symptom.category.localeCompare(b.symptom.category),
  );

  const priorSymptoms = reportedSymptomsHistory.sort(
    (a, b) => a.symptom.category.localeCompare(b.symptom.category),
  );

  const withinMonthSlice = sliceForShowMore(withinMonthSymptoms, !drawerMode);
  const priorSlice = sliceForShowMore(priorSymptoms, !drawerMode);

  const withinMonthGrpSymptoms = groupSymptomsByCategory(withinMonthSlice);
  const priorGrpSymptoms = groupSymptomsByCategory(priorSlice);

  React.useEffect(() => {
    if (drawerMode) { setValue(drawerTabIndex || 0); }
  }, [drawerTabIndex, drawerMode]);

  return (
    <Grid item xs={12} md={!drawerMode ? 4 : undefined} key='health-record-reported-condition'>
      <Card sx={{ height: '100%' }}>
        {!drawerMode && (
          <CardHeader
            icon={HealthRecordCategories.diagnosis.value.icon}
            title='Symptoms'
          />
        )}
        {drawerMode && <ShowMoreDrawerTitle closeDrawer={closeDrawer} drawerItem={drawerItem} />}
        <CardContent>
          { !reportedSymptoms.length ? <NoDataForNow />
            : (
              <>
                <Box mb={2}>
                  <Tabs value={value} onChange={handleChange}>
                    <Tab label={SymptomsTabLabels.WITHIN_30_DAYS} />
                    <Tab label={SymptomsTabLabels.PRIOR} />
                  </Tabs>
                </Box>
                <SymptomsCardTabPanels
                  groupedSymptom={withinMonthGrpSymptoms}
                  value={value}
                  index={0}
                >
                  <ShowMoreButton
                    isDrawer={drawerMode}
                    onShowMore={() => onShowMoreSymptoms(value)}
                    items={withinMonthSymptoms}
                  />
                </SymptomsCardTabPanels>
                <SymptomsCardTabPanels
                  groupedSymptom={priorGrpSymptoms}
                  value={value}
                  index={1}
                >
                  <ShowMoreButton
                    isDrawer={drawerMode}
                    onShowMore={() => onShowMoreSymptoms(value)}
                    items={priorSymptoms}
                  />
                </SymptomsCardTabPanels>
              </>
            )}
        </CardContent>
      </Card>
    </Grid>
  );
}
