import * as React from 'react';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import SectionHeader from 'components/Common/SectionHeader';
import usePatient from 'pages/Dashboard/AddPatientContext';
import { PatientFormData } from 'pages/Dashboard/types';
import { useQuery } from 'react-query';
import { providers } from 'utils/apiUrls';
import {
  fetchPracticeProvidersList,
  fetchProvidersAssociationsList,
} from 'pages/Dashboard/services/provider.services';
import {
  PracticeProvider,
  PracticeProvidersListResponse,
  ProviderDesgination,
} from 'pages/Dashboard/types/providers.types';
import { Box, CircularProgress } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { useHttp } from 'hooks/use-fetch';

interface PracticeInfoFormProps {
  setFormData: (payload: PatientFormData) => void;
}

export default function PracticeInfoForm({ setFormData }: PracticeInfoFormProps) {
  const { http, providerId } = useHttp();
  const { state, practicesList } = usePatient();
  const { formData } = state;
  const [practiceProviderOptions, setPracticeProviderOptions] = React.useState<PracticeProvider[]>(
    [],
  );

  React.useEffect(() => {
    if (!formData.practiceId && practicesList?.practices?.length === 1) {
      setFormData({ ...formData, practiceId: practicesList.practices[0].id });
    }
  }, [formData, practicesList?.practices, setFormData]);

  const { isLoading: associationProvidersListLoading, data: associationProviderIds } = useQuery(
    [`${providers.providerAssociationList.queryUrl}`, providerId, 'practise-info-form'],
    {
      queryFn: fetchProvidersAssociationsList(http.get),
      select: (data) => data.providers.map((provider) => provider.id),
    },
  );

  const { isFetching: providersLoading } = useQuery(
    [`${providers.practiceProvidersList.queryUrl}`, formData.practiceId],
    {
      queryFn: fetchPracticeProvidersList(http.get, formData.practiceId || ''),
      enabled: !associationProvidersListLoading && !!formData.practiceId,
      onSuccess: (practiceProviders: PracticeProvidersListResponse) => {
        const newPracticeProviderOptions: PracticeProvider[] = [];

        practiceProviders.providers.forEach((provider) => {
          // eslint-disable-next-line max-len
          const conditionToShowProvider = provider.designation !== ProviderDesgination.PROVIDER_DESIGNATION_OFFICE_STAFF
            && (provider.id === providerId || associationProviderIds?.includes(provider.id));
          if (conditionToShowProvider) {
            newPracticeProviderOptions.push(provider);
          }
        });

        setPracticeProviderOptions(newPracticeProviderOptions);

        if (newPracticeProviderOptions.length === 1) {
          setFormData({ ...formData, providerId: newPracticeProviderOptions[0].id });
        }
      },
    },
  );

  return (
    <Grid container direction='column' gap={2}>
      <Grid item>
        <SectionHeader title='Practice information' icon={<LocationCityIcon fontSize='large' />} />
      </Grid>
      <Grid item>
        <Grid container direction='column' gap={2}>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label='Select Practice'
                  // eslint-disable-next-line max-len
                  onChange={(e) => setFormData({ ...formData, practiceId: e.target.value as string })}
                  required
                  select
                  value={formData.practiceId || ''}
                  variant='outlined'
                >
                  {practicesList?.practices.map((practice) => (
                    <MenuItem key={practice.id} value={practice.id}>
                      {practice.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container gap={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label='Select Provider'
                  // eslint-disable-next-line max-len
                  onChange={(e) => setFormData({ ...formData, providerId: e.target.value as string })}
                  required
                  select
                  value={formData.providerId || ''}
                  variant='outlined'
                  disabled={
                    !formData.practiceId || providersLoading || associationProvidersListLoading
                  }
                >
                  {practiceProviderOptions.map((provider) => (
                    <MenuItem key={provider.id} value={provider.id}>
                      {provider.firstName}
                      {' '}
                      {provider.lastName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {providersLoading && (
                <Grid item alignSelf='center'>
                  <Box>
                    <CircularProgress size={25} sx={{ color: blueGrey[200] }} />
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
