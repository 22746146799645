import * as React from 'react';
import Grid from '@mui/material/Grid';
import { SvgIconProps } from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { Variant } from '@mui/material/styles/createTypography';

interface IProps {
  title: string;
  titleVariant?: Variant;
  subtitle?: string | undefined;
  Icon?: (props: SvgIconProps) => JSX.Element;
}

export default function PageHeader({ title, subtitle, titleVariant = 'h1', Icon }: IProps) {
  return (
    <Grid container sx={{ mb: 3, pb: 2 }}>
      <Grid item xs={12} xl={8}>
        <Typography variant={titleVariant}>
          {Icon ? <Icon fontSize='large' sx={{ marginRight: '10px' }} /> : null}
          {title}
        </Typography>
      </Grid>
      {!!subtitle && (
        <Grid item xs={12}>
          <Typography color='gray' variant='h4'>
            {subtitle}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
