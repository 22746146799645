import { SmokingRooms } from '@mui/icons-material';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import Groups from '@mui/icons-material/Groups';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import Medication from '@mui/icons-material/Medication';
import Psychology from '@mui/icons-material/Psychology';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';


type Category = {
  displayName: string;
  icon: OverridableComponent<SvgIconTypeMap> & { muiName: string; };
};

export default class HealthRecordCategories {
  static readonly medication = new HealthRecordCategories('medication', {
    displayName: 'Medication',
    icon: Medication,
  });

  static readonly diagnosis = new HealthRecordCategories('diagnosis', {
    displayName: 'Diagnosis',
    icon: Psychology,
  });

  static readonly careTeam = new HealthRecordCategories('careTeam', {
    displayName: 'Care Team',
    icon: Groups,
  });

  static readonly familyHistory = new HealthRecordCategories('familyHistory', {
    displayName: 'Family History',
    icon: FamilyRestroomIcon,
  });

  static readonly psychosocial = new HealthRecordCategories('psychosocial', {
    displayName: 'Psychosocial',
    icon: SummarizeIcon,
  });

  static readonly providers = new HealthRecordCategories('providers', {
    displayName: 'Providers',
    icon: LocalHospitalIcon,
  });

  static readonly reportedCondition = new HealthRecordCategories('reportedCondition', {
    displayName: 'Reported Condition',
    icon: LocalHospitalIcon,
  });

  static readonly substance = new HealthRecordCategories('substance', {
    displayName: 'Substance',
    icon: SmokingRooms,
  });

  private constructor(private readonly key: string, public readonly value: Category) {
  }

  toString() {
    return this.key;
  }
}
