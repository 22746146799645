import { Box } from '@mui/material';
import * as React from 'react';


interface UpperTextProps{
    children: string,
}

export default function UpperText({ children }: UpperTextProps) {
  return (
    <Box sx={{ textTransform: 'uppercase' }}>
      {children}
    </Box>
  );
}
