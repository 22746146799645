import {
  MoodTrackingAverageResponse,
  MoodTrackingListResponse,
} from 'pages/Dashboard/types/moods.types';
import { ListWaypointDetails, MostLoggedMetricsResponse, SomaticScoreResponse, StroopTimeSeriesAPIResponse, WaypointSourcesAPIResponse, WaypointTrackingAPIResponse } from 'pages/Dashboard/types/waypoints.types';
import { getDuration, getTimeFrameInterval } from 'pages/Dashboard/utils/trackingUtils';
import { QueryFunctionContext } from 'react-query';
import { gamesUrls, trackingUrls } from 'utils/apiUrls';
import { HttpType } from 'utils/http';

// eslint-disable-next-line max-len
export const fetchMoodAverage = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<MoodTrackingAverageResponse> {
  const [, providerId, patientId, selectTimeFrame, identifier] = queryKey;
  const intervals = getTimeFrameInterval(selectTimeFrame as string);
  if (identifier === 'current') {
    const { currInterval } = intervals;
    return method(
      trackingUrls.moodAverage.apiUrls(
        providerId as string,
        patientId as string,
        currInterval.start,
        currInterval.end,
      ).list,
    );
  }

  const { previousInterval } = intervals;
  return method(
    trackingUrls.moodAverage.apiUrls(
      providerId as string,
      patientId as string,
      previousInterval.start,
      previousInterval.end,
    ).list,
  );
};

export const fetchMoodChart = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<MoodTrackingListResponse> {
  const [, providerId, patientId, selectTimeFrame, mood] = queryKey;
  const intervals = getTimeFrameInterval(selectTimeFrame as string);
  const { currInterval } = intervals;

  return method(
    trackingUrls.moodList.apiUrls(
      providerId as string,
      patientId as string,
      currInterval.start,
      currInterval.end,
      mood as string,
    ).list,
  );
};

export const fetchWaypointsTracking = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<WaypointTrackingAPIResponse> {
  const [, providerId, patientId, selectTimeFrame, identifier] = queryKey;
  const intervals = getTimeFrameInterval(selectTimeFrame as string);
  if (identifier === 'current') {
    const { currInterval } = intervals;
    return method(
      trackingUrls.waypointAverage.apiUrls(
        providerId as string,
        patientId as string,
        currInterval.start,
        currInterval.end,
      ).list,
    );
  }

  const { previousInterval } = intervals;
  return method(
    trackingUrls.waypointAverage.apiUrls(
      providerId as string,
      patientId as string,
      previousInterval.start,
      previousInterval.end,
    ).list,
  );
};

export const fetchWaypointTracking = (method: HttpType['get'], isSleepWaypoint?: boolean, isPrevious = false) => function async({ queryKey }: QueryFunctionContext): Promise<WaypointTrackingAPIResponse | ListWaypointDetails> {
  const [, providerId, patientId, waypointId, selectTimeFrame, sourceId] = queryKey;
  const intervals = getTimeFrameInterval(selectTimeFrame as string);
  const duration = getDuration(selectTimeFrame as string);
  const { currInterval, previousInterval } = intervals;
  const interval = isPrevious ? previousInterval : currInterval;
  const url = isSleepWaypoint
    ? trackingUrls.waypointDetails.apiUrls
    : trackingUrls.waypoint.apiUrls;
  return method(
    url(
      providerId as string,
      patientId as string,
      waypointId as string,
      interval?.start,
      interval?.end,
      duration,
      sourceId as string,
    ).list,
  );
};

export const fetchGamesTracking = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<StroopTimeSeriesAPIResponse> {
  const [, providerId, patientId, selectTimeFrame] = queryKey;
  const intervals = getTimeFrameInterval(selectTimeFrame as string);
  const duration = getDuration(selectTimeFrame as string);
  const { currInterval } = intervals;
  return method(
    gamesUrls.stroopTimeSeries.apiUrls(
      providerId as string,
      patientId as string,
      currInterval.start,
      currInterval.end,
      duration,
    ).list,
  );
};

export const fetchWaypointSources = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<WaypointSourcesAPIResponse> {
  const [, providerId, patientId] = queryKey;
  return method(
    trackingUrls.waypointSources.apiUrls(
      providerId as string,
      patientId as string,
    ).list,
  );
};

export const fetchMostLoggedMetrics = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<MostLoggedMetricsResponse> {
  const [, providerId, patientId, selectTimeFrame, entity] = queryKey;
  const intervals = getTimeFrameInterval(selectTimeFrame as string);
  const { currInterval } = intervals;
  return method(
    trackingUrls.mostLoggedMetrics.apiUrls(
        providerId as string,
        patientId as string,
        currInterval.start,
        currInterval.end,
        entity as string,
    ).list,
  );
};

export const fetchSomaticScore = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<SomaticScoreResponse> {
  const [, providerId, patientId, selectTimeFrame] = queryKey;
  const intervals = getTimeFrameInterval(selectTimeFrame as string);
  const duration = getDuration(selectTimeFrame as string);
  const { currInterval } = intervals;
  const interval = currInterval;
  return method(
    trackingUrls.somaticScore.apiUrls(
      providerId as string,
      patientId as string,
      interval?.start,
      interval?.end,
      duration,
    ).list,
  );
};
