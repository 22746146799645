import * as React from 'react';
import {
  Box,
  Checkbox,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import Theme from 'theme';
import DrawerHeader from 'pages/Rewards/components/DrawerHeader';
import { grey } from '@mui/material/colors';
import {
  ListWaypointDetails,
  SleepCategory,
  SleepCategoryMap,
} from 'pages/Dashboard/types/waypoints.types';
import { TimeFrameOptions, getAverageText, getBarProps, getTickFormat } from 'pages/Dashboard/utils/trackingUtils';
import { Tabs } from 'components/Common/TabComponent';
import StackedBarChart from 'pages/Dashboard/components/StackedBarChart';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { add, format } from 'date-fns';
import { getStartOfDayLocal, getHourDifference, getHoursFromTimestamp } from 'utils/dateUtils';
import { convertDecimalToHoursMinutes } from 'pages/Dashboard/components/AppleHealthSleepChart';
import { useParams } from 'react-router-dom';
import ContentLoader from 'components/Common/ContentLoader';
import ChartAxisLabel from 'pages/Dashboard/components/ChartAxisLabel';
import { trackMixpanelEvent } from 'utils/utilMethods';
import { MixpanelEventName } from 'utils/constants';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';

const CHART_HEIGHT = 325;

function getTimeDifferenceInDecimalHours(startDate: Date, minHours: number) {
  const startTime = new Date(startDate).getTime();
  const minTime = new Date(startDate).setHours(
    minHours,
    0,
    0,
    0,
  );
  const sixPM = minTime > startTime ? minTime - 24 * 60 * 60 * 1000 : minTime;
  const timeDifference = startTime - sixPM;
  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutes = Math.floor(
    (timeDifference % (1000 * 60 * 60)) / (1000 * 60),
  );
  const timeInDecimals = hours + minutes / 60;
  return timeInDecimals;
}

type CustomTooltipProps = {
  timeFrame: string;
  selectedCategories: Record<SleepCategory, boolean>;
  label?: number;
  payload?: Payload<string | number | (string | number)[], string | number>[] | undefined;
  active?: boolean;
};

export function CustomTooltip({
  active,
  payload,
  label,
  timeFrame,
  selectedCategories,
}: CustomTooltipProps) {
  if (active && payload?.[0]?.payload?.metadata?.length) {
    const data = payload[0].payload.metadata;
    const keyValue = data.reduce(
      (acc: Record<string, string>, b: { key: SleepCategory; value: string }) => {
        const key = b.key as SleepCategory;
        return {
          ...acc,
          [key]: b.value,
        };
      },
      {} as Record<string, string>,
    );
    const isInDays = [TimeFrameOptions.LAST_7_DAYS, TimeFrameOptions.LAST_30_DAYS].includes(
      timeFrame as TimeFrameOptions,
    );
    const { day, date, year } = getTickFormat(label as number, isInDays, 'MMM d');
    const finalLabel = isInDays ? `${day}, ${date}` : `${date}, ${year}`;

    return (
      <div
        style={{
          background: 'white',
          border: '1px solid #f5f5f5',
          padding: '10px',
          borderRadius: '5px',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography>{finalLabel}</Typography>
        {Object.keys(SleepCategoryMap).map((key) => {
          const value = convertDecimalToHoursMinutes(keyValue[key], key, '700', true);
          const isValid = (key !== SleepCategory.INBED_TIME || data.length === 1) && value;
          return selectedCategories[key as SleepCategory] && isValid ? (
            <Typography
              color={SleepCategoryMap[key as SleepCategory].color}
              marginTop={2}
              key={key}
            >
              <Typography>{SleepCategoryMap[key as SleepCategory]?.label}</Typography>
              <Typography display='flex'>
                <Typography variant='h6' lineHeight='100%'>
                  {value}
                </Typography>
              </Typography>
            </Typography>
          ) : null;
        })}
      </div>
    );
  }

  return null;
}

type Metadata = {
  key: string;
  type: string;
  value: string;
  unit: string;
  description: string;
  timeSeries: {
    startDate: string;
    endDate: string;
    value: number;
  }[];
  avgStartTimestamp: number;
  occurenceCount: number;
};

interface CandlestickProps {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  fill?: string;
  metadata?: Metadata[];
  minHours: number;
  selectedTimeFrame: string;
  selectedCategories: Record<SleepCategory, boolean>;
}

function Candlestick(props: CandlestickProps) {
  const { x = 0,
    y = 0,
    width = 0,
    height = 0,
    fill,
    metadata,
    minHours,
    selectedTimeFrame,
    selectedCategories,
  } = props;
  const isInDays = [TimeFrameOptions.LAST_30_DAYS, TimeFrameOptions.LAST_7_DAYS].includes(
    selectedTimeFrame as TimeFrameOptions,
  );

  const reducedMetadata = metadata?.reduce((acc, b) => {
    const { color } = SleepCategoryMap[b.key as SleepCategory];
    const timeseries = b.timeSeries.map((t) => ({
      ...t,
      color,
      value: t.value,
      key: b.key,
    }));

    return {
      ...acc,
      [SleepCategoryMap[b.key as SleepCategory].index]: [
        ...(acc[SleepCategoryMap[b.key as SleepCategory].index] ?? []),
        ...timeseries,
      ],
    };
  }, {} as Record<number, { startDate: string; color: string; value: number; key: string }[]>)
    ?? {};
  const flattedMetaData = Object.values(reducedMetadata).flat() as {
    startDate: string;
    color: string;
    value: number;
    key: string;
  }[];
  const dates = isInDays
    ? flattedMetaData.map((m) => [m.startDate, m.color, m.value, m.key])
    : metadata?.reduce((acc, m) => {
      const { color } = SleepCategoryMap[m.key as SleepCategory];
      if (
        m.key === SleepCategory.ASLEEP_TIME
            || (m.key === SleepCategory.INBED_TIME && metadata.length === 1)
      ) {
        acc.push([m.avgStartTimestamp * 1000, color, m.value, m.key]);
      }
      return acc;
    }, [] as [number, string, string, string][]);

  if (!dates?.length) {
    return <g stroke={fill} fill={fill} strokeWidth='2' />;
  }

  return (
    <>
      {dates.map((item) => {
        let color = '#DFDFDF'; // TODO: move color to theme
        const key = item[3] as SleepCategory;
        const isAsleepSelected = selectedCategories[SleepCategory.ASLEEP_TIME];
        const keySelected = selectedCategories[item[3] as SleepCategory];
        const isAsleepCategoriesSelected = keySelected
        && SleepCategoryMap[key as SleepCategory].isAsleepCategory;
        const { isAsleepCategory } = SleepCategoryMap[key as SleepCategory];
        if (key === SleepCategory.INBED_TIME) {
          color = Object.keys(reducedMetadata).length > 1 ? '#DFDFDF' : SleepCategoryMap[key].color;
        }
        else if (
          isAsleepCategory
           && isAsleepSelected
           && !isAsleepCategoriesSelected
        ) {
          color = 'transparent';
        }
        else if (keySelected) {
          color = SleepCategoryMap[key as SleepCategory].color;
        }


        const value = parseFloat(item[2] as string);
        const itemOne = getTimeDifferenceInDecimalHours(new Date(item[0]), minHours);
        const itemY = y - (value + itemOne) * (height);
        const itemHeight = value * (height);

        return (
          <g fill={color} key={`L ${x + width},${itemY + itemHeight}`}>
            <path
              d={`
            M ${x},${itemY}
            L ${x},${itemY + itemHeight}
            L ${x + width},${itemY + itemHeight}
            L ${x + width},${itemY}
            L ${x},${itemY}
            `}
            />
          </g>
        );
      })}
    </>
  );
}

const getColor = (key: string, averageMap: Record<string, React.ReactElement>) => {
  if (key === SleepCategory.INBED_TIME) {
    return averageMap[SleepCategory.ASLEEP_TIME] ? '' : SleepCategoryMap[key as SleepCategory]?.color;
  }

  return SleepCategoryMap[key as SleepCategory]?.color;
};

const getLabel = (key: string, showAverageTimes: boolean) => {
  if (key === SleepCategory.INBED_TIME && showAverageTimes) {
    return 'End time in bed';
  }

  return (showAverageTimes
    ? SleepCategoryMap[key as SleepCategory]?.averageTimeLabel
    : SleepCategoryMap[key as SleepCategory]?.periodAverageLabel);
};

const getValueKey = (
  key: SleepCategory,
  showAverageTimes: boolean,
) => {
  if (showAverageTimes && key === SleepCategory.ASLEEP_TIME) {
    return 'asleep_time_sleep_avg_start_time';
  }

  if (showAverageTimes && key === SleepCategory.AWAKE_TIME) {
    return 'asleep_time_sleep_avg_end_time';
  }

  if (showAverageTimes && key === SleepCategory.INBED_TIME) {
    return 'inbed_time_sleep_avg_end_time';
  }

  return key;
};

type AveragesProps = {
  averageMap: Record<string, React.ReactElement>;
  selectedTimeFrame: string;
  showAverageTimes?: boolean;
};

function Averages({ averageMap, showAverageTimes, selectedTimeFrame }: AveragesProps) {
  const averages = Object.keys(SleepCategoryMap).reduce((acc, key: string) => {
    const label = getLabel(key, showAverageTimes as boolean);
    const value = averageMap?.[
      getValueKey(key as SleepCategory, showAverageTimes as boolean)
    ];
    const color = getColor(key, averageMap);
    if (label && value) {
      return [
        ...acc,
        {
          key,
          label,
          value,
          color,
        },
      ];
    }

    return acc;
  }, [] as { key: string, label: string, value: React.ReactElement, color: string }[]);

  if (averages.length < 1) {
    return null;
  }

  return (
    <Box>
      <Typography variant='body1' my={1} fontWeight='bold'>
        {getAverageText(selectedTimeFrame)}
      </Typography>
      <TableContainer>
        <Table>
          <TableBody>
            {showAverageTimes && averageMap?.inbed_time_sleep_avg_start_time && (
            <TableRowStyled>
              <TableCellStyled padding='none'>
                Start time in bed
              </TableCellStyled>
              <TableCellStyled padding='none'>{averageMap?.inbed_time_sleep_avg_start_time}</TableCellStyled>
            </TableRowStyled>
            )}
            {averages.map(({ key, value, label, color }) => (
              <TableRowStyled key={key}>
                <TableCellStyled padding='none'>
                  {!showAverageTimes && (
                  <Box
                    width={16}
                    height={16}
                    borderRadius={10}
                    border={`1px solid ${color || Theme.custom.colors.lightTextSecondary}`}
                    bgcolor={color}
                    mr={1}
                  />
                  )}
                  {label}
                </TableCellStyled>
                <TableCellStyled padding='none'>{value}</TableCellStyled>
              </TableRowStyled>
            ))}
            {showAverageTimes && averageMap?.awake_time_sleep_occurence && (
            <TableRowStyled>
              <TableCellStyled padding='none'>
                Awakenings
              </TableCellStyled>
              <TableCellStyled padding='none'>{averageMap?.awake_time_sleep_occurence}</TableCellStyled>
            </TableRowStyled>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

const TableRowStyled = styled(TableRow)(
  ({ theme }) => `
  &:nth-of-type(even) {
    background-color: ${theme.palette.background.default};
    justify-content: space-between;
    display: flex;
  }
  &:nth-of-type(odd) {
    justify-content: space-between;
    display: flex;
    background-color: ${
  theme.palette.mode === 'dark' ? theme.palette.background.default : grey[100]
};
  }
`,
);

const TableCellStyled = styled(TableCell)({
  padding: 8,
  paddingLeft: 8,
  paddingRight: 8,
  borderBottom: 'none',
  display: 'flex',
  alignItems: 'center',
});

const defaultSelectedCategories = Object.keys(SleepCategoryMap).reduce(
  (acc, key) => ({
    ...acc,
    [key as SleepCategory]: !SleepCategoryMap[key as SleepCategory].isAsleepCategory,
  }),
  {} as Record<SleepCategory, boolean>,
);

interface DrawerContentProps {
  isOpen: boolean;
  fetchPatientWaypointTrackingResult: {
    data: ListWaypointDetails;
    isFetching: boolean;
  };
  timeFrame: string;
  source: string;
  sourcesOptions: { value: string; label: string }[];
  yAxisProps: {
    startTime: number;
    endTime: number;
    ticks: number[];
    isInDays: boolean;
  };
  setSource: (source: string) => void;
  onClose: () => void;
  onChangeTimeFrame: (v: string) => void;
}

export default function DrawerContent({
  isOpen,
  fetchPatientWaypointTrackingResult,
  timeFrame: selectedTimeFrame,
  source,
  sourcesOptions,
  yAxisProps,
  onClose,
  setSource,
  onChangeTimeFrame: setSelectedTimeFrame,
}: DrawerContentProps) {
  const { id: patientId } = useParams();

  const { startTime, endTime, ticks, isInDays } = yAxisProps;

  const [selectedCategories, setSelectedCategories] = React.useState(defaultSelectedCategories);
  const [durationValues, setDurationValues] = React.useState(
    [] as Record<string, string | number>[],
  );
  const [availableCategories, setAvailableCategories] = React.useState(
    {} as Record<string, boolean>,
  );

  const tabs = Object.keys(TimeFrameOptions).map((timeFrameOption) => ({
    header: TimeFrameOptions[timeFrameOption as keyof typeof TimeFrameOptions],
  }));

  const averages = React.useMemo(() => {
    const data = (fetchPatientWaypointTrackingResult?.data as ListWaypointDetails)
      ?.categoryAvgData || [];
    return data;
  }, [fetchPatientWaypointTrackingResult?.data]);


  const averageMap = averages.reduce((a, avg) => {
    if (avg?.id) {
      return {
        ...a,
        [avg.id]: convertDecimalToHoursMinutes(
          avg.absoluteValue,
          avg.id,
          '400',
          true,
        ),
      };
    }
    return a;
  }, {} as Record<SleepCategory, React.ReactElement>);

  const sleepChartData = React.useMemo(
    () => fetchPatientWaypointTrackingResult?.data,
    [fetchPatientWaypointTrackingResult],
  ) as ListWaypointDetails;

  const isLoading = React.useMemo(
    () => fetchPatientWaypointTrackingResult?.isFetching,
    [fetchPatientWaypointTrackingResult],
  );

  const chartData = React.useMemo(() => sleepChartData?.categoryTimeSeriesData, [sleepChartData]);

  const minHours = getHoursFromTimestamp((sleepChartData?.minChartRange ?? 0) * 1000);

  const hoursDifference = getHourDifference(
    (sleepChartData?.minChartRange ?? 0) * 1000,
    (sleepChartData?.maxChartRange ?? 0) * 1000,
  );

  const handleChange = (event: SelectChangeEvent) => {
    setSource(event.target.value as string);
  };

  const onCategoryChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: SleepCategory,
  ) => {
    const { checked } = event.target;
    if (checked) {
      trackMixpanelEvent(MixpanelEventName.AH_SLEEP_CHART_CATEGORY_SELECTED, {
        'patient id': patientId ?? '',
        category: key,
      }, true);
    }
    setSelectedCategories((prev) => ({
      ...prev,
      [key]: checked,
      ...(key === SleepCategory.ASLEEP_TIME && !checked
        ? {
          [SleepCategory.DEEP_TIME]: false,
          [SleepCategory.CORE_TIME]: false,
          [SleepCategory.REM_TIME]: false,
        }
        : {}),
    }));
  };

  const data = React.useMemo(() => {
    const updatedDurationValues = [] as Record<string, string | number>[];
    const updatedAvailableCategories = {} as Record<string, boolean>;
    const finalData = chartData?.map((w, index) => {
      const date = getStartOfDayLocal(Number(w?.timestamp) * 1000, isInDays);
      updatedDurationValues[index] = {
        date,
      };
      w?.metadata?.forEach((m) => {
        if (m.key !== SleepCategory.INBED_TIME || w?.metadata?.length === 1) {
          updatedAvailableCategories[m.key] = true;
          updatedDurationValues[index][m.key] = parseFloat(m.value);
        }
      });

      return { date, metadata: w?.metadata || [], range: [0, 1] };
    });
    setDurationValues(updatedDurationValues);
    setAvailableCategories(updatedAvailableCategories);
    return finalData ?? [];
  }, [chartData, isInDays]);

  const timeFrameValues = React.useMemo(() => Object.values(TimeFrameOptions), []);
  const timeFrame = React.useMemo(
    () => timeFrameValues.findIndex((value) => value === selectedTimeFrame),
    [selectedTimeFrame, timeFrameValues],
  );

  const filteredCategoriesOptions = React.useMemo(() => Object.keys(SleepCategoryMap).filter(
    (key) => key !== SleepCategory.INBED_TIME && availableCategories[key],
  ), [availableCategories]);

  const subHeadingText = React.useMemo(() => {
    if (filteredCategoriesOptions.length === 0) {
      return { duration: '(time in bed)', timing: '(start/stop time in bed)' };
    }
    if (filteredCategoriesOptions.length === 1) {
      const isInBed = filteredCategoriesOptions[0] === SleepCategory.INBED_TIME;
      return {
        duration: isInBed ? '(time in bed)' : '(time asleep)',
        timing: isInBed ? '(start/stop time in bed)' : '(start/stop time asleep)',
      };
    }

    return {
      duration: '(length of time per stage while in bed)',
      timing: '(start/stop time per stage while in bed)',
    };
  }, [filteredCategoriesOptions]);

  return (
    <Drawer
      anchor='right'
      open={isOpen}
      onClose={onClose}
      sx={{ zIndex: Theme.zIndex.drawer + 2 }}
      PaperProps={{ sx: { maxWidth: 1312, width: '100%' } }}
      data-testid='rewards-drawer'
    >
      <Box role='grid' display='flex' flexDirection='column' flex={1}>
        <DrawerHeader title='Sleep' onClose={onClose} />
        <Divider />
        <Box padding={2} display='flex' flexDirection='column' flex={1}>
          <Grid container>
            <Grid
              item
              xs={12}
              display='flex'
              alignItems='center'
              flex={1}
              bgcolor='#E9E9E9'
              justifyContent='flex-end'
            >
              <Typography color='#4C4C4C' marginRight={2}>
                Data from past:
              </Typography>
              <Tabs
                value={timeFrame}
                setValue={(value) => {
                  const timeFrameValue = timeFrameValues[value as number];
                  const daysSelected = [
                    TimeFrameOptions.LAST_30_DAYS,
                    TimeFrameOptions.LAST_7_DAYS,
                  ].includes(timeFrameValue as TimeFrameOptions);
                  if (daysSelected) {
                    setSelectedCategories(defaultSelectedCategories);
                  }
                  else {
                    setSelectedCategories({
                      ...defaultSelectedCategories,
                      [SleepCategory.AWAKE_TIME]: false,
                    });
                  }
                  setSelectedTimeFrame(timeFrameValue);
                }}
                tabContext={tabs}
                variant='standard'
                activeStyles={{
                  backgroundColor: '#FFF',
                  maxHeight: 24,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              {sourcesOptions.length > 1 && (
              <FormControl sx={{ marginY: 3 }}>
                <InputLabel>Data source</InputLabel>
                <Select value={source} label='Data source' onChange={handleChange}>
                  {sourcesOptions.map((s) => (
                    <MenuItem key={s.value} value={s.value}>
                      {s.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              )}
              {sourcesOptions.length === 1 && (
              <Typography color={Theme.custom.colors.lightTextSecondary} sx={{ marginY: 3 }}>
                Source:
                {' '}
                {sourcesOptions[0].label}
              </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid container columnSpacing={6} mb={1.5}>
                <Grid item xs={6}>
                  <ContentLoader isFetching={isLoading} minHeight={CHART_HEIGHT} isError={false}>
                    <>
                      <Typography variant='h6'>Duration</Typography>
                      <Typography
                        variant='body1'
                        color={Theme.custom.colors.lightTextSecondary}
                        mb={3}
                      >
                        {subHeadingText.duration}
                      </Typography>
                      <StackedBarChart
                        data={durationValues}
                        timeFrame={selectedTimeFrame}
                        selectedCategories={selectedCategories}
                        yAxisProps={yAxisProps}
                      />
                      <Averages averageMap={averageMap} selectedTimeFrame={selectedTimeFrame} />
                    </>
                  </ContentLoader>
                </Grid>
                <Grid item xs={6}>
                  <ContentLoader isFetching={isLoading} minHeight={CHART_HEIGHT} isError={false}>
                    <>
                      <Typography variant='h6'>Timing</Typography>
                      <Typography
                        variant='body1'
                        color={Theme.custom.colors.lightTextSecondary}
                        mb={3}
                      >
                        {subHeadingText.timing}
                      </Typography>
                      <Box sx={{ position: 'relative' }}>
                        {!durationValues.length && (
                          <Box
                            sx={{
                              position: 'absolute',
                              top: 'calc((100% - 30px)/2)',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              textAlign: 'center',
                              padding: 2,
                              background: 'white',
                              zIndex: 1,
                            }}
                          >
                            no data exists
                          </Box>
                        )}
                        <ResponsiveContainer width='100%' height={CHART_HEIGHT}>
                          <BarChart
                            data={data}
                            margin={{ left: 0, bottom: 0, top: 0, right: 5 }}
                          >
                            <CartesianGrid strokeDasharray='3 3' />
                            <XAxis
                              dataKey='date'
                              type='number'
                              domain={[startTime, endTime]}
                              tick={<ChartAxisLabel isInDays={isInDays} />}
                              tickFormatter={() => ''}
                              ticks={ticks}
                              height={30}
                              tickLine={false}
                              tickSize={2}
                              allowDataOverflow
                            />
                            <YAxis
                              type='number'
                              tick={{ dy: 2 }}
                              domain={[0, hoursDifference + 2]}
                              reversed
                              tickCount={hoursDifference + 2}
                              tickFormatter={(index) => {
                                const date = add((sleepChartData?.minChartRange ?? 0) * 1000, {
                                  hours: index - 1,
                                });
                                return format(date, 'h aaa');
                              }}
                              tickLine={false}
                              fontSize={12}
                              tickSize={2}
                              width={50}
                              allowDataOverflow
                            />
                            <Tooltip
                              content={(
                                <CustomTooltip
                                  timeFrame={selectedTimeFrame}
                                  selectedCategories={selectedCategories}
                                />
                              )}
                              cursor={false}
                            />
                            <Bar
                              dataKey='range'
                              fill='#8884d8'
                              isAnimationActive={false}
                              {...getBarProps(selectedTimeFrame)}
                              shape={(
                                <Candlestick
                                  minHours={minHours}
                                  selectedTimeFrame={selectedTimeFrame}
                                  selectedCategories={selectedCategories}
                                />
                              )}
                            />
                          </BarChart>
                        </ResponsiveContainer>
                      </Box>
                      <Averages
                        averageMap={averageMap}
                        showAverageTimes
                        selectedTimeFrame={selectedTimeFrame}
                      />
                    </>
                  </ContentLoader>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {isInDays && filteredCategoriesOptions.length > 1 && !isLoading && (
            <FormGroup row sx={{ background: '#E9E9E9', width: '100%', justifyContent: 'center' }}>
              {filteredCategoriesOptions.map((key) => (
                <FormControlLabel
                  key={key}
                  control={(
                    <Checkbox
                      checked={selectedCategories[key as SleepCategory]}
                      onChange={(e) => onCategoryChangeHandler(e, key as SleepCategory)}
                      sx={{
                        '&.Mui-checked': {
                          color: SleepCategoryMap[key as SleepCategory]?.color,
                        },
                      }}
                    />
                  )}
                  label={SleepCategoryMap[key as SleepCategory]?.label}
                  disabled={
                    SleepCategoryMap[key as SleepCategory]?.isAsleepCategory
                    && !selectedCategories[SleepCategory.ASLEEP_TIME]
                  }
                />
              ))}
            </FormGroup>
          )}
        </Box>
      </Box>
    </Drawer>
  );
}
