import mixpanel from 'mixpanel-browser';
import { MixpanelEventName } from 'pages/Rewards/types/mixpanel.types';
import { Tabs } from 'pages/Rewards/types/tabs.types';

const trackMixpanelEvent = (eventName: MixpanelEventName, payload?: {
  [key: string]: string | {
    [key: string]: string
  }
}) => {
  mixpanel.track(eventName, { 'datetime clicked': new Date().toISOString(), ...payload });
};

export const tabItemsMap = {
  [Tabs.REWARDS]: {
    label: 'REWARDS',
    key: Tabs.REWARDS,
  },
  [Tabs.WAYS_TO_EARN]: {
    label: 'WAYS TO EARN',
    key: Tabs.WAYS_TO_EARN,
  },
  [Tabs.TRANSACTION_HISTORY]: {
    label: 'TRANSACTION HISTORY',
    key: Tabs.TRANSACTION_HISTORY,
  },
};

export const tabItems = Object.values(tabItemsMap);

export default trackMixpanelEvent;
