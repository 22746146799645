import { CacheKeysModel } from 'types/CacheKeysModel';

export const CacheKeys: CacheKeysModel = {
  enableDarkMode: 'enable-dark-mode',
  orgId: 'org-id',
  userId: 'user-id',
  providerId: 'super-admin-selected-provider',
};

export const LogoUrl = {
  sm: 'https://res.cloudinary.com/diyfx2bz3/image/upload/v1706894684/favicon_w47qqz.ico',
  lg: 'https://res.cloudinary.com/diyfx2bz3/image/upload/v1706894684/headlamp-health-logo-light-bg_tsykhe.png',
};

export const NotSpecified = 'Not specified';

export const WelcomeExplorerActionId = 'welcome-action';

export enum MixpanelEventName {
  SEE_ANSWERS_CLICKED = 'Rating scales see answers button clicked',
  PATIENT_NAME_CLICKED = 'Rating scales patient name link clicked',
  DOWNLOAD_PDF_CLICKED = 'Rating scales download PDF button clicked',
  FILTERS_APPLIED = 'Rating scales filters applied',
  EXPORT_DATA_CLICKED = 'Export data clicked',
  SCALES_SUGGESTED = 'Patient overview scales suggested',
  SCALE_CLICKED = 'Patient overview rating scale link clicked',
  ADDED_NOTE_ON_SCALE = 'Patient overview rating scale note added',
  REPORTS_ROUTE_VISITED = 'Reports route visited',
  AH_SLEEP_CHART_SOURCE_CHANGED = 'Apple Health sleep chart source changed',
  AH_SLEEP_CHART_VIEW_DETAILS_CLICKED = 'Apple Health sleep chart view details clicked',
  AH_SLEEP_CHART_CATEGORY_SELECTED = 'Apple Health sleep chart category selected',
  MOOD_AND_WAYPOINT_TIME_FRAME_CHANGED = 'Mood and waypoint time frame changed',

  VIEW_MORE_BUTTON_CLICK = 'View more button clicked',
  ADD_PATIENT_NEW_BUTTON_CLICK = 'Add Patient New button clicked',
  APPLIED_SORTING = 'Applied Sorting',
  QUERY_SEARCHED = 'Query Searched',
  STATUS_FILTER_SELECTED = 'Status filter selected',
  PROVIDER_FILTER_SELECTED = 'Provider filter selected',
  PROVIDER_METADATA_LOADED = 'Provider metadata loaded',
  NEXT_PAGE_CLICK = 'Next page click',
  PREV_PAGE_CLICK = 'Previous page click',
  PATIENT_QUICK_VIEW_OPENED = 'Patient quick view opened',
  PAGE_CHANGED = 'Page changed',
  ROWS_PER_PAGE_CHANGED = 'Rows per page changed'
}
