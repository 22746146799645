import * as React from 'react';
import { Box, Grid, Link, Typography } from '@mui/material';
import theme from 'theme';
import RewardPoints from 'pages/Rewards/components/RewardPoints';
import { TPartialExplorerAction } from 'pages/Rewards/types/explorer-actions.types';
import { TPartialRewards } from 'pages/Rewards/types/rewards.types';
import { TPartialTransactions } from 'pages/Rewards/types/transactions.types';
import RewardsContext, { TComponent } from 'pages/Rewards/context/RewardsContext';
import trackMixpanelEvent from 'pages/Rewards/utilMethods';
import { MixpanelEventName } from 'pages/Rewards/types/mixpanel.types';
import { FetchContext } from 'hooks/use-fetch';

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const month = date.toLocaleString('default', { month: '2-digit' });
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

type FlatListItem = TPartialRewards | TPartialExplorerAction;

type DetailedListItem = TPartialTransactions;

function FlatItem({ item }: { item: FlatListItem }) {
  const { setActiveComponent } = React.useContext(RewardsContext);
  const {
    superAdminId,
    providerId,
  } = React.useContext(FetchContext);
  const providerView = !superAdminId || superAdminId === providerId;

  const onLinkClick = () => {
    setActiveComponent({
      component: item.identifier as TComponent,
      props: { points: item.points },
    });
    if (item.identifier === TComponent.REFERRAL) {
      trackMixpanelEvent(MixpanelEventName.REFER_A_CLINICIAN_TO_HEADLAMP_LINK_CLICKED);
    }
  };

  return (
    <Grid container mb={1.5} flexWrap='nowrap'>
      <Grid item display='flex' minWidth='55px' justifyContent='flex-end' mr={1.5}>
        <RewardPoints rewardPoints={item.points} size='medium' type='added' />
      </Grid>
      <Grid item>
        {TComponent[item.identifier as TComponent] && providerView ? (
          <Link
            underline='hover'
            sx={{ cursor: 'pointer' }}
            onClick={onLinkClick}
          >
            {item.title}
          </Link>
        ) : (
          <Typography>{item.title}</Typography>
        )}
      </Grid>
    </Grid>
  );
}

function DetailedItem({ item, index }: { item: DetailedListItem; index: number }) {
  return (
    <Grid
      container
      mb={1.5}
      p={0.75}
      flexWrap='nowrap'
      justifyContent='space-between'
      alignItems='center'
      bgcolor={index % 2 === 0 ? theme.custom.backgroundColor : ''}
    >
      <Grid item>
        <Typography>{item.title}</Typography>
        <Typography color={theme.custom.colors.lightTextSecondary}>
          {formatDate(item.date)}
        </Typography>
      </Grid>
      <Grid item display='flex' ml={1.5}>
        <RewardPoints
          rewardPoints={item.type === 'added' ? `+ ${item.points}` : `- ${item.points}`}
          size='medium'
          type={item.type}
        />
      </Grid>
    </Grid>
  );
}

interface IProps {
  list: (FlatListItem | DetailedListItem)[] | undefined;
  type?: 'flat' | 'detailed';
}

function PointsList({ list = [], type = 'flat' }: IProps) {
  return (
    <Box my={3.75}>
      {list.map((item, index) => (type === 'flat' ? (
        <FlatItem key={item.id} item={item as FlatListItem} />
      ) : (
        <DetailedItem key={item.id} item={item as DetailedListItem} index={index} />
      )))}
    </Box>
  );
}

export default PointsList;
