import { useMutation, useQuery } from 'react-query';
import { fetchProviderConsent, giveProviderConsent } from 'pages/Dashboard/services/providerconsent.services';
import { useHttp } from 'hooks/use-fetch';
import { ProviderConsent } from 'pages/Dashboard/types';
import { providerConsentUrls } from 'utils/apiUrls';
import { useState } from 'react';

export default function useConsent() {
  const { http, providerId } = useHttp();
  const [hasConsent, setHasConsent] = useState(false);

  const { isFetching } = useQuery([providerConsentUrls.consent.queryUrl, providerId], {
    queryFn: fetchProviderConsent(http.get),
    enabled: !!providerId,
    onSettled: (data, error) => {
      if (!error) {
        setHasConsent(!!data?.consent);
      }
      else {
        setHasConsent(true);
      }
    },
    retry: 2,
  });

  const { mutate: giveConsent, isLoading } = useMutation({
    mutationFn: (payload: ProviderConsent) => giveProviderConsent(
      http.post,
      providerId,
      { ...payload },
    ),
    onSettled: () => {
      setHasConsent(true);
    },
    retry: 2,
  });

  return {
    isFetching,
    isLoading,
    hasConsent,
    giveConsent,
  };
}
