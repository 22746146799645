import { Waypoints } from 'pages/Dashboard/types';
import { PatientWaypoint } from 'pages/Dashboard/types/waypointSuggestion.types';

// eslint-disable-next-line import/prefer-default-export
export const capitalize = (str: string) => (str ? str.charAt(0).toUpperCase() + str.slice(1) : '');

export function formatEmail(email?: string): string {
  if (!email || email.includes('dummyheadlamp.com')) {
    return '';
  }
  return email;
}

export function filterParentWaypoints(waypoints?: PatientWaypoint[]): Waypoints[] {
  if (!waypoints) {
    return [];
  }
  return waypoints
    .filter((waypoint) => !waypoint?.waypoint?.parentWaypoint && waypoint?.waypoint)
    .map((waypoint) => waypoint.waypoint);
}
