import * as React from 'react';
import { Skeleton, TableCell } from '@mui/material';
import { useHttp } from 'hooks/use-fetch';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { fetchWhoIamCategoryResults } from 'pages/Dashboard/services/whoiam.services';
import { whoIam } from 'utils/apiUrls';
import DiscInfoText from 'pages/Dashboard/components/DiscInfoText';


interface PatientDISCInfoProps {
  id: string;
}

export default function PatientDISCInfo({ id }: PatientDISCInfoProps): JSX.Element {
  const { http, providerId } = useHttp();
  const { id: patienId } = useParams();

  const discInfo = useQuery(
    [`${whoIam.categoryResults.queryUrl}-${id}`, providerId, patienId, id],
    {
      queryFn: fetchWhoIamCategoryResults(http.get),
    },
  );

  if (discInfo.isFetching) {
    return <TableCell><Skeleton /></TableCell>;
  }

  const result = discInfo?.data?.results[0];

  return (
    <DiscInfoText result={result} />
  );
}
