import React, { useMemo } from 'react';
import { Grid, Card, CardHeader, CardContent, Box, Typography, Icon } from '@mui/material';
import AppleHealthIcon from 'components/Common/AppleHealthIcon';
import Theme from 'theme';
import { getAverageText } from 'pages/Dashboard/utils/trackingUtils';
import ContentLoader from 'components/Common/ContentLoader';
import AppleHealthTooltip from 'components/Common/AppleHealthTooltip';

interface AppleHealthChartsWrapperProps {
  title: string;
  renderChart: React.ReactNode;
  headerIcon: string;
  timeFrame: string;
  subTitle?: string;
  isAppleHealthWaypoint?: boolean;
  gridSize?: number;
  showTimeFrame?: boolean;
  isLoading?: boolean;
  hasMultipleItems?: boolean;
  noData?: boolean;
  infoIcon?: boolean;
  infoIconText?: React.ReactElement;
}

function AppleHealthChartsWrapper({
  title,
  renderChart,
  headerIcon,
  timeFrame,
  subTitle = '',
  isAppleHealthWaypoint = true,
  gridSize = 4,
  showTimeFrame = true,
  isLoading,
  hasMultipleItems,
  noData,
  infoIcon,
  infoIconText,
}: AppleHealthChartsWrapperProps) {
  const timeFrameString = getAverageText(timeFrame, hasMultipleItems);

  const action = useMemo(() => {
    if (isAppleHealthWaypoint) {
      return <AppleHealthIcon />;
    }

    if (infoIcon) {
      return (
        <AppleHealthTooltip text={infoIconText as React.ReactElement} iconType='info_outlined' />
      );
    }
    return null;
  }, [infoIcon, infoIconText, isAppleHealthWaypoint]);

  return (
    <Grid item xs={12} md={gridSize} key='mood-card'>
      <Card
        sx={{
          height: '100%',
          borderRadius: '5px',
          overflow: 'visible',
          display: 'flex',
          flexDirection: 'column',
          '& .MuiCardContent-root:last-child': {
            paddingBottom: 1,
          },
        }}
      >
        <CardHeader
          sx={{
            borderRadius: '5px 5px 0 0',
            padding: 2,
            '& .MuiCardHeader-action': {
              margin: 0,
              alignSelf: 'center',
              cursor: 'pointer',
            },
          }}
          title={(
            <Box display='flex' alignItems='center'>
              <Icon sx={{ color: '#0000008A' }}>{headerIcon}</Icon>
              {/* TODO: fix the color */}
              <Typography variant='h5' ml={1} fontWeight='normal'>
                {title}
                {!!subTitle && (
                  <Typography variant='body1' color={Theme.custom.colors.lightTextSecondary}>
                    {subTitle}
                  </Typography>
                )}
              </Typography>
            </Box>
          )}
          action={action}
        />
        <ContentLoader isFetching={!!isLoading} isError={false} fullHeight minHeight={425}>
          {noData ? (
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              width='100%'
              height='100%'
              minHeight={100}
            >
              <Typography>no data exists</Typography>
            </Box>
          ) : (
            <CardContent
              sx={{ paddingY: 1, paddingX: 2, flex: 1, display: 'flex', flexDirection: 'column' }}
            >
              {showTimeFrame && (
                <Typography variant='body1' color={Theme.custom.colors.lightTextPrimary} mb={3}>
                  {timeFrameString}
                </Typography>
              )}
              {renderChart}
            </CardContent>
          )}
        </ContentLoader>
      </Card>
    </Grid>
  );
}

export default AppleHealthChartsWrapper;
