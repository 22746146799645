import { Practice, Provider } from 'types/Entitites';

// eslint-disable-next-line import/prefer-default-export
export interface ProviderRequest extends Provider {
  practices: Practice[];
}

export interface ListProviderRequestAPIResponse {
  requests: ProviderRequest[];
}

export interface ApproveProviderRequestAPIResponse {
  success: boolean;
}

export enum PracticeRequestAction {
  PRACTICE_REQUEST_ACTION_UNSPECIFIED = 0,
  PRACTICE_REQUEST_ACTION_CREATE = 1,
  PRACTICE_REQUEST_ACTION_MAP = 2,
  PRACTICE_REQUEST_ACTION_DECLINED = 3,
}

export interface ApproveProviderRequestPayload {
  id?: string;
  practices?: Array<Partial<Practice>>;
  notes?: string;
}
