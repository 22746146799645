import * as React from 'react';
import { Typography } from '@mui/material';
import theme from 'theme';

export default function NoDataForNow() {
  return (
    <>
      <Typography variant='body1' color={theme.custom.colors.lightTextSecondary} gutterBottom>
        No data exists at this time.
      </Typography>
      <Typography variant='body1' color={theme.custom.colors.lightTextSecondary}>
        The patient may provide this information through the Headlamp mobile app.
      </Typography>
    </>
  );
}
