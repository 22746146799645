import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import CardHeader from 'pages/Dashboard/components/CardHeader';
import NoDataForNow from 'pages/Dashboard/components/NoDataForNow';
import HealthRecordCategories from 'pages/Dashboard/HealthRecordCategories';
import { ShowMoreButton, ShowMoreDrawerChildProps, ShowMoreDrawerTitle, sliceForShowMore } from 'pages/Dashboard/ShowMoreDrawer';
import { DisorderLethal, FamilyHistory } from 'pages/Dashboard/types/familyHistory.types';
import * as React from 'react';
import theme from 'theme';

export interface FamilyHistoryCardProps extends ShowMoreDrawerChildProps {
  familyHistory?: FamilyHistory[];
}

type GroupRelationType = {[key: string]: FamilyHistory[] | []}

export default function FamilyHistoryCard({
  closeDrawer,
  drawerItem,
  drawerMode = false,
  familyHistory = [],
  onShowMore,
}: FamilyHistoryCardProps): JSX.Element {
  const groupByRelation = familyHistory.reduce((acc: GroupRelationType, obj) => {
    const { relationText: k } = obj;
    const curGroup = acc[k] ?? [];
    return { ...acc, [k]: [...curGroup, obj] };
  }, {});

  const getDisorderType = (type: DisorderLethal) => {
    switch (type) {
      case DisorderLethal.DISORDER_LETHAL:
        return <Typography>(lethal)</Typography>;
      case DisorderLethal.DISORDER_NON_LETHAL:
        return <Typography>(non-lethal)</Typography>;
      default:
        return null;
    }
  };

  const data = sliceForShowMore(Object.entries(groupByRelation), !drawerMode);

  return (
    <Grid item xs={12} md={!drawerMode ? 4 : undefined} key='family-history'>
      <Card sx={{ height: '100%' }}>
        {!drawerMode && (
          <CardHeader
            icon={HealthRecordCategories.familyHistory.value.icon}
            title='Family history'
          />
        )}
        {drawerMode && <ShowMoreDrawerTitle closeDrawer={closeDrawer} drawerItem={drawerItem} />}
        <CardContent>
          {
            familyHistory.length ? (
              data.map((entries) => {
                const [relation, history] = entries;
                return (
                  <Box key={`family-history-${history[0].relative.id}`} mb={2}>
                    <Typography variant='body1' color={theme.custom.colors.lightTextSecondary}>{relation}</Typography>
                    {
                      history.map((historyData) => historyData?.disorders.map((disorder) => (
                        <React.Fragment key={`disorder-${disorder.id}`}>
                          <Box display='flex' gap={1}>
                            <Typography>{disorder.name}</Typography>
                            {getDisorderType(disorder.lethal)}
                          </Box>
                        </React.Fragment>
                      )))
                    }
                  </Box>
                );
              })
            ) : (
              <NoDataForNow />
            )
          }
          <ShowMoreButton
            isDrawer={drawerMode}
            onShowMore={onShowMore as VoidFunction}
            items={Object.entries(groupByRelation)}
          />
        </CardContent>
      </Card>
    </Grid>
  );
}
