import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import exceptionImg from 'assets/all-exceptions.jpeg';
import React, { ErrorInfo, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Logger, captureException } from 'utils/logger';


interface IProps {
  children?: ReactNode;
}

interface IState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component <IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(_: Error): IState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Logger.error(`${error}, ${errorInfo.componentStack}`);
    captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box textAlign='center' mt={3}>
          <Stack spacing={2}>
            <Typography variant='h2'>Oops! Something went wrong</Typography>
            <Link to='mailto:help@headlamp.com'>Please contact us: help@headlamp.com</Link>
            <Paper elevation={0}><img src={exceptionImg} alt='Exception' /></Paper>
          </Stack>
        </Box>
      );
    }
    else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
