import { useTheme } from '@emotion/react';
import * as React from 'react';
import { PieChart, Pie, Cell } from 'recharts';

const sentenceCase = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

interface CustomLabelProps {
  cx?: number;
  cy?: number;
  label?: string;
}

function CustomLabel(props: CustomLabelProps): JSX.Element {
  const { cx, cy = 0, label } = props;

  return (
    <text
      x={cx}
      y={cy}
      fill='black'
      textAnchor='middle'
      fontSize={16}
      fontWeight={400}
    >
      {label?.split(' ').map((line: string, i: number) => (
        <tspan
          x={cx}
          y={cy + (i * 20)}
          textAnchor='middle'
        >
          {i === 0 ? sentenceCase(line) : line}
        </tspan>
      ))}
    </text>
  );
}

type GaugeData = {value: number};

interface GaugeChartProps {
  color: string;
  data: GaugeData[];
  label: string;
}


export default function GaugeChart({ color, data, label }: GaugeChartProps): JSX.Element {
  const theme = useTheme();

  return (
    <PieChart margin={{ bottom: 0, top: 0 }} width={150} height={150}>
      <Pie
        cy='50%'
        startAngle={225}
        endAngle={-45}
        innerRadius='70%'
        data={data}
        dataKey='value'
        labelLine={false}
        blendStroke
        isAnimationActive={false}
        label={<CustomLabel label={label} />}
      >
        <Cell fill={color} />
        <Cell fill={theme.palette.mode === 'dark' ? 'grey' : '#d2d2d2'} />
      </Pie>
    </PieChart>
  );
}
