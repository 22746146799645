import * as React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import RewardPoints from 'pages/Rewards/components/RewardPoints';
import { useMutation } from 'react-query';
import ContentLoader from 'components/Common/ContentLoader';
import theme from 'theme';
import { CheckCircle } from '@mui/icons-material';
import Input from 'components/Common/Input';

interface IQuestionBankForm {
  question?: string;
}

export const QuestionBankFormInitialState: IQuestionBankForm = {
  question: '',
};

export default function QuestionBankForm() {
  const {
    mutate: sendEmail,
    isLoading,
    isError,
  } = useMutation({
    mutationFn: (payload: IQuestionBankForm) => Promise.resolve(payload),
    retry: 2,
  });

  const onSubmit: SubmitHandler<IQuestionBankForm> = (data) => {
    sendEmail(data);
  };

  const formMethods = useForm<IQuestionBankForm>({
    defaultValues: QuestionBankFormInitialState,
  });

  const resetFormState = () => {
    formMethods.reset(QuestionBankFormInitialState);
  };

  const typographyProps = {
    fontSize: 24,
    fontWeight: 400,
  };

  if (formMethods.formState.isSubmitted) {
    return (
      <ContentLoader isFetching={isLoading} isError={isError} minHeight={200}>
        <Box my={6.5} textAlign='center' width='100%'>
          <Box display='flex' justifyContent='center' alignItems='center' mb={3}>
            <CheckCircle color='success' fontSize='large' />
            <Typography {...typographyProps} ml={1.25}>
              Success!
            </Typography>
          </Box>
          <Typography {...typographyProps} color={theme.custom.colors.lightTextSecondary} mb={3}>
            Question submitted
          </Typography>
          <Button variant='contained' size='large' onClick={resetFormState}>
            SUBMIT ANOTHER QUESTION
          </Button>
        </Box>
      </ContentLoader>
    );
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='body1'>
              Headlamp&apos;s mission is to bring better data to clinicians. In order to do that,
              we want to understand more elements about our patients that help explain why they feel
              the way they do. The field of precision psychiatry aims to identify individual traits
              and characteristics that may be more closely targeted for treatment purposes. As a
              Headlamp user, you have the expertise to help us drive that mission forward. What
              questions do you have around precision psychiatry that may be asked of patients or
              about patients to get us one step closer?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Input
              name='question'
              control={formMethods.control}
              label='Question'
              required
              inputProps={{
                'data-testid': 'question-input',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant='contained' type='submit'>
              SUBMIT QUESTION
            </Button>
          </Grid>
          <Grid item xs={12} display='flex'>
            <Typography variant='body1' mr='3px'>
              Earn
            </Typography>
            <RewardPoints rewardPoints={10} size='medium' />
            <Typography variant='body1' ml='3px'>
              when the question is reviewed!
            </Typography>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
