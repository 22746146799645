/* eslint-disable import/no-cycle */
import { ONBOARDING_STATUS, PATIENT_LIST_VIEW } from 'pages/Dashboard/enums';
import { PatientRecordRetrievalStatus, Waypoints } from 'pages/Dashboard/types';
import { Providers } from 'pages/Dashboard/types/providers.types';
import { GoogleDate } from 'utils/dateUtils';
import { GooglePhoneNumber } from 'utils/phoneUtils';

type NameObj = {
  firstName?: string;
  lastName?: string;
  middleName?: string;
  preferredName?: string;
};

type ErrorObj = {
  code: number;
  description: string;
}

type OnboardingMetaDataObj = {
  status: ONBOARDING_STATUS;
  error?: ErrorObj[]
}

export enum ThirtyDayStatus {
  UP = 'UP',
  DOWN = 'DOWN',
  NEUTRAL = 'NEUTRAL',
  NO_DATA = 'NO_DATA',
  NOT_ENOUGH_DATA = 'NOT_ENOUGH_DATA'
}

export enum HealthRecordStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  PRESENT = 'PRESENT',
  NO_DATA = 'NO_DATA',
}

export enum BehavioralDataStatus {
  SIGNUP_PENDING = 'SIGNUP_PENDING',
  NO_DATA = 'NO_DATA',
  PRESENT = 'PRESENT',
}

export type PatientObj = {
  dateOfBirth?: GoogleDate;
  email?: string;
  name?: NameObj;
  phoneNumber?: GooglePhoneNumber;
  provider?: Providers;
  id?: string;
  recordRetrievalStatus?: PatientRecordRetrievalStatus;
  onboardingMetadata?: OnboardingMetaDataObj;
  healthRecordStatus?:HealthRecordStatus;
  behavioralDataStatus?:BehavioralDataStatus
  patientActivity?:Date;
  thirtyDayStatus?:ThirtyDayStatus;
  inviteDeliveryDate?:string
  dateAdded?: Date;
  hasRead?: boolean;
};

export type PatientListResponse = {
  patients: PatientObj[];
}

export type StatusCountsObj = {
  status: PATIENT_LIST_VIEW,
  count: number;
}

export type PatientsCountResponse = {
  statusCounts: StatusCountsObj[];
}

export type PatientFiltersResponse = {
  filters: string[];
}

type DateObj = {
  date?: string;
};

export type AddPatientPayload = {
  appointment?: DateObj;
  patient?: PatientObj;
  providerId?: string;
  waypoints?: Waypoints[];
};
