import { Grid } from '@mui/material';
import RevokeWaypointSuggestions from 'pages/Dashboard/RevokeWaypointSuggestions';
import SendWaypointSuggestions from 'pages/Dashboard/SendWaypointSuggestions';
import { EDIT_WAYPOINT_SUGGESTIONS_VIEW } from 'pages/Dashboard/enums';
import { Waypoints } from 'pages/Dashboard/types';
import { PatientObj } from 'pages/Dashboard/types/patient.types';
import { WaypointSuggestion } from 'pages/Dashboard/types/waypointSuggestion.types';
import React from 'react';

interface EditWaypointsProps {
  view: EDIT_WAYPOINT_SUGGESTIONS_VIEW;
  patient: PatientObj;
  standardWaypoints: Waypoints[];
  patientWaypoints: Waypoints[];
  waypointSuggestions: WaypointSuggestion[];
  handleOpenDrawer: () => void;
  handleCloseDrawer: () => void;
  handleDeleteConfirmation: () => void;
  handleSendSuggestions: (suggestions: Partial<WaypointSuggestion>[]) => Promise<void>;
}

export default function EditWaypoints({
  view,
  patient,
  standardWaypoints,
  patientWaypoints,
  waypointSuggestions,
  handleOpenDrawer,
  handleCloseDrawer,
  handleDeleteConfirmation,
  handleSendSuggestions,
}: EditWaypointsProps) {
  return (
    <Grid container padding={2}>
      {view === EDIT_WAYPOINT_SUGGESTIONS_VIEW.SEND_WAYPOINT_SUGGESTIONS ? (
        <SendWaypointSuggestions
          patient={patient}
          standardWaypoints={standardWaypoints}
          patientWaypoints={patientWaypoints}
          handleCloseDrawer={handleCloseDrawer}
          handleSendSuggestions={handleSendSuggestions}
        />
      ) : (
        <RevokeWaypointSuggestions
          patient={patient}
          waypointSuggestions={waypointSuggestions}
          handleOpenDrawer={handleOpenDrawer}
          handleCloseDrawer={handleCloseDrawer}
          handleDeleteConfirmation={handleDeleteConfirmation}
        />
      )}
    </Grid>
  );
}
