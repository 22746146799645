import { useCallback, useState } from 'react';

const useLocalStorage = (key: string): [
  string | null,
  (newValue: string) => void,
  () => void,
] => {
  const [value, setValue] = useState<string | null>(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : null;
  });

  const setLocalStorageValue = useCallback((newValue: string) => {
    localStorage.setItem(key, JSON.stringify(newValue));
    setValue(newValue);
  }, [key]);

  const deleteLocalStorageValue = useCallback(() => {
    localStorage.removeItem(key);
    setValue(null);
  }, [key]);

  return [value, setLocalStorageValue, deleteLocalStorageValue];
};

export default useLocalStorage;
