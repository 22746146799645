import {
  CreatePatientFormData,
  InvitePatientFormData,
  PatientDemographicInfoPayload,
  PatientFormData,
  PatientInvitesPayload,
  Waypoints,
  WayPointsIndex,
} from 'pages/Dashboard/types';
import { differenceInYears } from 'date-fns';
import { dateStringToGoogleDate } from 'utils/dateUtils';
import { phoneNumberStringToGooglePhoneNumber } from 'utils/phoneUtils';

export function downloadUrl(url: string, fileName?: string, viewOnly?: boolean) {
  const anchorElem = document.createElement('a');
  anchorElem.href = url;
  if (!viewOnly) {
    anchorElem.setAttribute('download', fileName ?? '');
  }
  else {
    anchorElem.setAttribute('target', '_blank');
  }
  document.body.appendChild(anchorElem);
  anchorElem.click();
}

export function convertWayPointsData(data?: Waypoints[]) {
  const waypointsData: WayPointsIndex = {};

  data?.forEach(({ id, name, category, displayName }) => {
    if (Object.hasOwn(waypointsData, category)) {
      const newPair = { id, name, displayName };
      waypointsData[category] = [...waypointsData[category], newPair];
    }
    else {
      waypointsData[category] = [{ id, name, displayName }];
    }
  });
  return waypointsData;
}

export function setAddPatientPayload(
  {
    nextAppointmentDate,
    city,
    state,
    street,
    streetAdditional,
    zipCode,
    dob,
    email,
    gender,
    firstName,
    lastName,
    preferredName,
    mobile,
    practiceId,
    providerId,
    consent,
  }: PatientFormData,
  waypoints: Waypoints[],
) {
  const isMinor = differenceInYears(new Date(), new Date(dob as string)) < 18;

  const data = {
    appointment: {
      date: dateStringToGoogleDate(nextAppointmentDate as string),
      time: { hours: 18, minutes: 30, seconds: 30, nanos: 30 },
    },
    patient: {
      address: {
        city,
        country: 'USA',
        state,
        street,
        streetAdditional,
        zip: zipCode,
      },
      dateOfBirth: dateStringToGoogleDate(dob as string),
      email,
      gender,
      name: {
        firstName,
        lastName,
        middleName: '',
        preferredName,
      },
      phoneNumber: phoneNumberStringToGooglePhoneNumber(mobile as string),
      ...(isMinor ? { consent } : {}),
    },
    waypoints,
    practiceId,
    headlampProviderId: providerId,
  };

  return data;
}

export function setCreatePatientPayload({
  nextAppointmentDate,
  city,
  state,
  street,
  streetAdditional,
  zipCode,
  dob,
  email,
  gender,
  firstName,
  lastName,
  preferredName,
  mobile,
  practiceId,
  providerId,
  consent,
}: CreatePatientFormData) {
  const data: PatientDemographicInfoPayload = {
    appointment: {
      date: dateStringToGoogleDate(nextAppointmentDate as string),
      time: { hours: 18, minutes: 30, seconds: 30, nanos: 30 },
    },
    patient: {
      address: {
        city,
        country: 'USA',
        state,
        street,
        streetAdditional,
        zip: zipCode,
      },
      dateOfBirth: dateStringToGoogleDate(dob as string),
      email,
      gender,
      name: {
        firstName,
        lastName,
        middleName: '',
        preferredName,
      },
      phoneNumber: phoneNumberStringToGooglePhoneNumber(mobile as string),
      consent: true,
    },
    practiceId,
    headlampProviderId: providerId,
  };

  return data;
}

export function setInvitePatientsPayload({
  patients,
  practiceId,
  providerId,
}: InvitePatientFormData) {
  const data: PatientInvitesPayload = {
    patient: patients
      .filter(({ email, mobile }) => email || mobile)
      .map(({ email, mobile }) => ({
        ...(email ? { email } : {}),
        ...(mobile ? { phoneNumber: phoneNumberStringToGooglePhoneNumber(mobile as string) } : {}),
      })),
    headlampProviderId: providerId,
    practiceId,
  };

  return data;
}

export const generateRandomKeys = () => (Math.random() + 1).toString(36).substring(7);

const serviceModule = {
  convertWayPointsData,
};

export default serviceModule;
