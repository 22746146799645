import React, { memo } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PendingPatientCard from 'pages/Dashboard/components/PendingPatientCard';
import RecentPatientCard from 'pages/Dashboard/components/RecentPatientCard';
import { PATIENT_LIST_VIEW } from 'pages/Dashboard/enums';
import ContentLoader from 'components/Common/ContentLoader';
import { PatientObj } from 'pages/Dashboard/types/patient.types';

const emptyListTextMap: {
  [key in string]: { text: string; buttonText: string; goToFilter: PATIENT_LIST_VIEW };
} = {
  [PATIENT_LIST_VIEW.READY]: {
    text: 'There are no ready patients.',
    buttonText: 'VIEW PENDING PATIENTS',
    goToFilter: PATIENT_LIST_VIEW.PENDING,
  },
  [PATIENT_LIST_VIEW.PENDING]: {
    text: 'There are no pending patients.',
    buttonText: 'VIEW READY PATIENTS',
    goToFilter: PATIENT_LIST_VIEW.READY,
  },
  [PATIENT_LIST_VIEW.ERRORS]: {
    text: 'There are no patients with errors.',
    buttonText: 'VIEW READY PATIENTS',
    goToFilter: PATIENT_LIST_VIEW.READY,
  },
};

interface TProps {
  filteredPatientList: PatientObj[];
  isError: boolean;
  isFetching: boolean;
  setStatusFilter: (status: string) => void;
  showProvider: boolean;
  statusFilter: string;
}

function ListPatients({
  filteredPatientList,
  isError,
  isFetching,
  setStatusFilter,
  showProvider,
  statusFilter,
}: TProps) {
  return (
    <ContentLoader isFetching={isFetching} isError={isError} minHeight={400}>
      {filteredPatientList.length > 0 ? (
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {filteredPatientList.map((patient) => (
              <Grid item sm={12} md={6} lg={4} xl={3} key={patient.id}>
                {patient.name?.firstName ? (
                  <RecentPatientCard details={patient} showProvider={showProvider} />
                ) : (
                  <PendingPatientCard details={patient} showProvider={showProvider} />
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12} textAlign='center'>
          <Typography sx={{ margin: '24px 0' }}>{emptyListTextMap[statusFilter].text}</Typography>
          <Button
            color='primary'
            variant='text'
            onClick={() => setStatusFilter(emptyListTextMap[statusFilter].goToFilter)}
          >
            {emptyListTextMap[statusFilter].buttonText}
          </Button>
        </Grid>
      )}
    </ContentLoader>
  );
}

export default memo(ListPatients);
