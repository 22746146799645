import React from 'react';
import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
} from '@mui/material';
import LoadingButton from 'components/Common/LoadingButton';

type TDialogProps = {
  open: boolean;
  title?: string;
  actionBtnLabel: string;
  actionBtnDisabled: boolean;
  actionBtnLoading: boolean;
  size: 'sm' | 'md' | 'lg';
  actionBtnLeftIcon?: JSX.Element;
  secondaryActionBtnLabel?: string;
  secondaryActionBtnDisabled?: boolean;
  secondaryActionBtnLoading?: boolean;
  secondaryActionBtnLeftIcon?: JSX.Element;
  closeBtnLabel?: string;
  secondaryActionBtnHandler?: () => void;
  actionBtnHandler: () => void;
  renderContent: () => React.ReactElement;
  onClose: () => void;
};

export function Dialog({
  open,
  title,
  actionBtnLabel,
  actionBtnDisabled,
  actionBtnLoading,
  size,
  actionBtnLeftIcon,
  secondaryActionBtnLabel,
  secondaryActionBtnDisabled = false,
  secondaryActionBtnLoading = false,
  secondaryActionBtnLeftIcon,
  closeBtnLabel = 'CLOSE',
  secondaryActionBtnHandler = () => null,
  actionBtnHandler,
  renderContent,
  onClose,
}: TDialogProps): JSX.Element {
  return (
    <MuiDialog open={open} onClose={onClose} maxWidth={size} fullWidth>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{renderContent()}</DialogContent>
      <Divider />
      <DialogActions sx={{ m: 1, gap: 1 }}>
        <Button onClick={onClose} variant='outlined'>
          {closeBtnLabel}
        </Button>
        {secondaryActionBtnLabel && (
          <LoadingButton
            onClick={secondaryActionBtnHandler}
            variant='contained'
            disabled={secondaryActionBtnDisabled}
            isLoading={secondaryActionBtnLoading}
            label={secondaryActionBtnLabel}
            startIcon={secondaryActionBtnLeftIcon}
          />
        )}
        <LoadingButton
          onClick={actionBtnHandler}
          variant='contained'
          disabled={actionBtnDisabled}
          isLoading={actionBtnLoading}
          label={actionBtnLabel}
          startIcon={actionBtnLeftIcon}
        />
      </DialogActions>
    </MuiDialog>
  );
}

export default Dialog;
