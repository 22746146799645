import { SourceType } from 'pages/Dashboard/types';
import { Frequency, GoogleDate } from 'utils/dateUtils';

export enum LifeStyleCategory {
  LIFE_STYLE_CATEGORY_UNSPECIFIED = 'LIFE_STYLE_CATEGORY_UNSPECIFIED',
  LIFE_STYLE_CATEGORY_EXERCISE = 'LIFE_STYLE_CATEGORY_EXERCISE',
  LIFE_STYLE_CATEGORY_ALCOHOL = 'LIFE_STYLE_CATEGORY_ALCOHOL',
  LIFE_STYLE_CATEGORY_TOBACCO = 'LIFE_STYLE_CATEGORY_TOBACCO',
  LIFE_STYLE_CATEGORY_CAFFEINE = 'LIFE_STYLE_CATEGORY_CAFFEINE',
  LIFE_STYLE_CATEGORY_OTHER_SUBSTANCE = 'LIFE_STYLE_CATEGORY_OTHER_SUBSTANCE',
}

export type consumptionType =
  | LifeStyleAlcohol
  | LifeStyleExercise
  | LifeStyleCaffeine
  | OtherSubstance
  | undefined;

export interface Exercise {
  id: string;
  name: string;
}

export interface LifeStyleExercise {
  id: string;
  consumption: boolean;
  startDate: GoogleDate;
  endDate: GoogleDate;
  description: string;
  quantity: string;
  unit: string;
  frequency: Frequency;
  exercises: Exercise[];
  current: boolean;
  source: SourceType;
}

interface AlcoholicDrink {
  id: string;
  name: string;
  quantity: string;
  unit: string;
  frequency: Frequency;
}

export interface LifeStyleAlcohol {
  id: string;
  consumption: boolean;
  startDate: GoogleDate;
  endDate: GoogleDate;
  description: string;
  quantity: string;
  unit: string;
  frequency: Frequency;
  drinks: AlcoholicDrink[];
  current: boolean;
  source: SourceType;
}

interface TobaccoForm {
  id: string;
  name: string;
}

export interface LifeStyleTobacco {
  id: string;
  consumption: boolean;
  startDate: GoogleDate;
  endDate: GoogleDate;
  description: string;
  quantity: string;
  unit: string;
  frequency: Frequency;
  tobaccoForms: TobaccoForm[];
  current: boolean;
  source: SourceType;
}

export interface LifeStyleCaffeine {
  id: string;
  consumption: boolean;
  startDate: GoogleDate;
  endDate: GoogleDate;
  description: string;
  quantity: string;
  unit: string;
  frequency: Frequency;
  current: boolean;
  source: SourceType;
}

export interface OtherSubstance {
  id: string;
  name: string;
  description: string;
  quantity: string;
  unit: string;
  frequency: Frequency;
  startDate: GoogleDate;
  endDate: GoogleDate;
  consumption: boolean;
  current: boolean;
}

export interface LifeStyleOtherSubstance {
  id: string;
  consumption: boolean;
  startDate: GoogleDate;
  endDate: GoogleDate;
  description: string;
  quantity: string;
  unit: string;
  frequency: Frequency;
  substances: OtherSubstance[];
  current: boolean;
  source: SourceType;
}

export interface PatientLifeStyle {
  category: LifeStyleCategory;
  exercise?: LifeStyleExercise;
  alcohol?: LifeStyleAlcohol;
  tobacco?: LifeStyleTobacco;
  caffeine?: LifeStyleCaffeine;
  otherSubstance?: LifeStyleOtherSubstance;
}

export interface PatientLifeStyleAPIResponse {
  patientLifeStyles: PatientLifeStyle[];
}
