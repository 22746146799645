import * as React from 'react';
import { Skeleton, TableCell } from '@mui/material';
import { useQuery } from 'react-query';
import { useHttp } from 'hooks/use-fetch';
import { useParams } from 'react-router-dom';
import { fetchWhoIamCategoryResults } from 'pages/Dashboard/services/whoiam.services';
import { whoIam } from 'utils/apiUrls';
import LocusOfControlText from 'pages/Dashboard/components/LocusOfControlText';

interface PatientLocusOfControlProps {
  id: string;
}

export default function PatientLocusOfControl({ id }: PatientLocusOfControlProps): JSX.Element {
  const { http, providerId } = useHttp();
  const { id: patienId } = useParams();

  const locusOfControl = useQuery(
    [`${whoIam.categoryResults.queryUrl}-${id}`, providerId, patienId,
      id],
    {
      queryFn: fetchWhoIamCategoryResults(http.get),
    },
  );


  if (locusOfControl.isFetching) {
    return <TableCell><Skeleton /></TableCell>;
  }

  const result = locusOfControl?.data?.results[0];


  return (
    <LocusOfControlText result={result} />
  );
}
