import * as React from 'react';
import { Typography } from '@mui/material';
import theme from 'theme';

export default function NoData() {
  return (
    <Typography variant='body1' color={theme.custom.colors.lightTextSecondary} gutterBottom>
      No data found.
    </Typography>
  );
}
