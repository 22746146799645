import { CategoryName, DISCColors, DISCIdentifyingTerms } from 'pages/Dashboard/types/whoiam.types';

// eslint-disable-next-line import/prefer-default-export
export const CategoryTooltipText = {
  [CategoryName.WIM_CATEGORY_NAME_QUALITY_OF_LIFE]: `
        A 16 question instrument for measuring the quality of life across
        patient groups and cultures and is conceptually distinct from health
        status or other casual indicators of quality of life.`,
  [CategoryName.WIM_CATEGORY_NAME_CORE_VALUES]: 'Starting from a list of 30 values, the patient selects their top 10. From their top 10, they choose their top 5.',
  [CategoryName.WIM_CATEGORY_NAME_LOCUS_OF_CONTROL]: 'A 29 question tool to measure the degree to which individuals believe they have the ability to control what happens to them (internal) or how much they think that forces beyond their control affect their situation (external).',
  [CategoryName.WIM_CATEGORY_NAME_DISC]: 'A personality assessment tool that provides a common language people can use to better understand themselves and those they interact with—and then use this knowledge to reduce conflict and improve working relationships.',
  [CategoryName.WIM_CATEGORY_NAME_PHQ8]: '',
  [CategoryName.WIM_CATEGORY_NAME_GAD7]: '',
  [CategoryName.WIM_CATEGORY_NAME_WHO5]: '',
};

export const DiscColors: DISCColors = {
  D: '#DC010C',
  I: '#EFBE1D',
  S: '#009C3F',
  C: '#005BAC',
};

export const DiscIdentifyingTerms: DISCIdentifyingTerms = {
  D: 'Competitive, Adventurous, Decisive',
  I: 'Inclusive, Creative, Outgoing, Talkative',
  S: 'Patient, Reassuring, Mediator, Methodical',
  C: 'Accurate, Analytical, Structured, Purposeful',
};

