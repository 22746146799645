import { Typography } from '@mui/material';
import { Doctor } from 'pages/Dashboard/types/diagnosis.types';
import * as React from 'react';

interface DoctorTextProps{
    doctor: Doctor | null,
}


export default function DoctorText({ doctor }: DoctorTextProps) {
  if (!doctor) return null;


  return (
    <Typography variant='subtitle1' mb={2}>
      {doctor.name}
      {` (${doctor.designation})`}
    </Typography>
  );
}
