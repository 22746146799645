import { Box, capitalize, Skeleton, Typography } from '@mui/material';
import AppleHealthIcon from 'components/Common/AppleHealthIcon';
import AppleHealthActivitySummary from 'pages/Dashboard/components/AppleHealthActivitySummary';
import AppleHealthSleepChartSummary from 'pages/Dashboard/components/AppleHealthSleepChartSummary';
import SleepChart from 'pages/Dashboard/components/SelfReportedSleepChart';
import {
  ListWaypointDetails,
  WaypointTrackingAPIResponse,
} from 'pages/Dashboard/types/waypoints.types';
import usePatientTrackingSummary from 'pages/Dashboard/usePatientTrackingSummary';
import { TimeFrameOptions, defaultWaypointTracking } from 'pages/Dashboard/utils/trackingUtils';
import * as React from 'react';
import Theme from 'theme';
import { MixpanelEventName } from 'utils/constants';
import { trackMixpanelEvent } from 'utils/utilMethods';

interface PatientTrackingProps {
  patientId: string;
}

export default function PatientTrackingSummary({ patientId }: PatientTrackingProps) {
  const timeFrame = TimeFrameOptions.LAST_30_DAYS;
  const {
    widget,
    fetchActivityPatientWaypointTrackingResult: fetchActivityResult,
    fetchSleepPatientWaypointTrackingResult: fetchSleepResult,
    fetchCurrentSelfReportWaypointTrackingResult: fetchCurrent,
    fetchPreviousSelfReportWaypointTrackingResult: fetchPrevious,
    setSourceId,
    sourceId,
    sourceOptions,
    isLoading,
  } = usePatientTrackingSummary(patientId);

  const onChangeSource = React.useCallback(
    (source: string) => {
      trackMixpanelEvent(
        MixpanelEventName.AH_SLEEP_CHART_SOURCE_CHANGED,
        {
          'patient id': patientId ?? '',
        },
        true,
      );
      setSourceId(source);
    },
    [patientId, setSourceId],
  );

  const currentData = (fetchCurrent.data as WaypointTrackingAPIResponse) ?? defaultWaypointTracking;
  const previousDa = (fetchPrevious.data as WaypointTrackingAPIResponse) ?? defaultWaypointTracking;

  const sleepData = fetchSleepResult.data as ListWaypointDetails;
  const currentSleepData = sleepData?.categoryAvgData;
  const previousSleepData = sleepData?.previousIntervalCategoryAvgData;
  const activityData = fetchActivityResult.data as WaypointTrackingAPIResponse;
  const currentActivityData = activityData?.averages;
  const previousActivityData = activityData?.previousIntervalAverages;
  if (
    isLoading
  ) {
    return <Skeleton height='100px' width='100%' sx={{ mt: -1 }} />;
  }
  return (
    <Box width='100%' ml={2} mr={0} display='flex'>
      {widget === 'sleep' && (
        <Box width='100%' ml={2}>
          <Box display='flex' alignItems='center' mb={2}>
            <Typography fontSize={16} mr='12px'>
              Sleep
            </Typography>
            <AppleHealthIcon />
          </Box>
          <AppleHealthSleepChartSummary
            timeFrame={timeFrame}
            averages={currentSleepData}
            previousPeriodAverages={previousSleepData}
            setSourceId={onChangeSource}
            sourceId={sourceId}
            sourceOptions={sourceOptions}
          />
        </Box>
      )}
      {widget === 'activity' && (
        <Box display='flex' flexDirection='column' ml={2}>
          <Box display='flex' alignItems='center' mb={2}>
            <Typography fontSize={16} mr={1}>
              {' '}
              Activity
            </Typography>
            <AppleHealthIcon />
          </Box>
          <AppleHealthActivitySummary
            averages={currentActivityData}
            previousPeriodAverages={previousActivityData}
            timeFrame={timeFrame}
          />
        </Box>
      )}
      {widget === 'selfReport' && (
        <Box display='flex' flexDirection='column'>
          <Box display='flex' alignItems='center' mb={2}>
            <Typography fontSize={16} ml={2}>
              {capitalize(currentData?.waypoints?.[0]?.waypoint?.name ?? '')}
            </Typography>
          </Box>
          <SleepChart
            currentDataArray={currentData?.waypoints}
            previousDataArray={previousDa?.waypoints}
          />
        </Box>
      )}
      {widget === 'NO_DATA' && (
        <Box display='flex' width='100%'>
          <Typography
            color={Theme.custom.colors.lightTextSecondary}
            ml={2}
            mt={2}
            textAlign='center'
          >
            No data for this timeframe
          </Typography>
        </Box>
      )}
    </Box>
  );
}
