import React from 'react';
import { Link, Typography } from '@mui/material';

export function PatientDemographicFormConsent(): JSX.Element {
  return (
    <Typography color='GrayText' variant='body2'>
      This indicates that you have provided notice to your patient(s) and obtained their
      authorization to pull patient medical record history. By clicking “Add Patient”, I represent
      and warrant that I have provided any legally required privacy notice (e.g., HIPAA Privacy
      Notice) and obtained patient authorization as required under applicable law from each patient
      listed below that their medical information will be provided to Headlamp. I further understand
      and agree that such information will be processed by Headlamp under the Headlamp
      {' '}
      <Link href='https://www.headlamp.com/terms' target='_blank' underline='hover'>
        Terms of Service
      </Link>
      {' '}
      and
      {' '}
      <Link href='https://www.headlamp.com/privacy' target='_blank' underline='hover'>
        Privacy Policy
      </Link>
      .
    </Typography>
  );
}

export function PatientInviteFormConsent(): JSX.Element {
  return (
    <Typography color='GrayText' variant='body2'>
      The patient will be sent a link to enter information needed to access their medical records
      and create their Headlamp account. By clicking “Send Invite(s)”, I acknowledge that parent /
      legal guardian consent has been obtained for any patients under the age of 18.
    </Typography>
  );
}

export function SMSConsent(): JSX.Element {
  return (
    <Typography color='GrayText' variant='body2'>
      Just a reminder that you should make sure that you have obtained patient permission to receive
      SMS messages from business associates including Headlamp, which is likely already captured in
      your clinic intake forms.
    </Typography>
  );
}
