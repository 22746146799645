import { SourceType } from 'pages/Dashboard/types';
import { Doctor } from 'pages/Dashboard/types/diagnosis.types';
import { GoogleDate } from 'utils/dateUtils';

export enum MedicationCategory {
  MEDICATION_CATEGORY_UNSPECIFIED = 'MEDICATION_CATEGORY_UNSPECIFIED',
  MEDICATION_CATEGORY_PSYCH = 'MEDICATION_CATEGORY_PSYCH',
  MEDICATION_CATEGORY_OTHER = 'MEDICATION_CATEGORY_OTHER',
}

export interface PatientMedication {
  id: string;
  medication: Medication;
  instructions: string;
  quantity: string;
  status: string;
  started: GoogleDate | null;
  stopped: GoogleDate | null;
  doctor: Doctor;
  category: MedicationCategory;
  indication: string;
  source: SourceType
}


export interface Medication {
  id: string;
  rxcui: string;
  brandName: string;
  displayName: string;
  fullName: string;
  fullGenericName: string;
  strength: string;
  rxtermsDoseForm: string;
  standardDisplayName: string;
  genericRxcui: string;
  rxNormDoseForm: string;
  suppress: string;
  synonym: string;
  route: string;
}

export interface MedicationAPIResponse {
  patientMedications: PatientMedication[];
}
