import { Add } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import UpperText from 'components/Common/UpperText';
import AddressSearch from 'pages/Authentication/components/AddressSearch';
import { Address, ResultItem } from 'pages/Authentication/types/AddressSearchAutoComplete';
import * as React from 'react';
import { Practice } from 'types/Entitites';

const practiseNameValidationMessage = 'Practise name can only contain alphabetic characters, numbers, underscores and dashes.';
const practiseNameRegex = /^[a-zA-Z0-9_-\s]+$/;

interface PracticeFormProps {
  data: Partial<Practice>;
  onPracticeSave?: (p: Partial<Practice>) => void;
  onPracticeUpdate?: (p: Partial<Practice>, i: number) => void;
  index: number;
  isNew?: boolean;
  practices?: Partial<Practice>[];
  onEditCancel?: () => void;
}

export default function PracticeForm({
  data,
  onPracticeSave,
  index,
  onPracticeUpdate,
  isNew = true,
  practices,
  onEditCancel,
}: PracticeFormProps) {
  const [formData, setFormData] = React.useState<Partial<Practice>>({
    name: '',
    address: {},
  });
  const formRef = React.useRef<HTMLFormElement | null>();

  React.useEffect(() => {
    setFormData(data);
  }, [data]);

  const onAddressSelect = React.useCallback(
    (address: Address | null, value: ResultItem | null) => {
      if (!address) {
        return;
      }

      setFormData({
        ...formData,
        address: {
          street: formData.address?.street,
          state: address.state?.longName,
          zip: address.zipCode?.longName,
          city: address.city?.longName,
          streetAdditional: '',
          fieldValue: value,
          country: address.country ?? 'USA',
        },
      });
    },
    [formData],
  );

  function setFormAddressField(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string,
  ) {
    setFormData({
      ...formData,
      address: {
        ...formData.address,
        country: 'USA',
        [field]: e.target.value,
      },
    });
  }

  const onFormSubmit = () => {
    const prunedFormData = {
      ...formData,
      name: formData.name?.trim(),
    };
    if (isNew) {
      onPracticeSave?.(prunedFormData);
    }
    else {
      onPracticeUpdate?.(prunedFormData, index);
    }
  };

  const handleZipInputField = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setFormAddressField(e, 'zip');
    }
  };

  const handlePractiseNameField = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const input = e.target;
    setFormData({
      ...formData,
      name: input.value,
    });
    const isInValid = !!e.target.value && !practiseNameRegex.test(e.target.value.trim());
    input.setCustomValidity(isInValid ? practiseNameValidationMessage : '');
    input.reportValidity();
  };

  return (
    <form
      ref={(ref) => {
        formRef.current = ref;
      }}
    >
      <Stack spacing={2}>
        <Box>
          <TextField
            fullWidth
            label='Practice name'
            required
            onChange={handlePractiseNameField}
            inputProps={{
              maxLength: 50,
              minLength: 3,
            }}
            value={formData.name || ''}
          />
        </Box>
        <Box>
          <AddressSearch
            inputLabel='Practice address'
            addressValue={formData.address?.fieldValue || { label: '', id: 'defaultId' }}
            onAddressSelect={onAddressSelect}
            onInputChange={(value) => {
              setFormData({
                ...formData,
                address: {
                  ...formData.address,
                  street: value,
                  fieldValue: { label: value },
                },
              });
            }}
          />
        </Box>
        <Box display='flex' gap={2} flexDirection={{ xs: 'column', sm: 'row' }}>
          <Box width={{ xs: '100%', sm: 0.5 }}>
            <TextField
              label='Ste, bldg, apt, etc.'
              fullWidth
              onChange={(e) => setFormAddressField(e, 'streetAdditional')}
              value={formData.address?.streetAdditional || ''}
            />
          </Box>
          <Box width={{ xs: '100%', sm: 0.25 }} />
          <Box width={{ xs: '100%', sm: 0.25 }} />
        </Box>
        <Box display='flex' gap={2} flexDirection={{ xs: 'column', sm: 'row' }}>
          <Box width={{ xs: '100%', sm: 0.5 }}>
            <TextField
              label='City'
              fullWidth
              required
              value={formData.address?.city || ''}
              onChange={(e) => setFormAddressField(e, 'city')}
            />
          </Box>
          <Box width={{ xs: '100%', sm: 0.25 }}>
            <TextField
              label='State'
              fullWidth
              required
              value={formData.address?.state || ''}
              onChange={(e) => setFormAddressField(e, 'state')}
            />
          </Box>
          <Box width={{ xs: '100%', sm: 0.25 }}>
            <TextField
              label='Zip'
              fullWidth
              inputProps={{ maxLength: 5, minLength: 5 }}
              required
              value={formData.address?.zip || ''}
              onChange={handleZipInputField}
            />
          </Box>
        </Box>
        <Box width={{ xs: '100%', sm: 'max-content' }}>
          {isNew ? (
            <Button
              onClick={() => {
                if (formRef?.current?.reportValidity()) {
                  onFormSubmit();
                }
              }}
              type='button'
              sx={{ width: '100%' }}
              startIcon={<Add />}
              color='primary'
              variant={practices && practices.length > 0 ? 'outlined' : 'contained'}
            >
              <UpperText>Add Practice</UpperText>
            </Button>
          ) : (
            <Box display='flex' gap={3}>
              <Button onClick={onEditCancel} type='button' color='primary' variant='outlined'>
                <UpperText>Cancel</UpperText>
              </Button>
              <Button
                onClick={() => {
                  if (formRef?.current?.reportValidity()) {
                    onFormSubmit();
                  }
                }}
                type='button'
                color='primary'
                variant='contained'
              >
                <UpperText>Update</UpperText>
              </Button>
            </Box>
          )}
        </Box>
      </Stack>
    </form>
  );
}
