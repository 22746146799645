import * as React from 'react';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Spinner from 'components/Common/Spinner';
import { FetchContext, useHttp } from 'hooks/use-fetch';
import BorderTableRow from 'components/Common/BorderTableRow';
import NoProviderRequests from 'pages/Admin/NoProviderRequests';
import { useQuery } from 'react-query';
import { providerPracticesUrls } from 'utils/apiUrls';
import { useNavigate } from 'react-router-dom';
import { ListProviderPracticeAPIResponse, ProviderPractice } from 'pages/SuperAdmin/types';
import listProviderPracticeRequest from 'pages/SuperAdmin/providerPractices.services';

export default function ProvidersList() {
  const navigate = useNavigate();

  const { http } = useHttp();
  const { updateActiveProvider, superAdminId } = React.useContext(FetchContext);

  const { data, isFetching } = useQuery([providerPracticesUrls.listProviderPractices.queryUrl], {
    queryFn: listProviderPracticeRequest(http.get),
  });

  const providerRequestListResponse = data as ListProviderPracticeAPIResponse;

  if (isFetching) {
    return <Spinner />;
  }

  const providersList = (providerRequestListResponse?.providerPractices ?? []).filter(
    (provider) => provider.providerId !== superAdminId,
  );

  const goToDashboard = (provider: ProviderPractice) => {
    updateActiveProvider(provider);
    navigate('/');
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label='provider-requests-table'>
        <TableHead>
          <TableRow>
            <TableCell>Provider Name</TableCell>
            <TableCell>Provider Email</TableCell>
            <TableCell>Practices</TableCell>
            <TableCell align='center'>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {providersList?.length > 0 ? (
            providersList.map((provider) => (
              <BorderTableRow key={`provider-provider-${provider.providerId}`}>
                <TableCell>{provider.providerName}</TableCell>
                <TableCell>{provider.providerEmail}</TableCell>
                <TableCell>
                  <ListItemText
                    primary={provider.practiceName}
                    secondary={provider.practiceAddress}
                  />
                </TableCell>
                <TableCell>
                  <Button onClick={() => goToDashboard(provider)}>
                    Go to Provider&apos;s Dashboard
                  </Button>
                </TableCell>
              </BorderTableRow>
            ))
          ) : (
            <NoProviderRequests />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
