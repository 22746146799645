import { Box, Grid, Link, Typography } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React from 'react';
import { MixpanelEventName, NotSpecified } from 'utils/constants';
import theme from 'theme';
import { format } from 'date-fns';
import { CategoryResult, Scale } from 'pages/Dashboard/types/whoiam.types';
import { trackMixpanelEvent } from 'utils/utilMethods';

const comparison = (result: CategoryResult[], reversed?: boolean) => {
  const diff = result[0].score - result[1].score;
  const SuccessIcon = reversed ? ArrowDropUpIcon : ArrowDropDownIcon;
  const ErrorIcon = reversed ? ArrowDropDownIcon : ArrowDropUpIcon;

  if (reversed ? diff <= 0 : diff >= 0) {
    return (
      <Box display='flex'>
        <ErrorIcon color='error' />
        <Typography color='red'>{diff}</Typography>
      </Box>
    );
  }
  else {
    return (
      <Box display='flex'>
        <SuccessIcon color='success' />
        <Typography color='green'>
          {reversed ? '+' : ''}
          {diff}
        </Typography>
      </Box>
    );
  }
};

function ScaleResultSummary({
  results,
  name,
  setSelectedScale,
}: {
  results: CategoryResult[];
  name: string;
  setSelectedScale: React.Dispatch<React.SetStateAction<Scale>>;

}) {
  const onScaleClickHandler = (categoryId: string) => () => {
    const selectedScaleDetails = {
      categoryId,
      results,
    };

    setSelectedScale({
      ...(selectedScaleDetails.results?.[0] || ({} as CategoryResult)),
      categoryId: selectedScaleDetails.categoryId,
      scale: name,
    });

    trackMixpanelEvent(MixpanelEventName.SCALE_CLICKED, {
      scale: name,
    });
  };
  return results.length ? (
    <Grid item>
      <Box display='flex' flexDirection='column' alignItems='flex-start'>
        <Link sx={{ cursor: 'pointer ' }} onClick={onScaleClickHandler(name)} underline='hover'>
          <Typography fontSize={20} variant='h6'>{name}</Typography>
        </Link>

        {results?.length ? (
          <>
            <Typography color={theme.custom.colors.lightTextSecondary} fontSize={14}>
              {`${format(new Date(results?.[0]?.attemptedAt || ''), 'M/d/yy')}`}
            </Typography>
            <Box display='flex' alignItems='center'>
              <Typography fontSize={34}>{results?.[0]?.score ?? ''}</Typography>
              { results?.length > 1 && comparison(results)}
            </Box>
            <Typography fontSize={12}>{results?.[0]?.description}</Typography>
            {results?.[1] ? (
              <Typography color='#787878' fontSize={12}>
                {`compared to ${format(new Date(results?.[1]?.attemptedAt || ''), 'M/d/yy')}`}
              </Typography>
            ) : null}
          </>
        ) : (
          <Typography>{NotSpecified}</Typography>
        )}
      </Box>
    </Grid>
  ) : null;
}

export default ScaleResultSummary;
