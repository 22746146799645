import * as React from 'react';
import { TableCell, TableRow, Typography } from '@mui/material';
import { EducationFormatter, RelationFormatter } from 'pages/Dashboard/services/lifestyle.services';
import { EducationLevel, PatientBackground, PatientBackgroundCategory, PatientRelationshipCategory } from 'pages/Dashboard/types/background.types';

interface PatientBackgroundInfoProps {
    patientBackground: PatientBackground;
}

export default function PatientBackgroundInfo({ patientBackground }: PatientBackgroundInfoProps) {
  const { relationship, education } = patientBackground;
  switch (patientBackground.category) {
    case PatientBackgroundCategory.PATIENT_BACKGROUND_CATEGORY_RELATIONSHIP:
      return (
        <TableRow>
          <TableCell>
            <Typography>
              Relationship
            </Typography>
          </TableCell>
          <TableCell>
            <Typography>
              {RelationFormatter(relationship?.relationshipStatus
                || PatientRelationshipCategory.PATIENT_RELATIONSHIP_CATEGORY_UNSPECIFIED)}
            </Typography>
          </TableCell>
        </TableRow>
      );
    case PatientBackgroundCategory.PATIENT_BACKGROUND_CATEGORY_EDUCATION:
      return (
        <TableRow>
          <TableCell>
            <Typography>
              Education
            </Typography>
          </TableCell>
          <TableCell>
            <Typography>
              {EducationFormatter(education?.educationLevel
                || EducationLevel.EDUCATION_LEVEL_UNSPECIFIED)}
            </Typography>
          </TableCell>
        </TableRow>
      );
  }
  return null;
}
