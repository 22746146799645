import * as React from 'react';
import { Button, Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { PATIENT_LIST_VIEW } from 'pages/Dashboard/enums';
import { mainNav } from 'utils/spaUrls';
import { Link } from 'react-router-dom';
import { PersonAdd } from '@mui/icons-material';
import SelectProvider from 'pages/Dashboard/components/SelectProvider';
import { PracticeProvider } from 'pages/Dashboard/types/providers.types';
import { MixpanelEventName } from 'utils/constants';
import { trackMixpanelEvent } from 'utils/utilMethods';

interface TProps {
  statusFilter: string;
  patientsCount: { [key in PATIENT_LIST_VIEW]: number };
  updateStatusFilter: React.Dispatch<string>;
  isFetching: boolean;
  providerAssociations: PracticeProvider[];
  providerIdFilter: string;
  setProviderIdFilter: (provider:string) => void;
}

export default function ListPatientFilters({
  statusFilter,
  patientsCount,
  updateStatusFilter,
  isFetching,
  providerAssociations,
  providerIdFilter,
  setProviderIdFilter,
}: TProps) {
  const titleMap = {
    READY: 'READY',
    PENDING: 'APP SIGNUP PENDING',
    ERRORS: 'APP INVITE ERRORS',
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        marginBottom={1}
        mt={0}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Box sx={{ display: 'flex', ml: 3, gap: 0, mt: 2 }}>
          {Object.values(PATIENT_LIST_VIEW).map((filter) => (
            <Grid item key={filter}>
              <Typography
                fontStyle='small'
                sx={{
                  borderBottom: 3,
                  paddingBottom: 1,
                  pl: 2,
                  pr: 2,
                  borderBottomColor: statusFilter === filter ? '#33739D' : 'transparent',
                  cursor: 'pointer',
                  color: statusFilter === filter ? '#33739D' : '#787878',
                  fontWeight: 400,
                }}
                onClick={() => updateStatusFilter(filter)}
              >
                {`${titleMap[filter]} (${patientsCount[filter] || 0})`}
              </Typography>
            </Grid>
          ))}
        </Box>
        <Box sx={{ bgColor: 'red', display: 'flex', alignItems: 'center', mt: 1, mb: 1 }}>
          <Box sx={{}}>
            <SelectProvider
              providers={providerAssociations}
              selectedProvider={providerIdFilter}
              setSelectedProvider={setProviderIdFilter}
            />
          </Box>
          <Button
            color='primary'
            variant='contained'
            size='small'
            type='submit'
            component={Link}
            startIcon={<PersonAdd />}
            to={mainNav.addNewPatient}
            sx={{ marginLeft: '8px', height: 40 }}
            onClick={() => {
              trackMixpanelEvent(MixpanelEventName.ADD_PATIENT_NEW_BUTTON_CLICK, {});
            }}
          >
            ADD PATIENTS
          </Button>
        </Box>
      </Grid>

      <Grid container spacing={1} />
    </>
  );
}
