import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as React from 'react';


interface IProps {
  title: string;
  icon?: JSX.Element;
  subtitle?: string;
  subtitileIcon?: JSX.Element
}

export default function SectionHeader({ title, icon, subtitle, subtitileIcon }: IProps) {
  return (
    <Box display='flex' justifyContent='space-between' alignItems='center' mb={3}>
      <Box>
        <Box display='flex' gap={1} alignItems='center'>
          {icon}
          <Typography variant='h4' sx={{ mb: 0 }}>{title}</Typography>
        </Box>
        <Box display='flex' gap={1}>
          {subtitileIcon}
          <Typography variant='body2'>{subtitle}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
