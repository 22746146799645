import { getTickFormat } from 'pages/Dashboard/utils/trackingUtils';
import React from 'react';

function ChartAxisLabel({
  x,
  y,
  payload,
  isInDays,
  index,
  visibleTicksCount,
}: {
  x?: number;
  y?: number;
  payload?: { value: number };
  index?: number;
  visibleTicksCount?: number;
  isInDays: boolean
}) {
  const { day, date } = getTickFormat(payload?.value as number, isInDays);
  const modifyDx = isInDays && (visibleTicksCount ?? 0) < 7;
  const dxForFirst = index === 0 && modifyDx ? '-0.2em' : '';
  const dxForLast = index === ((visibleTicksCount ?? 0) - 1) && modifyDx ? '0.4em' : '';

  return (
    <text x={x} y={y} textAnchor='middle' fontSize={12} fill='#666'>
      {day && (
      <tspan x={x} dx={dxForFirst || dxForLast} dy='0.71em'>
        {day}
      </tspan>
      )}
      {date && (
      <tspan x={x} dx={dxForFirst || dxForLast} dy='1em'>
        {date}
      </tspan>
      )}
    </text>
  );
}

export default ChartAxisLabel;
