import { useState } from 'react';
import { useQuery } from 'react-query';
import { scalesManifestUrls } from 'utils/apiUrls';
import { useHttp } from 'hooks/use-fetch';
import { fetchAttemptDetails } from 'pages/ScalesManifest/services/scales-manifest.services';
import { TAttemptDetails } from 'pages/ScalesManifest/types/scales-manifest.types';

export default function useScales({
  categoryId,
  attemptId,
}: {
  categoryId: string;
  attemptId: string;
}) {
  const { http, providerId } = useHttp();

  const [attemptDetails, setAttemptDetails] = useState<TAttemptDetails>({} as TAttemptDetails);

  const { isFetching, isError, refetch } = useQuery(
    [scalesManifestUrls.listScalesManifest.queryUrl, categoryId, attemptId, providerId],
    {
      queryFn: () => fetchAttemptDetails(http.get, attemptId, categoryId, providerId),
      refetchOnMount: 'always',
      retry: false,
      enabled: !!categoryId && !!attemptId,
      onSuccess: (data) => {
        setAttemptDetails(data);
      },
    },
  );

  return {
    isFetching,
    isError,
    attemptDetails,
    refetch,
  };
}
