export enum MixpanelEventName {
  POINT_BALANCE_ICON_CLICKED = 'Point balance icon clicked',
  WAYS_TO_EARN_TAB_CLICKED = 'Ways to earn tab clicked',
  REWARDS_TAB_CLICKED = 'Rewards tab clicked',
  TRANSACTION_HISTORY_TAB_CLICKED = 'Transaction history tab clicked',
  REFER_A_CLINICIAN_TO_HEADLAMP_LINK_CLICKED = 'Refer a clinician to Headlamp link clicked',
  REDEEM_LINK_CLICKED = 'Email link redeem@headlamp.com clicked',
  FEEDBACK_LINK_CLICKED = 'Email link feedback@headlamp.com clicked',
  SEND_REFERRAL_BUTTON_CLICKED = 'Send Referral button clicked',
  SEND_ANOTHER_REFERRAL_BUTTON_CLICKED = 'Send Another Referral button clicked',
  HEADLAMP_GIVES_BACK_PANEL_CLOSED = 'Headlamp gives back panel closed',
}

export enum Result {
  SUCCESS = 'Success',
  FAIL = 'Fail'
}
