import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { Controller, Control } from 'react-hook-form';


export interface RadioOption {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    label: string;
}

interface RadioInputProps{
    name: string;
    control: Control;
    label: string;
    options: RadioOption[];
    required?: boolean;
}

export default function RadioInput({
  name,
  control,
  label,
  options,
  required,
}: RadioInputProps) {
  const generateRadioOptions = () => options.map((singleOption, idx) => (
    <FormControlLabel
      value={singleOption.value}
      label={singleOption.label}
      control={<Radio required={idx === 0 && required} />}
    />
  ));
  return (
    <FormControl component='fieldset'>
      <FormLabel component='legend'>{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, value },
          fieldState: { error },
        }) => (
          <RadioGroup value={value} onChange={onChange}>
            {generateRadioOptions()}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
}
