import { TimeAudit, UserAudit } from 'types/audit';
import { GoogleDate } from 'utils/dateUtils';

export enum Mood {
  MOOD_UNSPECIFIED = 'MOOD_UNSPECIFIED',
  MOOD_HAPPINESS = 'MOOD_HAPPINESS',
  MOOD_MOTIVATION = 'MOOD_MOTIVATION',
  MOOD_ANXIOUSNESS = 'MOOD_ANXIOUSNESS',
}

export enum MoodV2 {
  MOOD_PLEASANTNESS = 'MOOD_PLEASANTNESS',
  MOOD_ENERGY = 'MOOD_ENERGY',
}

export type MoodOptions = {
  mood: Mood | MoodV2,
  label: string,
  currentValue: number,
  previousValue: number,
}

export type MoodColorType = {
  [key: string] : {positive: string, negative: string}
}

export interface MoodTrackingAverage {
  mood: Mood | MoodV2,
  value: number
}

export interface MoodTrackingList {
  id: string,
  date: GoogleDate,
  mood: Mood,
  notes: string,
  patientId: string,
  timeAudit: TimeAudit,
  userAudit: UserAudit,
  value: number,
}

export interface MoodTrackingAverageResponse {
  moods: MoodTrackingAverage[]
}

export interface MoodTrackingListResponse {
  moods: MoodTrackingList[]
}
