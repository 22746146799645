import React from 'react';
import { PatientObj } from 'pages/Dashboard/types/patient.types';
import { TableCell, TableRow, Typography } from '@mui/material';
import ResendInvite from 'pages/Dashboard/components/ResendInvite';
import { formatPhoneNumber } from 'utils/phoneUtils';
import { capitalize } from 'utils/appUtils';

interface PatientComponentProps {
  patient: PatientObj;
}

function PendingPatientComponent(props: PatientComponentProps) {
  const { patient } = props;
  const isNamePending = !patient?.name?.firstName;

  // Convert phone number to string or display fallback if missing
  const formattedPhoneNumber = formatPhoneNumber(patient.phoneNumber);
  const phoneNumberString = `Pending (${String(
    formattedPhoneNumber || 'No Number Available',
  )})`;
  const nameString = `${capitalize(patient.name?.firstName ?? '')} ${capitalize(patient.name?.lastName ?? '')}`;

  return (
    <TableRow
      sx={{
        '&:hover': {
          backgroundColor: '#f2f2f2',
        },
      }}
    >
      <TableCell
        key={patient.id}
        scope='patient'
        sx={{
          color: 'grey',
          fontWeight: patient.hasRead ? 600 : 400,
          padding: '4px 8px',
          width: 400,
        }}
        color=''
      >
        {isNamePending ? phoneNumberString : nameString}
      </TableCell>
      <TableCell sx={{ padding: '4px 8px', ml: 5 }}>
        <Typography sx={{ ml: 0, textAlign: 'center' }}>{patient.dateAdded ? new Date(patient.dateAdded).toLocaleDateString('en-US') : ''}</Typography>
      </TableCell>
      <TableCell sx={{ padding: '4px 8px' }}>
        <Typography sx={{ ml: 0, textAlign: 'center' }}>
          {patient.inviteDeliveryDate
            ? new Date(patient.inviteDeliveryDate).toLocaleDateString('en-US')
            : ''}
        </Typography>
      </TableCell>
      <TableCell color='grey' sx={{ padding: '10px 4px' }}>
        {patient.onboardingMetadata?.status !== 'PREPARING_INVITE_DELIVERY' ? (
          <ResendInvite patient={patient} />
        ) : (
          <Typography
            sx={{
              color: 'grey',
            }}
          >
            {' '}
            No action available while invite in progress
          </Typography>
        )}
      </TableCell>
    </TableRow>
  );
}

export default PendingPatientComponent;
