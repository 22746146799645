import * as React from 'react';
import RewardsContext, { TComponent } from 'pages/Rewards/context/RewardsContext';
import { ArrowBack, ClearOutlined } from '@mui/icons-material';
import { Grid, IconButton, Typography } from '@mui/material';


interface IHeaderProps {
  title: string;
  showBackIcon?: boolean;
  onClose: (event: React.KeyboardEvent | React.MouseEvent) => void;
}

export default function DrawerHeader({ title, showBackIcon = false, onClose }: IHeaderProps) {
  const { setActiveComponent } = React.useContext(RewardsContext);
  return (
    <Grid container alignItems='center' paddingY={1} paddingX={2}>
      {showBackIcon && (
        <Grid item>
          <IconButton
            onClick={() => setActiveComponent({ component: TComponent.DEFAULT })}
            sx={{ padding: 0, mr: 0.75 }}
            data-testid='drawer-header-back-icon'
          >
            <ArrowBack />
          </IconButton>
        </Grid>
      )}
      <Grid item>
        <Typography variant='h6'>{title}</Typography>
      </Grid>
      <Grid item ml='auto'>
        <IconButton onClick={onClose} sx={{ padding: 0 }} data-testid='drawer-close-btn'>
          <ClearOutlined />
        </IconButton>
      </Grid>
    </Grid>
  );
}
