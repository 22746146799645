import PersonIcon from '@mui/icons-material/Person';
import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import SectionHeader from 'components/Common/SectionHeader';
import AddressSearch from 'pages/Authentication/components/AddressSearch';
import { Address, ResultItem } from 'pages/Authentication/types/AddressSearchAutoComplete';
import usePatient from 'pages/Dashboard/AddPatientContext';
import { PatientContextAction, PatientFormData, PatientGender } from 'pages/Dashboard/types';
import * as React from 'react';
import { formattedDates } from 'utils/dateUtils';
import PracticeInfoForm from 'pages/Dashboard/PracticeInfoForm';
// import { differenceInYears } from 'date-fns';
// import MinorConsentForm from 'pages/Dashboard/components/MinorConsentForm';

export default function AddPatientFormDetails() {
  const { state, dispatch } = usePatient();
  const { formData } = state;

  const defaultAddress: ResultItem = { label: formData.street || '', id: 'defaultId' };
  const [autoAddressField, setAutoAddressField] = React.useState<ResultItem | null>(null);
  // const [showConsentForm, setShowConsentForm] = React.useState<boolean>(false);


  const setFormData = React.useCallback(
    (payload: PatientFormData) => {
      dispatch({
        type: PatientContextAction.SET_FORM_DATA,
        payload: { formData: payload },
      });
    },
    [dispatch],
  );

  function onAddressSelect(address: Address | null, value: ResultItem | null) {
    if (!address) {
      return;
    }

    setAutoAddressField(value);
    dispatch({
      type: PatientContextAction.SET_FORM_DATA,
      payload: {
        formData: {
          ...formData,
          state: address.state?.longName,
          city: address.city?.longName,
          zipCode: address.zipCode?.longName,
          streetAdditional: '',
          street: autoAddressField?.label || '',
        },
      },
    });
  }

  const onDOBChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // const isMinor = differenceInYears(new Date(), new Date(e.target.value)) < 18;

    // setShowConsentForm(isMinor);

    setFormData({ ...formData, dob: e.target.value });
  };

  return (
    <Grid container direction='column' gap={4}>
      <Grid item>
        <PracticeInfoForm setFormData={setFormData} />
      </Grid>
      <Grid item>
        <Grid container direction='column' gap={2}>
          <Grid item>
            <SectionHeader
              title='Patient information'
              icon={<PersonIcon fontSize='large' />}
            />
          </Grid>
          <Grid item>
            <Grid container direction='column' spacing={2}>
              <Grid item xs={12} md={10}>
                <Grid container spacing={2}>
                  <Grid item md={3} xs={12}>
                    <TextField
                      fullWidth
                      label='Legal first name'
                      onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                      required
                      value={formData.firstName || ''}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <TextField
                      fullWidth
                      label='Legal last name'
                      onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                      required
                      value={formData.lastName || ''}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      label='Preferred name (optional)'
                      onChange={(e) => setFormData({ ...formData, preferredName: e.target.value })}
                      value={formData.preferredName || ''}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={2}>
                    <TextField
                      fullWidth
                      label='Date of birth'
                      inputProps={{ max: formattedDates.today, type: 'date' }}
                      InputLabelProps={{ shrink: true }}
                      onChange={onDOBChange}
                      required
                      value={formData.dob || ''}
                    />
                  </Grid>
                  {/* {
                    showConsentForm && (
                    <Grid item md={12}>
                      <MinorConsentForm
                        checked={formData.consent}
                        onChange={(e) => setFormData({
                          ...formData,
                          consent: e.target.checked,
                        })}
                      />
                    </Grid>
                    )
                  } */}
                </Grid>
              </Grid>
              <Grid item xs={12} md={8}>
                <Grid container>
                  <FormControl>
                    <FormLabel>Sex assigned at birth</FormLabel>
                    <RadioGroup
                      name='radio-buttons-group'
                      onChange={(e) => setFormData({ ...formData, gender: e.target.value })}
                      value={formData.gender || ''}
                    >
                      <FormControlLabel
                        value={PatientGender.MALE}
                        control={<Radio required />}
                        label='Male'
                      />
                      <FormControlLabel value={PatientGender.FEMALE} control={<Radio />} label='Female' />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12} md={8}>
                <Grid container>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      helperText='No dashes. Digits only.'
                      inputProps={{ max: 9999999999, min: 1000000000, type: 'number' }}
                      label='Mobile phone number'
                      onChange={(e) => setFormData({ ...formData, mobile: e.target.value })}
                      required
                      value={formData.mobile || ''}
                    />
                  </Grid>
                  <Grid item>
                    <Typography color='GrayText' variant='body2'>
                      Just a reminder that you should make sure that you have obtained patient
                      permission toreceive SMS messages from business associates including Headlamp,
                      which is likely already captured in your clinic intake forms. The patient will
                      be sent a link to enter information needed to create their Headlamp account.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      label='Email address'
                      onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                      required
                      type='email'
                      value={formData.email || ''}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={8}>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <AddressSearch
                      inputLabel='Patient address'
                      addressValue={autoAddressField || defaultAddress}
                      onAddressSelect={onAddressSelect}
                      onInputChange={(val) => setFormData({ ...formData, street: val })}
                      isRequired
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={8}>
                <Grid container>
                  <Grid item xs={12} md={2}>
                    <TextField
                      fullWidth
                      label='Ste, bldg, apt, etc.'
                      onChange={(e) => setFormData(
                        { ...formData, streetAdditional: e.target.value },
                      )}
                      value={formData.streetAdditional || ''}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={8}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={2}>
                    <TextField
                      fullWidth
                      required
                      label='City'
                      onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                      value={formData.city || ''}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      fullWidth
                      label='State'
                      onChange={(e) => setFormData({ ...formData, state: e.target.value })}
                      value={formData.state || ''}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      fullWidth
                      required
                      label='ZIP'
                      onChange={(e) => setFormData({ ...formData, zipCode: e.target.value })}
                      value={formData.zipCode || ''}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={8}>
                <Grid container>
                  <Grid item xs={12} md={2}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      inputProps={{ min: formattedDates.today, type: 'date' }}
                      label='Date of next appointment'
                      onChange={(e) => setFormData(
                        { ...formData, nextAppointmentDate: e.target.value },
                      )}
                      required
                      value={formData.nextAppointmentDate || ''}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
