import React, { useEffect } from 'react';
import { TextField } from '@mui/material';
import { useMutation } from 'react-query';
import { addNotes } from 'pages/ScalesManifest/services/scales-manifest.services';
import { useHttp } from 'hooks/use-fetch';
import { Dialog } from 'components/Common/Dialog';
import { trackMixpanelEvent } from 'utils/utilMethods';
import { MixpanelEventName } from 'utils/constants';
import Theme from 'theme';

interface AddNotesDialogProps {
  open: boolean;
  attemptId: string;
  categoryId: string;
  note?: string;
  onClose: (shouldRefetch?: boolean) => void;
}

export function AddNotesDialog({
  open,
  attemptId,
  categoryId,
  note: initialNotes,
  onClose,
}: AddNotesDialogProps): JSX.Element {
  const { http, providerId } = useHttp();
  const [note, setNote] = React.useState('');

  useEffect(() => {
    if (open) setNote(initialNotes ?? '');
  }, [open, initialNotes]);

  const resetAndClose = (shouldRefetch?: boolean) => {
    onClose(shouldRefetch);
  };

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: () => addNotes(http.post, attemptId, categoryId, note, providerId),
    onSettled: () => {
      resetAndClose(true);
    },
  });

  const onAdd = async () => {
    trackMixpanelEvent(MixpanelEventName.ADDED_NOTE_ON_SCALE, {
      scale: attemptId,
    });
    mutateAsync();
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    value.length <= 1000 ? setNote(value) : setNote(value.slice(0, 1000));
  };

  return (
    <Dialog
      open={open}
      size='md'
      title={`${initialNotes ? 'Edit' : 'Add'} note`}
      actionBtnLabel={initialNotes ? 'SAVE' : 'ADD'}
      actionBtnDisabled={(!initialNotes && !note) || note.length > 1000}
      actionBtnLoading={isLoading}
      actionBtnHandler={onAdd}
      closeBtnLabel='CANCEL'
      onClose={() => resetAndClose()}
      renderContent={() => (
        <TextField
          helperText={`${note.length} / 1000`}
          FormHelperTextProps={{
            sx: {
              textAlign: 'right',
              ...(note.length > 1000 ? { color: Theme.custom.colors.lightErrorMain } : {}),
            },
          }}
          multiline
          rows={6}
          fullWidth
          variant='outlined'
          placeholder='Enter your note here'
          value={note}
          onChange={onChange}
        />
      )}
    />
  );
}

export default AddNotesDialog;
