import { useAuth0 } from '@auth0/auth0-react';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Spinner from 'components/Common/Spinner';
import LoginForm from 'pages/Authentication/components/LoginForm';
import * as React from 'react';
import { Link as RouterLink, Navigate } from 'react-router-dom';
import { auth, landing } from 'utils/spaUrls';


export default function Login() {
  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading) {
    return <Spinner />;
  }
  else if (isAuthenticated) {
    return <Navigate to={landing} />;
  }
  return (
    <Grid container direction='column' alignContent='center' spacing={2} textAlign='center'>
      <Grid item xs={12} sx={{ border: '1px grey solid', padding: 2 }}>
        <LoginForm />
      </Grid>
      <Grid item xs={4}>
        <Typography color='dimgrey'>New To Headlamp?</Typography>
      </Grid>
      <Grid item xs={4}>
        <Link component={RouterLink} to={auth.signup} underline='hover'>Create An Account</Link>
      </Grid>
    </Grid>
  );
}
