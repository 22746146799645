import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box, CircularProgress } from '@mui/material';

interface IProps {
  isFetching: boolean;
  isError: boolean;
  minHeight?: string | number;
  noDataText?: string;
  children: React.ReactElement;
  fullHeight?: boolean;
}

export default function ContentLoader({
  isFetching,
  isError,
  minHeight,
  noDataText,
  children,
  fullHeight,
}: IProps) {
  const renderComponent = React.useMemo(() => {
    if (isFetching) return <CircularProgress />;
    if (isError) return <Typography color='error'>Something went wrong!</Typography>;
    if (noDataText) return <Typography>{noDataText}</Typography>;
    return null;
  }, [isFetching, isError, noDataText]);

  if (renderComponent) {
    return (
      <Box display='flex' alignItems='center' justifyContent='center' minHeight={minHeight} width='100%' {...(fullHeight ? { flex: 1 } : {})}>
        {renderComponent}
      </Box>
    );
  }

  return children;
}
