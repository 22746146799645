import { SvgIconTypeMap, CardHeader as MuiCardHeader } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import React from 'react';

interface IProps {
  icon: OverridableComponent<SvgIconTypeMap> & { muiName: string; };
  title: string;
}

export default function CardHeader({ icon, title }: IProps) {
  return (
    <MuiCardHeader
      avatar={React.createElement(icon, { fontSize: 'large' })}
      title={title}
      titleTypographyProps={{ variant: 'h5', fontSize: 24, fontWeight: 400 }}
    />
  );
}
