import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  Typography,
  useTheme,
} from '@mui/material';
import Spinner from 'components/Common/Spinner';
import mixpanel from 'mixpanel-browser';
import useConsent from 'hooks/use-consent';
import { MixpanelEventName } from 'pages/Dashboard/types/mixpanel.types';
import { useHttp } from 'hooks/use-fetch';

const trackMixpanelEvent = (eventName: MixpanelEventName, payload?: { [key: string]: string }) => {
  mixpanel.track(eventName, { 'datetime clicked': new Date().toISOString(), ...payload });
};

const consentText = (
  <Typography variant='body1'>
    I have read and agree to the
    {' '}
    <Link
      href='https://www.headlamp.com/terms'
      target='_blank'
      underline='hover'
      onClick={() => trackMixpanelEvent(MixpanelEventName.TERMS_OF_SERVICE_LINK_CLICKED)}
    >
      Terms of Service
    </Link>
    ,
    {' '}
    <Link
      href='https://www.headlamp.com/baa'
      target='_blank'
      underline='hover'
      onClick={() => trackMixpanelEvent(MixpanelEventName.BAA_LINK_CLICKED)}
    >
      Business Associate Agreement
    </Link>
    , and
    {' '}
    <Link
      href='https://www.headlamp.com/privacy'
      target='_blank'
      underline='hover'
      onClick={() => trackMixpanelEvent(MixpanelEventName.PRIVACY_POLICY_LINK_CLICKED)}
    >
      Privacy Policy
    </Link>
  </Typography>
);
interface ConsentFormProps {
  children: React.ReactNode;
}

function ConsentForm({ children }: ConsentFormProps): JSX.Element {
  const { superAdminId, providerId } = useHttp();
  const [isConsentGiven, setIsConsentGiven] = useState(false);
  const [isError, setIsError] = useState(false);
  const theme = useTheme();

  const isViaSuperAdminRoute = !!superAdminId && superAdminId !== providerId;

  const { isFetching, isLoading, hasConsent, giveConsent } = useConsent();

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsError(!event.target.checked);
    setIsConsentGiven(event.target.checked);
  };

  const handleContinueClick = () => {
    if (isConsentGiven) {
      setIsError(false);
      giveConsent({ date: new Date().toISOString() });
    }
    else {
      setIsError(true);
    }
    trackMixpanelEvent(MixpanelEventName.TERMS_AND_PRIVACY_CONTINUE_BUTTON_CLICKED, {
      'checkbox checked': isConsentGiven ? 'yes' : 'no',
    });
  };

  if (isFetching || isLoading) {
    return <Spinner testId='spinner' />;
  }

  if (hasConsent || isViaSuperAdminRoute) {
    return children as JSX.Element;
  }

  return (
    <Grid
      container
      flexDirection='column'
      maxWidth={theme.breakpoints.values.lg}
      marginX='auto'
      padding={3}
    >
      <Grid xs={12} item>
        <Typography variant='h2' fontSize={34} fontWeight={400} marginBottom={3}>
          Terms & privacy
        </Typography>
      </Grid>
      <Grid item marginBottom={3}>
        <FormControl error={isError}>
          <FormControlLabel
            data-testid='consent-checkbox'
            control={(
              <Checkbox
                checked={isConsentGiven}
                onChange={handleCheckboxChange}
                color='primary'
                id='consent-checkbox'
              />
            )}
            label={consentText}
          />
          {isError && (
            <FormHelperText component='div'>
              <Typography variant='body2'>
                Agreeing to the Terms of Service, Business Associate Agreement, and Privacy Policy
                is required to continue.
              </Typography>
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid item>
        <Button variant='contained' onClick={handleContinueClick} size='large'>
          CONTINUE
        </Button>
      </Grid>
    </Grid>
  );
}

export default ConsentForm;
