import {
  ReducerState,
  PatientAction,
  PatientContextValues,
  PatientContextAction,
  PatientForm,
} from 'pages/Dashboard/types';
import * as React from 'react';

export const patientContextInitialState: ReducerState = {
  formData: {},
  waypoints: [],
  patientList: [],
};

export const patientFormInitialState: PatientForm = {
  practiceId: '',
  patients: [{ email: '', mobile: '' }],
  firstName: '',
  lastName: '',
  preferredName: '',
  email: '',
  dob: '',
  gender: '',
  mobile: '',
  street: '',
  streetAdditional: '',
  city: '',
  state: '',
  zipCode: '',
  nextAppointmentDate: '',
  providerId: '',
};

export const patientReducer: React.Reducer<ReducerState, PatientAction> = (state, action) => {
  const { type, payload } = action;
  const waypoints = payload?.waypoints || [];

  switch (type) {
    case PatientContextAction.SET_FORM_DATA:
      return {
        ...state,
        formData: { ...state.formData, ...(payload?.formData ?? {}) },
      };
    case PatientContextAction.RESET_FORM_DATA:
      return {
        ...state,
        formData: {},
        waypoints: [],
      };
    case PatientContextAction.SET_WAYPOINTS:
      return {
        ...state,
        waypoints: [...waypoints],
      };
    case PatientContextAction.SET_PATIENT_LIST:
      return {
        ...state,
        patientList: payload?.patientList ?? [],
      };
    default:
      throw new Error('type not found.');
  }
};

export const PatientContext = React.createContext<PatientContextValues>({
  state: patientContextInitialState,
  dispatch: () => null,
  addingPatient: false,
  practicesList: null,
});

const usePatient = () => {
  const context = React.useContext(PatientContext);

  if (context === undefined) {
    throw new Error('Context not found ');
  }

  return context;
};

export default usePatient;
