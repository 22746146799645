import { Typography } from '@mui/material';
import { getTickFormat } from 'pages/Dashboard/utils/trackingUtils';
import React from 'react';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';

type AppleHealthChartTooltipProps = {
  isInDays: boolean;
  data: { key: string, label: string, color: string, units: string, value: number }[];
  label?: number;
  payload?: Payload<string | number | (string | number)[], string | number>[] | undefined;
  active?: boolean;
};

function AppleHealthChartTooltip({
  active,
  data,
  payload,
  label,
  isInDays,
}: AppleHealthChartTooltipProps) {
  if (active && payload && payload.length) {
    const { day, date, year } = getTickFormat(label as number, isInDays, 'MMM d');
    const finalLabel = isInDays ? `${day}, ${date}` : `${date}, ${year}`;

    return (
      <div
        style={{
          background: 'white',
          border: '1px solid #f5f5f5',
          padding: '10px',
          borderRadius: '5px',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography>{finalLabel}</Typography>
        {data.map((bar) => {
          if (bar.value > 0) {
            return (
              <Typography color={bar.color} key={bar.key} marginTop={2}>
                <Typography>{bar.label}</Typography>
                <Typography display='flex'>
                  <Typography variant='h6' lineHeight='100%'>
                    {bar.value}
                  </Typography>
                  {bar.units?.toLowerCase()}
                </Typography>
              </Typography>
            );
          }
          return null;
        })}
      </div>
    );
  }

  return null;
}

export default AppleHealthChartTooltip;
