import * as React from 'react';
import { Skeleton, TableCell } from '@mui/material';
import { useQuery } from 'react-query';
import { useHttp } from 'hooks/use-fetch';
import { useParams } from 'react-router-dom';
import { fetchWhoIamCategoryResults } from 'pages/Dashboard/services/whoiam.services';
import { whoIam } from 'utils/apiUrls';
import QualityOfLifeText from 'pages/Dashboard/components/QualityOfLifeText';

interface PatientQualityOfLifeProps {
  id: string;
}

export default function PatientQualityOfLife({
  id,
}: PatientQualityOfLifeProps): JSX.Element {
  const { http, providerId } = useHttp();
  const { id: patienId } = useParams();

  const qualityOfLife = useQuery(
    [`${whoIam.categoryResults.queryUrl}-${id}`, providerId, patienId, id],
    {
      queryFn: fetchWhoIamCategoryResults(http.get),
    },
  );

  if (qualityOfLife.isFetching) {
    return (
      <TableCell>
        <Skeleton />
      </TableCell>
    );
  }

  const result = qualityOfLife?.data?.results[0];

  return (
    <QualityOfLifeText result={result} />
  );
}
