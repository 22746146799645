import { Box, Grid, Typography } from '@mui/material';
import AppleHealthChartTooltip from 'pages/Dashboard/components/AppleHealthChartTooltip';
import ChartAxisLabel from 'pages/Dashboard/components/ChartAxisLabel';
import { WaypointTracking } from 'pages/Dashboard/types/waypoints.types';
import React, { useMemo, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  Text,
  YAxis,
  Cell,
} from 'recharts';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';
import Theme from 'theme';
import { getStartOfDayLocal } from 'utils/dateUtils';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const activityBarsMap: Record<
  string,
  {
    key: string;
    goalKey: string;
    color: string;
    normalColor: string;
    label: string;
    count: number;
    units: string;
    goalReachedCount: number;
  }
> = {
  activeEnergyBurned: {
    key: 'activeEnergyBurned',
    goalKey: 'activeEnergyBurnedGoal',
    color: '#CC3B3B',
    normalColor: '#ECBBBB',
    label: 'Move',
    count: 0,
    units: 'cal',
    goalReachedCount: 0,
  },
  appleExerciseTime: {
    key: 'appleExerciseTime',
    goalKey: 'appleExerciseTimeGoal',
    color: '#4A7F4B',
    normalColor: '#B8E3B9',
    label: 'Exercise',
    count: 0,
    units: 'mins',
    goalReachedCount: 0,
  },
  appleStandHours: {
    key: 'appleStandHours',
    goalKey: 'appleStandHoursGoal',
    color: '#39AEBE',
    normalColor: '#A5DCE4',
    label: 'Stand',
    count: 0,
    units: 'hrs',
    goalReachedCount: 0,
  },
};

type CustomTooltipProps = {
  isInDays: boolean;
  label?: number;
  payload?: Payload<string | number | (string | number)[], string | number>[] | undefined;
  active?: boolean;
};

function CustomTooltip({ active, payload, label, isInDays }: CustomTooltipProps) {
  const data = Object.values(activityBarsMap).map((bar) => ({
    key: bar.key,
    label: bar.label,
    color: bar.color,
    units: bar.units,
    value: payload?.[0]?.payload?.[bar.key] ?? 0,
  }));

  return (
    <AppleHealthChartTooltip
      data={data}
      isInDays={isInDays}
      active={active}
      label={label}
      payload={payload}
    />
  );
}
interface AppleHealthActivityChartProps {
  chartData: WaypointTracking[];
  chartGoals: Record<string, string>[];
  yAxisProps: {
    startTime: number;
    endTime: number;
    ticks: number[];
    isInDays: boolean;
  };
  barSize: number;
  averages: { key: string; value: string }[];
  timeFrame: string;
  previousPeriodAverages: { key: string; value: string }[];
}

function AppleHealthActivityChart({
  chartData,
  chartGoals,
  yAxisProps: { ticks, startTime, endTime, isInDays },
  barSize,
  averages,
  timeFrame,
  previousPeriodAverages,
}: AppleHealthActivityChartProps) {
  const [focusBar, setFocusBar] = useState(-1);
  const goals = React.useMemo(() => chartGoals?.reduce((a, g) => {
    if (g?.key && g?.value) {
      const intValue = !Number.isNaN(parseInt(g.value, 10)) ? parseInt(g.value, 10) : 0;
      return { ...a, [g.key]: intValue };
    }
    return a;
  }, {} as Record<string, number>), [chartGoals]);

  const data = React.useMemo(() => {
    Object.keys(activityBarsMap).forEach((key) => {
      activityBarsMap[key].count = 0;
      activityBarsMap[key].goalReachedCount = 0;
    });
    return chartData?.map((w) => {
      const date = getStartOfDayLocal((w?.timestamp as number) * 1000, isInDays);
      const metadata = w?.metadata?.reduce((a, m) => {
        if (m?.key && m?.value) {
          const intValue = !Number.isNaN(parseInt(m.value, 10)) ? parseInt(m.value, 10) : 0;
          if (activityBarsMap[m.key]) {
            activityBarsMap[m.key].count += 1;
            if (
              !!goals[activityBarsMap[m.key]?.goalKey]
              && intValue > goals[activityBarsMap[m.key]?.goalKey]
            ) {
              activityBarsMap[m.key].goalReachedCount += 1;
            }
          }
          return { ...a, [m.key]: intValue };
        }
        return a;
      }, {} as Record<string, string | number>);

      return {
        date,
        ...metadata,
      };
    });
  }, [chartData, goals, isInDays]);

  const averageMap = averages.reduce(
    (a, avg) => {
      if (avg?.key) {
        return {
          ...a,
          [avg.key]: Math.round(+avg.value),
        };
      }
      return a;
    },
   {} as Record<string, number>,
  );

  const previousPeriodAverageMap = previousPeriodAverages.reduce(
    (a, avg) => {
      if (avg?.key) {
        return {
          ...a,
          [avg.key]: Math.round(+avg.value),
        };
      }
      return a;
    },
    {} as Record<string, number>,
  );

  const priorAvgText = useMemo(() => `prior ${timeFrame.replace(' ', '-').replace('s', '')} avg.`, [timeFrame]);
  const averageCategories = Object.values(activityBarsMap);

  return (
    <Box flex={1} display='flex' flexDirection='column'>
      <Grid container>
        {averageCategories.map((bar, index) => {
          const isUp = averageMap[bar.key]
          && previousPeriodAverageMap[bar.key]
          && averageMap[bar.key] !== previousPeriodAverageMap[bar.key]
            ? averageMap[bar.key] > previousPeriodAverageMap[bar.key]
            : undefined;

          const ArrowIcon = isUp
            ? ArrowDropUpIcon
            : ArrowDropDownIcon;
          return (
            <Grid item key={bar.goalKey} borderLeft={index > 0 ? '1px solid #0000001F' : ''} mb={2}>
              <Box pl={index > 0 ? 1.5 : 0} pr={index < averageCategories.length ? 1 : 0}>
                <Typography variant='body1' fontWeight='700' color={bar.color}>
                  {bar.label}
                </Typography>
                <Box display='flex' alignItems='center' height={20} mb={2}>
                  {averageMap[bar.key] > 0 ? (
                    <Box display='flex' alignItems='flex-end'>
                      <Typography variant='body1' fontSize={20} fontWeight={700} lineHeight='100%' mr={0.5}>
                        {averageMap[bar.key] ?? 0}
                      </Typography>
                      <Typography lineHeight='100%' color={Theme.custom.colors.lightTextSecondary}>{bar.units}</Typography>
                    </Box>
                  ) : '-'}
                  {isUp !== undefined && <ArrowIcon color='action' />}
                </Box>
                <Box>
                  <Typography fontSize={12} color={Theme.custom.colors.lightTextSecondary} mb={0.5} textAlign='center'>
                    {priorAvgText}
                  </Typography>
                  <Typography display='flex' alignItems='baseline'>
                    <Typography lineHeight='100%' mr={0.5} fontWeight={400}>
                      {previousPeriodAverageMap[bar.key] || '-'}
                    </Typography>
                    {previousPeriodAverageMap[bar.key] > 0 && <Typography variant='body2' lineHeight='100%' color={Theme.custom.colors.lightTextSecondary}>{bar.units}</Typography>}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <Box marginTop='auto'>
        {Object.values(activityBarsMap).map((bar, index) => {
          const goal = `${bar.goalReachedCount} out of ${bar.count}`;
          return (
            <ResponsiveContainer width='100%' height={index === 2 ? 115 : 90} key={bar.key}>
              <BarChart
                data={data}
                onMouseMove={(state) => {
                  setFocusBar(state?.isTooltipActive ? state?.activeTooltipIndex ?? -1 : -1);
                }}
                onMouseLeave={() => {
                  setFocusBar(-1);
                }}
                margin={{ left: 5, bottom: 0, top: 0, right: 5 }}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <Tooltip
                  content={<CustomTooltip isInDays={isInDays} />}
                  wrapperStyle={{ zIndex: 1 }}
                  position={{ y: -50 }}
                  cursor={false}
                />
                <XAxis
                  dataKey='date'
                  type='number'
                  domain={[startTime, endTime]}
                  tick={<ChartAxisLabel isInDays={isInDays} />}
                  tickFormatter={() => ''}
                  ticks={ticks}
                  height={index === 2 ? 25 : 0}
                  tickLine={false}
                  tickSize={2}
                  fontSize={12}
                />
                <YAxis
                  hide
                  domain={[
                    0,
                    (dataMax: number) => {
                      const max = Number.isNaN(dataMax) ? 0 : dataMax;
                      const goalMax = goals[bar.goalKey] ?? 0;
                      const actualMax = max > goalMax * 2 ? max : goalMax * 2;
                      return actualMax;
                    },
                  ]}
                  tickCount={0}
                />
                <Legend
                  verticalAlign='top'
                  content={(
                    <div
                      style={{
                        justifyContent: 'space-between',
                        fontSize: 12,
                        display: 'flex',
                        width: '100%',
                        alignItems: 'flex-end',
                        marginTop: 12,
                      }}
                    >
                      <Text style={{ color: bar.color, fontWeight: 'bold' }}>{bar.label}</Text>
                      {goals[bar.goalKey] > 0 && chartData.length > 0 && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'flex-end',
                        }}
                      >
                        <Text style={{ color: bar.color, marginRight: 6, fontWeight: 'bold' }}>
                          {`- - - Goal ${goals[bar.goalKey]} ${bar.units}`}
                        </Text>
                        <Text textAnchor='end'>{goal}</Text>
                      </div>
                      )}
                    </div>
                    )}
                />
                <Bar
                  dataKey={bar.key}
                  fill={bar.color}
                  barSize={barSize}
                  radius={[3, 3, 0, 0]}
                >
                  {data.map((entry: Record<string, string | number>, cIndex) => {
                    const value = entry[bar.key] as number;
                    const color = value > goals[bar.goalKey] ? bar.color : bar.normalColor;
                    return (
                      <Cell
                        key={entry?.date}
                        fill={focusBar !== cIndex ? color : `${Theme.custom.colors.primaryMain}aa`}
                      />
                    );
                  })}
                </Bar>
                {goals[bar.goalKey] && (
                  <ReferenceLine
                    y={goals[bar.goalKey]}
                    stroke={bar.color}
                    isFront
                    strokeDasharray='5 5'
                  />
                )}
              </BarChart>
            </ResponsiveContainer>
          );
        })}
      </Box>
    </Box>
  );
}

export default AppleHealthActivityChart;
