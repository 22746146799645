import * as React from 'react';
import { TableCell, TableRow, Typography } from '@mui/material';
import { EducationFormatter, RelationFormatter } from 'pages/Dashboard/services/lifestyle.services';
import { EducationLevel, PatientRelationshipCategory } from 'pages/Dashboard/types/background.types';

export default function NoBackgroundData() {
  return (
    <>
      <TableRow>
        <TableCell>
          <Typography>
            Relationship
          </Typography>
        </TableCell>
        <TableCell>
          <Typography>
            {RelationFormatter(
              PatientRelationshipCategory.PATIENT_RELATIONSHIP_CATEGORY_UNSPECIFIED,
            )}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <Typography>
            Education
          </Typography>
        </TableCell>
        <TableCell>
          <Typography>
            {EducationFormatter(
              EducationLevel.EDUCATION_LEVEL_UNSPECIFIED,
            )}
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
}
