import { Icon, Tooltip } from '@mui/material';
import React from 'react';
import theme from 'theme';

function AppleHealthTooltip({
  text,
  iconType,
  icon,
}: {
  text: React.ReactElement | string;
  iconType?: string;
  icon?: React.ReactElement;
}) {
  return (
    <Tooltip
      title={text}
      arrow
      placement='top'
      componentsProps={{
        tooltip: {
          sx: {
            boxShadow: '0px 5px 8px 0px rgba(0, 0, 0, 0.25)',
            maxWidth: 400,
            bgcolor: '#F1F1F1',
            color: 'black',
            '& .MuiTooltip-arrow': {
              color: '#F1F1F1',
            },
          },
        },
      }}
    >
      {icon || (
      <Icon fontSize='medium' sx={{ color: theme.custom.colors.primaryMain }}>
        {iconType}
      </Icon>
      )}
    </Tooltip>
  );
}

export default AppleHealthTooltip;
