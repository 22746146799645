export enum ONBOARDING_STATUS {
  PENDING_INVITE_DELIVERY = 'PENDING_INVITE_DELIVERY', // Email / SMS is sent but not recieved yet
  PENDING_PATIENT_SIGNUP = 'PENDING_PATIENT_SIGNUP', // Email / SMS is sent and received but the patient hasn't tapped on the invite link yet
  ONBOARDING_STARTED = 'ONBOARDING_STARTED', // Email / SMS is sent and received and patients has started the onboarding process
  DELIVERY_FAILED = 'INVITE_DELIVERY_FAILED', // Email / SMS sending failed
  PREPARING_INVITE_DELIVERY = 'PREPARING_INVITE_DELIVERY', // Email / SMS are not even sent yet
  SUCCESS = 'SUCCESS',
  CANCELLED = 'CANCELLED',
}

export enum PATIENT_LIST_VIEW {
  READY = 'READY',
  PENDING = 'PENDING',
  ERRORS = 'ERRORS',
}

export enum INVITE_DRAWER_TYPE {
  RESEND_INVITE = 'Resend Invite',
  RESOLVE_INVITE = 'Resolve Error'
}

export enum EDIT_WAYPOINT_SUGGESTIONS_VIEW {
  SEND_WAYPOINT_SUGGESTIONS = 'SEND_WAYPOINT_SUGGESTIONS',
  REVOKE_WAYPOINT_SUGGESTIONS = 'REVOKE_WAYPOINT_SUGGESTIONS'
}

export enum ACTION_CONFIRMATION_VIEW {
  DEFAULT = 'DEFAULT',
  CONFIRMATION = 'CONFIRMATION',
  SUCCESS = 'SUCCESS'
}
