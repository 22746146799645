import { Stack } from '@mui/material';
import AddCredentialsForm from 'pages/Admin/AddCredentialsForm';
import * as React from 'react';
import { ConnectionCredentials, Practice } from 'types/Entitites';


interface AddCredentialsProps{
  credentials: ConnectionCredentials[],
  setCredentialData: (e: ConnectionCredentials[]) => void,
  practice: Practice,
  connectionId: string
}

export default function PracticeConnectionCredentials(
  { credentials, setCredentialData, practice, connectionId } : AddCredentialsProps,
) {
  const setCredentialList = (credential: ConnectionCredentials, idx: number) => {
    credentials.splice(idx, 1, credential);
    setCredentialData([
      ...credentials,
    ]);
  };

  return (
    <form>
      <Stack spacing={2} mx={2}>
        {
          credentials.map((credential, idx) => (
            <AddCredentialsForm
              key={`${credential.name}-${practice.id}-${connectionId}`}
              idx={idx}
              credential={credential}
              setCredentialList={setCredentialList}
            />
          ))
        }
      </Stack>
    </form>
  );
}
