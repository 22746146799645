import { useAuth0 } from '@auth0/auth0-react';
import Spinner from 'components/Common/Spinner';
import { useHttp } from 'hooks/use-fetch';
import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { UserRole } from 'types/NavTreeModel';
import { auth } from 'utils/spaUrls';

export default function PrivateRoute({
  outlet,
  roles,
}: {
  outlet: JSX.Element;
  roles: UserRole[];
}) {
  const { isAuthenticated, isLoading } = useAuth0();
  const { providerId, userRoles } = useHttp();

  const isAuthorized = roles.some((role) => userRoles[role]);

  if (isLoading) {
    return <Spinner />;
  }
  else if (isAuthenticated && !providerId) {
    return <Spinner />;
  }
  else if (isAuthenticated && isAuthorized) {
    return outlet;
  }
  else {
    return <Navigate to={auth.login} />;
  }
}
