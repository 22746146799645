import { Box, Typography } from '@mui/material';
import React from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AppleHealthTooltip from 'components/Common/AppleHealthTooltip';

function AppleHealthIcon() {
  return (
    <AppleHealthTooltip
      text={<Typography variant='body2'>Data from Apple Health</Typography>}
      icon={(
        <Box width={24} height={24} bgcolor='white' borderRadius={1} border='1px solid #00000033' position='relative'>
          {/* TODO: Fix border color */}
          <FavoriteIcon color='error' sx={{ fontSize: 12, position: 'absolute', right: 2, top: 2 }} />
        </Box>
)}
    />
  );
}

export default AppleHealthIcon;
