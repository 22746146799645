import { useQuery } from 'react-query';
import { useHttp } from 'hooks/use-fetch';
import { rewardsUrls } from 'utils/apiUrls';
import { useMemo } from 'react';
import fetchRewardPoints from 'pages/Rewards/services/reward-points.services';
import fetchRewards from 'pages/Rewards/services/rewards.services';
import fetchExplorerActions from 'pages/Rewards/services/explorer-actions.services';
import fetchTransactions from 'pages/Rewards/services/transactions.services';

interface IProps {
  isOpen: boolean;
}

export default function useRewards({ isOpen }: IProps) {
  const { http, providerId } = useHttp();

  const {
    data: rewardPointsData,
    isError: isRewardPointsError,
    isFetching: rewardPointsFetching,
  } = useQuery([rewardsUrls.rewardPoints.queryUrl, providerId], {
    queryFn: fetchRewardPoints(http.get),
    enabled: !!providerId,
    retry: 2,
  });

  const {
    data: rewardsData,
    isError: isRewardsError,
    isFetching: rewardsFetching,
  } = useQuery([rewardsUrls.rewards.queryUrl, providerId], {
    queryFn: fetchRewards(http.get),
    enabled: isOpen && !!providerId,
    retry: 2,
  });

  const {
    data: explorerActionsData,
    isError: isExplorerActionsError,
    isFetching: explorerActionsFetching,
  } = useQuery([rewardsUrls.explorerActions.queryUrl, providerId], {
    queryFn: fetchExplorerActions(http.get),
    enabled: isOpen && !!providerId,
    retry: 2,
  });

  const {
    data: transactionsData,
    isError: isTransactionsError,
    isFetching: transactionsFetching,
  } = useQuery([rewardsUrls.transactions.queryUrl, providerId], {
    queryFn: fetchTransactions(http.get),
    enabled: isOpen && !!providerId,
    retry: 2,
  });

  const rewardPoints = useMemo(
    () => rewardPointsData?.data[0]?.attributes?.Points,
    [rewardPointsData],
  );

  const isEnabled = useMemo(
    () => rewardPointsData?.data[0]?.attributes?.enrolled,
    [rewardPointsData],
  );

  const rewards = useMemo(
    () => rewardsData?.data?.map((reward) => ({
      title: reward.attributes.Title,
      description: reward.attributes.Description,
      points: reward.attributes.PointsRequired,
      id: reward.id,
      identifier: reward.attributes.identifier,
    })),
    [rewardsData],
  );

  const explorerActions = useMemo(
    () => explorerActionsData?.data?.map((explorerAction) => ({
      title: explorerAction.attributes.Title,
      description: explorerAction.attributes.Description,
      points: explorerAction.attributes.Points,
      id: explorerAction.id,
      identifier: explorerAction.attributes.identifier,
    })),
    [explorerActionsData],
  );

  const transactions = useMemo(
    () => transactionsData?.data?.map((transaction) => ({
      type: transaction.attributes.explorer_action?.data
        ? 'added'
        : ('redeemed' as 'redeemed' | 'added'),
      points: transaction.attributes.Points,
      date: transaction.attributes.date,
      title:
          transaction.attributes.explorer_action?.data?.attributes?.Title
          || transaction.attributes.reward?.data?.attributes?.Title,
      id: transaction.id,
    })),
    [transactionsData],
  );

  return {
    explorerActions,
    explorerActionsFetching,
    isExplorerActionsError,
    isRewardPointsError,
    isRewardsError,
    isTransactionsError,
    rewardPoints,
    rewardPointsFetching,
    rewards,
    rewardsFetching,
    transactions,
    transactionsFetching,
    isEnabled,
  };
}
