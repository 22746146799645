import React, { memo } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { PATIENT_LIST_VIEW } from 'pages/Dashboard/enums';
import { PatientObj } from 'pages/Dashboard/types/patient.types';
import { Table, TableBody, TableContainer, TableHead } from '@mui/material';
import SortableTableHeader from 'pages/Dashboard/components/SortableTableHeader';
import ReadyPatientComponent from 'pages/Dashboard/components/ReadyPatientRow';
import PendingPatientComponent from 'pages/Dashboard/components/PendingPatientRow';
import ErrorPatientComponent from 'pages/Dashboard/components/ErrorPatientRow';
import ContentLoader from 'components/Common/ContentLoader';

const ComponentMap: {
  [key: string]: React.FC<{
    patient: PatientObj;
    showProvider: boolean;
    onAccordionChange: (patientId: string) => void;
    expanded: boolean;
  }>;
} = {
  READY: ReadyPatientComponent,
  PENDING: PendingPatientComponent,
  ERRORS: ErrorPatientComponent,
};

const emptyListTextMap: {
  [key in PATIENT_LIST_VIEW]: { text: string; buttonText: string; goToFilter: PATIENT_LIST_VIEW };
} = {
  [PATIENT_LIST_VIEW.READY]: {
    text: 'There are no ready patients.',
    buttonText: 'VIEW PENDING PATIENTS',
    goToFilter: PATIENT_LIST_VIEW.PENDING,
  },
  [PATIENT_LIST_VIEW.PENDING]: {
    text: 'There are no pending patients.',
    buttonText: 'VIEW READY PATIENTS',
    goToFilter: PATIENT_LIST_VIEW.READY,
  },
  [PATIENT_LIST_VIEW.ERRORS]: {
    text: 'There are no patients with errors.',
    buttonText: 'VIEW READY PATIENTS',
    goToFilter: PATIENT_LIST_VIEW.READY,
  },
};

interface TProps {
  filteredPatientList: PatientObj[];
  isError: boolean;
  isFetching: boolean;
  setStatusFilter: (status: PATIENT_LIST_VIEW) => void;
  showProvider: boolean;
  statusFilter: PATIENT_LIST_VIEW;
  sortDirection: string;
  sortField: string;
  setSortDirection: (direction: string) => void;
  setSortField: (field: string) => void;
  expandedPatientId: string | null;
  setExpandedPatientId: (patientId: string) => void;
}

function ListPatients({
  filteredPatientList,
  isError,
  isFetching,
  setStatusFilter,
  showProvider,
  statusFilter,
  sortDirection,
  sortField,
  setSortDirection,
  setSortField,
  expandedPatientId,
  setExpandedPatientId,
}: TProps) {
  const RowComponent = ComponentMap[statusFilter];

  const handleAccordionChange = (patientId: string) => {
    setExpandedPatientId(patientId);
  };

  const isSomethingExpanded = expandedPatientId !== null;

  return (
    <ContentLoader isFetching={isFetching} isError={isError} minHeight={400}>
      {filteredPatientList.length > 0 ? (
        <TableContainer sx={{ backgroundColor: isSomethingExpanded ? '#E9E9E9' : '#FFFFFF' }}>
          <Table>
            <TableHead>
              <SortableTableHeader
                statusFilter={statusFilter}
                sortDirection={sortDirection}
                sortField={sortField}
                setSortDirection={setSortDirection}
                setSortField={setSortField}
              />
            </TableHead>
            <TableBody>
              {filteredPatientList.map((patientRow: PatientObj) => (
                <RowComponent
                  key={patientRow.id}
                  patient={patientRow}
                  showProvider={showProvider}
                  onAccordionChange={handleAccordionChange}
                  expanded={expandedPatientId === patientRow.id}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Grid item xs={12} textAlign='center' pt={3}>
          <Typography sx={{ margin: '24px 0' }}>{emptyListTextMap[statusFilter].text}</Typography>
          <Button
            color='primary'
            variant='text'
            onClick={() => setStatusFilter(emptyListTextMap[statusFilter].goToFilter)}
          >
            {emptyListTextMap[statusFilter].buttonText}
          </Button>
        </Grid>
      )}
    </ContentLoader>
  );
}

export default memo(ListPatients);
