import { Card, CardContent, Grid, Typography } from '@mui/material';
import CardHeader from 'pages/Dashboard/components/CardHeader';
import NoDataForNow from 'pages/Dashboard/components/NoDataForNow';
import HealthRecordCategories from 'pages/Dashboard/HealthRecordCategories';
import { ShowMoreButton, ShowMoreDrawerChildProps, ShowMoreDrawerTitle, sliceForShowMore } from 'pages/Dashboard/ShowMoreDrawer';
import {
  MentalHealthProvider,
  MentalHealthProviderCategory,
  Provider,
} from 'pages/Dashboard/types/providers.types';
import * as React from 'react';
import theme from 'theme';
import { EProfessions } from 'types/Entitites';

export interface ProviderCardProps extends ShowMoreDrawerChildProps {
  patientProviders?: Provider[];
  mentalHealthProviders?: MentalHealthProvider[];
  primaryCareProviders?: Provider[];
}

const providerCategoryMap: { [key: string]: string } = {
  [MentalHealthProviderCategory.MENTAL_HEALTH_PROVIDER_CATEGORY_PSYCHIATRIST]: 'Psychiatrist',
  [MentalHealthProviderCategory.MENTAL_HEALTH_PROVIDER_CATEGORY_THERAPIST]: 'Therapist',
  [MentalHealthProviderCategory.MENTAL_HEALTH_PROVIDER_CATEGORY_UNSPECIFIED]: 'Other Provider',
};

export default function ProvidersCard({
  closeDrawer,
  drawerItem,
  drawerMode = false,
  patientProviders = [],
  mentalHealthProviders = [],
  primaryCareProviders = [],
  onShowMore,
}: ProviderCardProps): JSX.Element {
  const allProviders: Array<Provider & { category?: string }> = [
    ...patientProviders,
    ...mentalHealthProviders,
    ...primaryCareProviders.map((pcp) => ({ ...pcp, category: 'PCP' })),
  ];

  const data = sliceForShowMore(allProviders, !drawerMode);

  return (
    <Grid item xs={12} md={!drawerMode ? 4 : undefined} key='provider-card'>
      <Card sx={{ height: '100%' }}>
        {!drawerMode && (
          <CardHeader
            icon={HealthRecordCategories.providers.value.icon}
            title={HealthRecordCategories.providers.value.displayName}
          />
        )}
        {drawerMode && <ShowMoreDrawerTitle closeDrawer={closeDrawer} drawerItem={drawerItem} />}
        <CardContent>
          {!allProviders.length ? (
            <NoDataForNow />
          ) : (
            data.map((currentProvider) => (
              <React.Fragment key={`health-history-${currentProvider.provider.id}`}>
                <Typography variant='body1' color={theme.custom.colors.lightTextSecondary}>
                  {providerCategoryMap?.[currentProvider.category as string]
                      ?? currentProvider.category
                      ?? (currentProvider.provider.designation
                        ? EProfessions[
                            currentProvider.provider.designation as keyof typeof EProfessions
                        ]
                        : '')}
                </Typography>
                <Typography mb={2}>
                  {`${currentProvider.provider.firstName} ${currentProvider.provider.lastName}`}
                </Typography>
              </React.Fragment>
            ))
          )}
          <ShowMoreButton
            isDrawer={drawerMode}
            onShowMore={onShowMore as VoidFunction}
            items={allProviders}
          />
        </CardContent>
      </Card>
    </Grid>
  );
}
