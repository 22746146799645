import * as React from 'react';
import { Box, Button, Card, CardContent, CardHeader, Divider, Drawer, Grid, Stack, Typography } from '@mui/material';
import {
  getAverageText,
  TimeFrameOptions,
} from 'pages/Dashboard/utils/trackingUtils';
import theme from 'theme';
import ContentLoader from 'components/Common/ContentLoader';
import { PieChart, Pie, Cell, Sector, ResponsiveContainer } from 'recharts';
import DrawerHeader from 'pages/Rewards/components/DrawerHeader';
import { DonutLargeOutlined } from '@mui/icons-material';
import { Tabs } from 'components/Common/TabComponent';

const indexedColors = [
  '#33739D',
  '#A06824',
  '#8E4C7D',
  '#4A7F4B',
  '#E5772C',
  '#787878',
];

const tabs = Object.keys(TimeFrameOptions).map((timeFrameOption) => ({
  header: TimeFrameOptions[timeFrameOption as keyof typeof TimeFrameOptions],
}));

const renderActiveShape = (props: {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  startAngle: number;
  endAngle: number;
  fill: string;
  payload: { parameter: string };
  percent: number;
}) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill='none' />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fontSize={12}>
        {payload.parameter.split(' ').map((word, index) => (
          <tspan key={word} x={ex + (cos >= 0 ? 1 : -1) * 12} dy={index === 0 ? 0 : 14}>
            {word}
          </tspan>
        ))}
        <tspan x={ex + (cos >= 0 ? 1 : -1) * 12} dy={16} textAnchor={textAnchor} fontSize={14}>
          {(percent * 100).toFixed(0)}
          %
        </tspan>
      </text>
    </g>
  );
};

function CustomPieChart({
  data,
}: {
  data: Metric[];
}): JSX.Element {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const onPieEnter = (_: unknown, index: number) => {
    setActiveIndex(index);
  };

  const totalCount = data.reduce((acc, curr) => acc + curr.count, 0);
  const filteredData = data.reduce((acc, item) => {
    if (acc.length < 5) {
      acc.push(item);
    }
    else if (acc.length === 5) {
      acc.push({
        parameter: 'Other',
        count: item.count,
      });
    }
    else {
      acc[5] = {
        parameter: 'Other',
        count: item.count + acc[5].count,
      };
    }

    return acc;
  }, [] as Metric[]);

  return (
    <Box>
      <ResponsiveContainer width='100%' height={240}>
        <PieChart>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={filteredData}
            cx='50%'
            cy='50%'
            innerRadius={47}
            outerRadius={55}
            fill='#27827F'
            paddingAngle={5}
            dataKey='count'
            onMouseEnter={onPieEnter}
          >
            {filteredData.map((entry, index) => (
              <Cell
                key={entry.parameter}
                fill={indexedColors[index] ?? indexedColors[5]}
                stroke={indexedColors[index] ?? indexedColors[5]}
                strokeWidth={0.5}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <Box display='flex' flexDirection='column' alignItems='center'>
        <Box textAlign='left'>
          {filteredData.map((_, index) => (
            <Box
              key={filteredData[index].parameter}
              mx={1}
              display='flex'
              alignItems='baseline'
              ml={2}
              mb={2}
            >
              <Box
                sx={{
                  width: '8px',
                  height: '8px',
                  borderRadius: '50%',
                  backgroundColor: indexedColors[index] ?? indexedColors[5],
                  display: 'inline-block',
                  marginRight: '5px',
                }}
              />
              <Box>
                <Typography variant='body1' fontWeight='normal'>
                  {filteredData[index].parameter}
                </Typography>

                <Typography variant='body1' fontWeight='normal' color={indexedColors[index] ?? indexedColors[5]}>
                  {Math.round((filteredData[index].count / totalCount) * 100)}
                  %
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

type Metric = {
  parameter: string;
  count: number;
};

function RadarChart({
  label,
  data,
}: {
  label: string;
  data: Metric[];
}): JSX.Element {
  const totalCount = data.reduce((acc, curr) => acc + curr.count, 0);
  const filteredData = data.reduce((acc, item) => {
    if (acc.length < 5) {
      acc.push(item);
    }
    else if (acc.length === 5) {
      acc.push({
        parameter: 'Other',
        count: item.count,
      });
    }
    else {
      acc[5] = {
        parameter: 'Other',
        count: item.count + acc[5].count,
      };
    }

    return acc;
  }, [] as Metric[]);

  const slicedData = filteredData.slice(0, 6);

  return (
    <Box mt={3}>
      <Typography variant='subtitle1' fontWeight={700} mb={1} color={theme.custom.colors.lightTextPrimary}>
        {label}
      </Typography>

      <Grid container maxHeight={160} overflow='hidden' spacing={3}>
        {slicedData.map((_, index) => (
          <Grid
            key={slicedData[index].parameter}
            item
          >
            <Typography variant='body1' fontWeight='normal'>
              {slicedData[index].parameter}
            </Typography>

            <Typography variant='h5' fontWeight='normal' color={indexedColors[index] ?? indexedColors[5]}>
              {Math.round((slicedData[index].count / totalCount) * 100)}
              %
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

type Data = {
  mood: Metric[];
  situation: Metric[];
};

interface LoggedMoodsProps {
  timeFrame: string;
  data: Data;
  noData: boolean;
  isMoodLoading: boolean;
  onChangeTimeFrame: (v: string) => void;
}

export default function LoggedMoods({
  timeFrame: selectedTimeFrame,
  data,
  noData,
  isMoodLoading,
  onChangeTimeFrame: setSelectedTimeFrame,
}: LoggedMoodsProps): JSX.Element {
  const timeFrameString = getAverageText(selectedTimeFrame, false, true);
  const [isOpen, setIsOpen] = React.useState(false);

  const onShowMore = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const timeFrameValues = React.useMemo(() => Object.values(TimeFrameOptions), []);
  const timeFrame = React.useMemo(
    () => timeFrameValues.findIndex((value) => value === selectedTimeFrame),
    [selectedTimeFrame, timeFrameValues],
  );

  return (
    <Grid item xs={12} md={4} key='mood-card'>
      <Card
        sx={{
          height: '100%',
          borderRadius: '5px',
          overflow: 'visible',
          display: 'flex',
          flexDirection: 'column',
          '& .MuiCardContent-root:last-child': {
            paddingBottom: 2,
          },
        }}
      >
        <CardHeader
          sx={{
            borderRadius: '5px 5px 0 0',
            paddingBottom: 0,
            '& .MuiCardHeader-action': {
              margin: 0,
              alignSelf: 'center',
              cursor: 'pointer',
            },
          }}
          title={(
            <Box display='flex' alignItems='center'>
              <Typography variant='h5' fontWeight='normal'>
                Details
                <Typography variant='body1' color={theme.custom.colors.lightTextSecondary}>
                  {timeFrameString}
                </Typography>
              </Typography>
            </Box>
          )}
        />
        <CardContent sx={{ paddingTop: 0, flex: 1 }}>
          <Drawer
            anchor='right'
            open={isOpen}
            onClose={onClose}
            sx={{ zIndex: theme.zIndex.drawer + 2 }}
            PaperProps={{ sx: { maxWidth: 'lg', width: '100%' } }}
            data-testid='rewards-drawer'
          >
            <Box role='grid' display='flex' flexDirection='column' overflow='hidden'>
              <DrawerHeader title='Mood details' onClose={onClose} showBackIcon={false} />
              <Divider />
              <Box
                display='flex'
                alignItems='center'
                bgcolor='#E9E9E9'
                justifyContent='flex-end'
                mx={2}
                my={3}
              >
                <Typography color='#4C4C4C' marginRight={2}>
                  Data from past:
                </Typography>
                <Tabs
                  value={timeFrame}
                  setValue={(value) => {
                    const timeFrameValue = timeFrameValues[value as number];
                    setSelectedTimeFrame(timeFrameValue);
                  }}
                  tabContext={tabs}
                  variant='standard'
                  activeStyles={{
                    backgroundColor: '#FFF',
                    maxHeight: 24,
                  }}
                />
              </Box>
              <Grid container padding={2} flex={1} overflow='scroll' spacing={2}>
                <Grid item xs={6} textAlign='center'>
                  <Typography variant='h6'>
                    Primary moods
                  </Typography>
                  <ContentLoader
                    isFetching={isMoodLoading}
                    isError={false}
                    noDataText={noData ? 'No moods exists for the selected timeframe' : ''}
                    minHeight={100}
                  >
                    <CustomPieChart
                      data={data.mood}
                    />
                  </ContentLoader>
                </Grid>
                <Grid item xs={6} textAlign='center'>
                  <Typography variant='h6'>
                    Primary reasons
                  </Typography>
                  <ContentLoader
                    isFetching={isMoodLoading}
                    isError={false}
                    noDataText={noData ? 'No reasons exists for the selected timeframe' : ''}
                    minHeight={100}
                  >
                    <CustomPieChart
                      data={data.situation}
                    />
                  </ContentLoader>
                </Grid>
              </Grid>
            </Box>
          </Drawer>
          <ContentLoader
            isFetching={isMoodLoading}
            isError={false}
            noDataText={noData ? 'No data exists for the selected timeframe' : ''}
            minHeight={100}
          >
            <Stack display='flex' flexDirection='column' height='100%'>
              <RadarChart label='Primary moods' data={data.mood} />
              <RadarChart label='Primary reasons' data={data.situation} />
              <Box textAlign='center' width='100%' marginTop='auto'>
                <Button
                  onClick={onShowMore}
                  variant='contained'
                  sx={{ mt: 2 }}
                  startIcon={(
                    <DonutLargeOutlined
                      fontSize='large'
                    />
              )}
                >
                  VIEW CHARTS
                </Button>
              </Box>

            </Stack>
          </ContentLoader>
        </CardContent>
      </Card>
    </Grid>
  );
}
