import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { SideNavCtx } from 'components/Layouts/constants';
import Typography from '@mui/material/Typography';
import NavTree from 'utils/NavTree';
import { useHttp } from 'hooks/use-fetch';
import { NavTreeIds } from 'types/NavTreeModel';

export default function SideNav() {
  const theme = useTheme();
  const { drawerWidth, isFullNav } = React.useContext(SideNavCtx);
  const { userRoles, isBillingAdmin } = useHttp();

  const filteredNavItems = React.useMemo(() => NavTree.filter(
    (navItem) => {
      const hasRoles = navItem.roles.some(
        (role) => (
          userRoles[role]
        ),
      );
      return navItem.id === NavTreeIds.REPORTS ? isBillingAdmin || hasRoles : hasRoles;
    },
  ), [isBillingAdmin, userRoles]);

  return (
    <Drawer
      open
      sx={{
        width: drawerWidth,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          marginTop: theme.custom.appBarHeight,
          zIndex: theme.zIndex.appBar - 1,
        },
      }}
      variant='permanent'
    >
      <List disablePadding>
        {filteredNavItems.map((navItem) => {
          const Icon = navItem.icon;
          return (
            <ListItemButton component={Link} to={navItem.spaUrl} divider key={navItem.name}>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              {isFullNav ? <Typography>{navItem.name}</Typography> : null}
            </ListItemButton>
          );
        })}
      </List>
    </Drawer>
  );
}
