import * as React from 'react';
import { Box, InputLabel, TextField } from '@mui/material';
import { ConnectionCredentials, CredentialType } from 'types/Entitites';


interface AddCredentialFormProps{
  credential: ConnectionCredentials
  idx: number
  setCredentialList: (e: ConnectionCredentials, idx: number) => void
}

const CredentialLabels = {
  [CredentialType.ClientId]: 'Client ID',
  [CredentialType.ClientSecret]: 'Client Sec',
  [CredentialType.Sub]: 'Sub',
  [CredentialType.Issuer]: 'Issuer',
  [CredentialType.Audience]: 'Audience',
};

export default function AddCredentialsForm({
  idx, credential, setCredentialList }: AddCredentialFormProps) {
  return (
    <Box display='flex' alignItems='center' gap={2}>
      <InputLabel sx={{ width: 100 }}>
        {CredentialLabels[credential.name]}
      </InputLabel>
      <TextField
        value={credential.value}
        autoComplete='off'
        label=''
        variant='outlined'
        onChange={
            (e) => setCredentialList({ name: credential.name, value: e.target.value }, idx)
          }
      />
    </Box>
  );
}
