import * as React from 'react';
import { IconButton } from '@mui/material';
import RewardPoints from 'pages/Rewards/components/RewardPoints';

interface IProps {
  clickHandler?: (event: React.KeyboardEvent | React.MouseEvent) => void;
  rewardPoints?: number;
}

function RewardsButton({ clickHandler, rewardPoints = 0 }: IProps) {
  return (
    <IconButton sx={{ mr: 3, borderRadius: 0 }} onClick={clickHandler} data-testid='rewards-button'>
      <RewardPoints rewardPoints={rewardPoints} />
    </IconButton>
  );
}

export default RewardsButton;
