import { Box } from '@mui/material';
import { WaypointTracking } from 'pages/Dashboard/types/waypoints.types';
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LegendProps } from 'recharts';


const categorizeValue = (value: number): 'Less' | 'Typical' | 'More' => {
  if (value <= 10) return 'Less';
  if (value > 10 && value <= 20) return 'Typical';
  return 'More';
};

const countSleepCategories = (dataArray: WaypointTracking[]) => {
  const counts = { Less: 0, Typical: 0, More: 0 };

  dataArray.forEach((data) => {
    const category = categorizeValue(data.value);
    counts[category] += 1;
  });

  return counts;
};

interface SleepChartProps {
  previousDataArray: WaypointTracking[];
currentDataArray: WaypointTracking[];
}

interface CustomLegendProps {
  payload?: LegendProps['payload'];
}

const renderLegend = (props: CustomLegendProps) => {
  const { payload } = props;

  return (
    <ul style={{ listStyle: 'none', display: 'flex', padding: 0, justifyContent: 'center', width: '100%' }}>
      {payload?.map((entry, index) => (
        <li style={{ marginRight: 20, display: 'flex', alignItems: 'center' }}>
          <span
            style={{
              display: 'inline-block',
              width: 12,
              height: 12,
              backgroundColor: entry.color,
              borderRadius: '50%',
              marginRight: 8,
            }}
          />
          {entry.value}
        </li>
      ))}
    </ul>
  );
};

function SleepChart({ previousDataArray, currentDataArray } : SleepChartProps) {
  const previousCounts = countSleepCategories(previousDataArray);
  const currentCounts = countSleepCategories(currentDataArray);

  const sleepChartData = [
    {
      name: 'Less',
      past30Days: currentCounts.Less,
      prior30Days: previousCounts.Less,
    },
    {
      name: 'Typical',
      past30Days: currentCounts.Typical,
      prior30Days: previousCounts.Typical,
    },
    {
      name: 'More',
      past30Days: currentCounts.More,
      prior30Days: previousCounts.More,
    },
  ];

  return (
    <div style={{ width: '90%', display: 'flex' }}>
      <Box sx={{ ml: -2 }}>
        <BarChart
          width={350}
          height={300}
          data={sleepChartData}
          margin={{
            top: 20,
            right: 30,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' />
          <YAxis
            label={{ value: 'times reported', angle: -90, position: 'insideLeft', dx: 30, dy: 40 }}
            interval={0}
            domain={[4, 'dataMax']}
            tickFormatter={(tick) => tick}
            allowDecimals={false}
          />
          <Tooltip />
          <Legend content={renderLegend} />
          <Bar dataKey='past30Days' fill='#004477' name='Past 30 days' />
          <Bar dataKey='prior30Days' fill='#66A2D1' name='Prior 30 days' />
        </BarChart>
      </Box>
    </div>
  );
}

export default SleepChart;
