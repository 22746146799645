import TRewardsResponse from 'pages/Rewards/types/rewards.types';
import { QueryFunctionContext } from 'react-query';
import { rewardsUrls } from 'utils/apiUrls';
import { HttpType } from 'utils/http';

const fetchRewards = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<TRewardsResponse> {
  const [, providerId] = queryKey;

  return method(rewardsUrls.rewards.apiUrl(providerId as string));
};

export default fetchRewards;

