import * as React from 'react';
import { Button, CardHeader, Drawer, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

export interface ShowMoreDrawerTitleProps {
  drawerItem: string;
  closeDrawer: () => void;
}

export interface ShowMoreDrawerProps extends ShowMoreDrawerTitleProps {
  children: React.ReactNode;
}

export interface ShowMoreButtonProps {
  onShowMore: () => void;
  isDrawer: boolean; // true if drawer
  items: unknown[];
}

export interface ShowMoreDrawerChildProps extends ShowMoreDrawerTitleProps {
  onShowMore?: () => void;
  drawerMode: boolean;
}

export default function ShowMoreDrawer(props: ShowMoreDrawerProps) {
  const { drawerItem, closeDrawer, children } = props;

  return (
    <Drawer
      anchor='right'
      open={!!drawerItem}
      onClose={closeDrawer}
      PaperProps={{
        sx: {
          width: 440,
          minWidth: 440,
        },
      }}
    >
      {children}
    </Drawer>
  );
}

export function ShowMoreDrawerTitle(props: ShowMoreDrawerTitleProps) {
  const { drawerItem, closeDrawer } = props;

  return (
    <CardHeader
      action={(
        <IconButton onClick={closeDrawer}>
          <Close fontSize='small' />
        </IconButton>
      )}
      title={drawerItem}
    />
  );
}

export const SHOW_MORE_AFTER = 3;

export function ShowMoreButton(props: ShowMoreButtonProps) {
  const { isDrawer, onShowMore, items } = props;

  if (!isDrawer && onShowMore && items.length > SHOW_MORE_AFTER) {
    return <Button onClick={onShowMore}>SHOW MORE</Button>;
  }

  return null;
}

export function sliceForShowMore<T>(items: T[], slice = false) {
  return slice ? items.slice(0, SHOW_MORE_AFTER) : items;
}
