import { useAuth0 } from '@auth0/auth0-react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import * as React from 'react';


export default function LoginForm() {
  const { loginWithRedirect } = useAuth0();
  return (
    <Box width='100%'>
      <Typography gutterBottom variant='h2'>Sign In</Typography>
      <Button
        color='primary'
        onClick={loginWithRedirect}
        size='large'
        variant='contained'
      >
        SIGN IN
      </Button>
    </Box>
  );
}
