import { Box, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import CardHeader from 'pages/Dashboard/components/CardHeader';
import NoDataForNow from 'pages/Dashboard/components/NoDataForNow';
import HealthRecordCategories from 'pages/Dashboard/HealthRecordCategories';
import { RelationFormatter, EducationFormatter, LegalHistoryFormatter, consumption } from 'pages/Dashboard/services/lifestyle.services';
import { EducationLevel, LegalHistoryType, PatientBackground, PatientBackgroundCategory, PatientRelationshipCategory } from 'pages/Dashboard/types/background.types';
import { Exercise, LifeStyleCategory, PatientLifeStyle } from 'pages/Dashboard/types/lifestyle.types';
import * as React from 'react';
import theme from 'theme';


interface PsychosocialCardProps {
  lifeStyle?: PatientLifeStyle[],
  background?: PatientBackground[]
}

export default function PsychosocialCard(
  { lifeStyle = [], background = [] }: PsychosocialCardProps,
): JSX.Element {
  function renderBackground(currentBackground: PatientBackground) {
    const { relationship, education, legalHistory } = currentBackground;
    switch (currentBackground.category) {
      case PatientBackgroundCategory.PATIENT_BACKGROUND_CATEGORY_RELATIONSHIP:
        return (
          <Box>
            <Typography variant='body1' color={theme.custom.colors.lightTextSecondary}>
              Relationship
            </Typography>
            <Typography>
              {RelationFormatter(relationship?.relationshipStatus
                || PatientRelationshipCategory.PATIENT_RELATIONSHIP_CATEGORY_UNSPECIFIED)}
            </Typography>
          </Box>
        );
      case PatientBackgroundCategory.PATIENT_BACKGROUND_CATEGORY_EDUCATION:
        return (
          <Box>
            <Typography variant='body1' color={theme.custom.colors.lightTextSecondary}>
              Education
            </Typography>
            <Typography>
              {EducationFormatter(education?.educationLevel
                || EducationLevel.EDUCATION_LEVEL_UNSPECIFIED)}
            </Typography>
          </Box>
        );
      case PatientBackgroundCategory.PATIENT_BACKGROUND_CATEGORY_LEGAL_HISTORY:
        return (
          <Box>
            <Typography variant='body1' color={theme.custom.colors.lightTextSecondary}>
              Legal history
            </Typography>
            <Typography>
              {
                legalHistory?.legalHistory
                  ? LegalHistoryFormatter(legalHistory?.legalHistoryType
                    || LegalHistoryType.LEGAL_HISTORY_TYPE_UNSPECIFIED)
                  : 'Never been arrested'
              }
            </Typography>
          </Box>
        );
    }
    return null;
  }

  return (
    <Grid item xs={12} md={4}>
      <Card sx={{ height: '100%' }}>
        <CardHeader
          icon={HealthRecordCategories.psychosocial.value.icon}
          title={HealthRecordCategories.psychosocial.value.displayName}
        />
        <CardContent>
          {
            !lifeStyle.find(
              (style) => style.category === LifeStyleCategory.LIFE_STYLE_CATEGORY_EXERCISE,
            ) && !background.length ? <NoDataForNow /> : (
              <Stack spacing={2}>
                { lifeStyle.map((style) => {
                  if (style.category === LifeStyleCategory.LIFE_STYLE_CATEGORY_EXERCISE) {
                    const { exercise } = style;
                    return (
                      <Box key={LifeStyleCategory.LIFE_STYLE_CATEGORY_EXERCISE}>
                        <Typography variant='body1' color={theme.custom.colors.lightTextSecondary}>
                          Exercise
                        </Typography>
                        {exercise?.consumption ? (
                          <>
                            {consumption(exercise)
                              ? <Typography>{consumption(exercise)}</Typography> : null }
                            {exercise?.exercises?.map((workout: Exercise) => (
                              <Typography key={workout.id}>{workout.name}</Typography>
                            ))}
                          </>
                        ) : 'None'}
                      </Box>
                    );
                  }
                  return null;
                })}
                { background.map((curr) => renderBackground(curr))}
              </Stack>
            )
          }
        </CardContent>
      </Card>
    </Grid>
  );
}
