import { ListWaypointSuggestionsAPIResponse, CreateWaypointSuggestionsPayload } from 'pages/Dashboard/types/waypointSuggestion.types';
import { ListWaypointAPIResponse } from 'pages/Dashboard/types/waypoints.types';
import { QueryFunctionContext } from 'react-query';
import { wayPointSuggestionUrls, wayPointsUrls } from 'utils/apiUrls';
import { HttpType } from 'utils/http';

export const fetchStandardWaypointsList = (
  method: HttpType['get'],
) => function async({
  queryKey,
}: QueryFunctionContext): Promise<ListWaypointAPIResponse> {
  const [, providerId] = queryKey;
  return (
    method(wayPointsUrls.listWayPoints.apiUrls(
      providerId as string,
    ).list)
  );
};

export const fetchWaypointSuggestionsList = (
  method: HttpType['get'],
) => function async({
  queryKey,
}: QueryFunctionContext): Promise<ListWaypointSuggestionsAPIResponse> {
  const [, providerId, patientId] = queryKey;
  return (
    method(wayPointSuggestionUrls.listWaypointSuggestions.apiUrls(
      providerId as string,
      patientId as string,
    ).list)
  );
};

export const createWaypointSuggestions = (
  method: HttpType['post'],
  providerId: string,
  patientId: string,
  payload: CreateWaypointSuggestionsPayload,
) => method(wayPointSuggestionUrls.createWaypointSuggestions.apiUrls(
  providerId as string,
  patientId as string,
).create, payload);

export const deleteWaypointSuggestions = (
  method: HttpType['delete'],
  providerId: string,
  patientId: string,
) => method(wayPointSuggestionUrls.deleteWaypointSuggestions.apiUrls(
      providerId as string,
      patientId as string,
).delete);
