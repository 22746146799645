import DownloadingIcon from '@mui/icons-material/Downloading';
import { Alert, Box, CardContent, Typography, capitalize } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import ResendInvite from 'pages/Dashboard/components/ResendInvite';
import ResolveInvite from 'pages/Dashboard/components/ResolveInvite';
import { ONBOARDING_STATUS } from 'pages/Dashboard/enums';
import { PatientObj } from 'pages/Dashboard/types/patient.types';
import * as React from 'react';
import { Link } from 'react-router-dom';
import theme from 'theme';
import { GoogleDate, googleDateToDateString } from 'utils/dateUtils';
import { getPatientRecordRetrievalStatus, RecordRetrievalState } from 'utils/patientUtils';
import * as spaUrls from 'utils/spaUrls';

interface IProps {
  details: PatientObj;
  showProvider: boolean;
}

export default function RecentPatientCard({ details, showProvider }: IProps) {
  const isPending = [
    ONBOARDING_STATUS.PENDING_INVITE_DELIVERY,
    ONBOARDING_STATUS.PENDING_PATIENT_SIGNUP,
  ].includes(details?.onboardingMetadata?.status as ONBOARDING_STATUS);

  // eslint-disable-next-line max-len
  const isDeliveryFailed = ONBOARDING_STATUS.DELIVERY_FAILED
    === (details?.onboardingMetadata?.status as ONBOARDING_STATUS);

  const retrievalStatus = getPatientRecordRetrievalStatus(details.recordRetrievalStatus);
  const cardContent = React.useMemo(() => {
    const cardContentJsxMap: { [key in RecordRetrievalState]: React.ReactNode } = {
      [RecordRetrievalState.Fetching]: (
        <Box display='flex' gap='12px'>
          <DownloadingIcon fontSize='small' sx={{ color: theme.custom.colors.lightTextSecondary, marginTop: '2px' }} />
          <Typography variant='body1'>Retrieving health records</Typography>
        </Box>
      ),
      [RecordRetrievalState.Error]: (
        <Box display='flex' gap='12px'>
          <DownloadingIcon fontSize='small' sx={{ color: theme.custom.colors.lightTextSecondary, marginTop: '2px' }} />
          <Typography variant='body1'>
            Still retrieving health records. Thank you for your patience.
          </Typography>
        </Box>
      ),
      [RecordRetrievalState.Success]: null,
    };
    return (
      <>
        {cardContentJsxMap[retrievalStatus]}
        {isDeliveryFailed ? (
          <Alert sx={{ mt: 2 }} variant='outlined' severity='error'>
            Invite delivery failed
          </Alert>
        ) : null}
      </>
    );
  }, [isDeliveryFailed, retrievalStatus]);

  const title = React.useMemo(
    () => (
      <Box display='flex' flexDirection='column'>
        {showProvider && (
          <Typography variant='h6'>{`${capitalize(details.provider?.firstName ?? '')} ${capitalize(details.provider?.lastName ?? '')}`}</Typography>
        )}
        <Typography variant='h6'>{`${capitalize(details.name?.firstName ?? '')} ${capitalize(details.name?.lastName ?? '')}`}</Typography>
      </Box>
    ),
    [
      details.name?.firstName,
      details.name?.lastName,
      details.provider?.firstName,
      details.provider?.lastName,
      showProvider,
    ],
  );

  const actions = React.useMemo(() => (
    <>
      {retrievalStatus === RecordRetrievalState.Success ? (
        <Button
          variant='outlined'
          size='small'
          component={Link}
          to={spaUrls.mainNav.patientDetail(details?.id)}
          sx={{ mr: 4 }}
        >
          VIEW
        </Button>
      ) : null}
      {isDeliveryFailed ? <ResolveInvite patient={details} sx={{ mr: 4 }} /> : null}
      {retrievalStatus === RecordRetrievalState.Fetching || isPending
        ? <ResendInvite patient={details} />
        : null}
    </>
  ), [details, isDeliveryFailed, isPending, retrievalStatus]);

  return (
    <Card
      sx={{
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardHeader
        subheader={googleDateToDateString(details.dateOfBirth as GoogleDate)}
        subheaderTypographyProps={{ variant: 'body1', color: theme.custom.colors.lightTextSecondary }}
        title={title}
      />
      <CardContent>{cardContent}</CardContent>
      <CardActions disableSpacing sx={{ padding: '16px', mt: 'auto' }}>
        {actions}
      </CardActions>
    </Card>
  );
}
