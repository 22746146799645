import MobileFriendly from '@mui/icons-material/MobileFriendly';
import Add from '@mui/icons-material/Add';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Downloading from '@mui/icons-material/Downloading';
import Home from '@mui/icons-material/Home';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import * as spaUrls from 'utils/spaUrls';
import React from 'react';
import { Preview } from '@mui/icons-material';
import {
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableRow,
  styled,
} from '@mui/material';
import { Link } from 'react-router-dom';

interface AddNewPatientSuccessProps {
  onAddAnotherPatientClick: VoidFunction;
  patientFormDemographic: boolean;
}

const StyledTableCell = styled(TableCell)<TableCellProps>(() => ({
  borderBottom: 'none',
  padding: '8px',
}));

export default function AddNewPatientSuccess({
  onAddAnotherPatientClick,
  patientFormDemographic,
}: AddNewPatientSuccessProps) {
  const demographicSuccessForm = () => (
    <Table sx={{ maxWidth: 470, mx: 'auto', my: 2 }}>
      <TableBody>
        <TableRow>
          <StyledTableCell>
            <Downloading color='primary' fontSize='large' />
          </StyledTableCell>
          <StyledTableCell>
            <Typography variant='body1'>
              We&apos;ll start pulling the patient&apos;s health history now
            </Typography>
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell>
            <MobileFriendly color='primary' fontSize='large' />
          </StyledTableCell>
          <StyledTableCell>
            <Typography variant='body1'>
              An invite has been sent to the patient to download the Headlamp mobile app and create
              their account
            </Typography>
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell>
            <Preview color='primary' fontSize='large' />
          </StyledTableCell>
          <StyledTableCell>
            <Typography variant='body1'>
              When ready, you can view their information from the home page
            </Typography>
          </StyledTableCell>
        </TableRow>
      </TableBody>
    </Table>
  );

  const invitePatientSuccessForm = () => (
    <Table sx={{ maxWidth: 470, mx: 'auto', my: 2 }}>
      <TableBody>
        <TableRow>
          <StyledTableCell>
            <MobileFriendly color='primary' fontSize='large' />
          </StyledTableCell>
          <StyledTableCell>
            <Box>
              <Typography variant='body1'>
                Patient(s) will be invited to:
              </Typography>
              <List disablePadding sx={{ listStyleType: 'disc', ml: 2 }}>
                <ListItem sx={{ display: 'list-item', p: 0 }}>
                  <Typography variant='body1'>download the Headlamp mobile app</Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item', p: 0 }}>
                  <Typography variant='body1'>consent to pulling health records</Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item', p: 0 }}>
                  <Typography variant='body1'>create their Headlamp account</Typography>
                </ListItem>
              </List>
            </Box>
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <StyledTableCell>
            <Preview color='primary' fontSize='large' />
          </StyledTableCell>
          <StyledTableCell>
            <Typography variant='body1'>
              Once this is completed, you can view their health history from the home page
            </Typography>
          </StyledTableCell>
        </TableRow>
      </TableBody>
    </Table>
  );

  return (
    <Grid container spacing={2} textAlign='center'>
      <Grid item xs={12}>
        <Box display='flex' flexDirection='row' justifyContent='center'>
          <CheckCircle color='success' fontSize='medium' />
          <Typography variant='h5'>&nbsp;Success</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h5'>&nbsp;What&apos;s Next?</Typography>
      </Grid>
      {patientFormDemographic ? demographicSuccessForm() : invitePatientSuccessForm()}
      <Grid item xs={12}>
        <Button
          startIcon={<Add />}
          onClick={onAddAnotherPatientClick}
          variant='contained'
          color='primary'
          sx={{ textTransform: 'uppercase' }}
        >
          Add Another Patient
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          component={Link}
          to={spaUrls.mainNav.dashboard}
          startIcon={<Home />}
          variant='outlined'
          color='primary'
          sx={{ textTransform: 'uppercase' }}
        >
          Return Home
        </Button>
      </Grid>
    </Grid>
  );
}
