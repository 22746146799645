import { TPartialExplorerAction } from 'pages/Rewards/types/explorer-actions.types';
import { TPartialRewards } from 'pages/Rewards/types/rewards.types';
import { Tabs } from 'pages/Rewards/types/tabs.types';
import { TPartialTransactions } from 'pages/Rewards/types/transactions.types';
import * as React from 'react';

export enum TComponent {
  'DEFAULT' = 'DEFAULT',
  'REFERRAL' = 'REFERRAL',
  'QUESTION' = 'QUESTION',
}

export type ActiveComponent<T> = {
  component: TComponent;
  props?: T;
};

export type TRewardsContext = {
  explorerActions: TPartialExplorerAction[] | undefined;
  explorerActionsFetching: boolean;
  isExplorerActionsError: boolean;
  isRewardPointsError: boolean;
  isRewardsError: boolean;
  isTransactionsError: boolean;
  rewardPoints: number | string | undefined;
  rewardPointsFetching: boolean;
  rewards: TPartialRewards[] | undefined;
  rewardsFetching: boolean;
  transactions: TPartialTransactions[] | undefined;
  transactionsFetching: boolean;
  activeComponent: ActiveComponent<unknown>;
  activeTab: Tabs;
  setActiveTab: (tab: Tabs) => void;
  setActiveComponent: ({
    component,
    props,
  }: ActiveComponent<unknown>) => void;
};

export const defaultContextValue = {
  explorerActions: undefined,
  explorerActionsFetching: false,
  isExplorerActionsError: false,
  isRewardPointsError: false,
  isRewardsError: false,
  isTransactionsError: false,
  rewardPoints: undefined,
  rewardPointsFetching: false,
  rewards: undefined,
  rewardsFetching: false,
  transactions: undefined,
  transactionsFetching: false,
  activeTab: Tabs.REWARDS,
  activeComponent: { component: TComponent.DEFAULT },
  setActiveTab: () => null,
  setActiveComponent: () => null,
};

const RewardsContext = React.createContext(defaultContextValue as TRewardsContext);

export default RewardsContext;
