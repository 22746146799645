import * as React from 'react';
import { Grid, Link, Typography } from '@mui/material';
import RewardsTabs from 'pages/Rewards/components/RewardsTabs';
import theme from 'theme';
import RewardsContext from 'pages/Rewards/context/RewardsContext';
import RewardPoints from 'pages/Rewards/components/RewardPoints';
import trackMixpanelEvent, { tabItemsMap } from 'pages/Rewards/utilMethods';
import { MixpanelEventName } from 'pages/Rewards/types/mixpanel.types';

const feedbackEmail = 'feedback@headlamp.com';

export default function DrawerContent() {
  const { activeTab, rewardPoints } = React.useContext(RewardsContext);

  const trackLinkClick = () => {
    trackMixpanelEvent(MixpanelEventName.FEEDBACK_LINK_CLICKED, {
      'active tab': tabItemsMap[activeTab],
    });
  };

  return (
    <>
      <Grid item marginBottom={3.5}>
        <Typography variant='body1' color={theme.custom.colors.lightTextPrimary}>
          Headlamp Gives Back enables clinicians to earn Explorer Points to spend on rewards for
          their participation and partnership with Headlamp Health.
        </Typography>
      </Grid>
      <Grid item marginBottom={3.5} display='flex' alignItems='center'>
        <RewardPoints rewardPoints={rewardPoints} />
        <Typography marginLeft={1.5} variant='body1' color={theme.custom.colors.lightTextSecondary}>
          Explorer point balance
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <RewardsTabs />
        <Typography mb={3}>
          We always want your feedback! Email
          {' '}
          <Link
            underline='hover'
            href={`mailto:${feedbackEmail}`}
            onClick={trackLinkClick}
          >
            {feedbackEmail}
          </Link>
          {' '}
          with any comments, questions, or suggestions.
        </Typography>
        <Typography color={theme.custom.colors.lightTextSecondary}>
          Headlamp Gives Back is subject to change.
        </Typography>
      </Grid>
    </>
  );
}
