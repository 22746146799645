import { useTheme } from '@emotion/react';
import * as React from 'react';
import { PieChart, Pie, Cell, Label } from 'recharts';

type GaugeData = { value: number };

interface GaugeChartProps {
  color: string;
  data: GaugeData[];
  value: string;
}

export default function GaugeChartSmall({
  color,
  value,
  data,
}: GaugeChartProps): JSX.Element {
  const theme = useTheme();

  const renderCustomLabel = (props: { [key: string]: unknown }) => {
    const x = !props.name
      ? parseInt(props.cx as string, 10) - 40
      : parseInt(props.cx as string, 10) + 33;

    const y = parseInt(props.cy as string, 10) + 35;

    return (
      <text fill='grey' fontSize='0.75rem' x={x} y={y} width='80%'>
        {!props.name ? '0' : 10}
      </text>
    );
  };

  return (
    <PieChart margin={{ bottom: 0, top: 2 }} width={100} height={100}>
      <Pie
        startAngle={225}
        endAngle={-45}
        innerRadius='70%'
        data={data}
        dataKey='value'
        labelLine={false}
        blendStroke
        isAnimationActive
        label={renderCustomLabel}
      >
        <Cell fill={color} />
        <Cell fill={theme.palette.mode === 'dark' ? 'grey' : '#d2d2d2'} />
        <Label
          fill={theme.palette.text.primary}
          {...theme.typography.h2}
          value={value}
          position='center'
        />
      </Pie>
    </PieChart>
  );
}
