import * as React from 'react';
import { useMutation, useQueries, useQuery } from 'react-query';
import { Box, Grid, TablePagination, Typography, debounce } from '@mui/material';
import PageHeader from 'components/Common/PageHeader';
import { useHttp } from 'hooks/use-fetch';
import useSearchParamsState from 'hooks/use-search-params-state';
import ListPatientFilters from 'pages/Dashboard/components/ListPatientFilters';
import ListPatients from 'pages/Dashboard/components/ListPatientNew';
import { RefetchPatientsCtx } from 'pages/Dashboard/constants';
import { PATIENT_LIST_VIEW } from 'pages/Dashboard/enums';
import usePatientList from 'pages/Dashboard/hooks/usePatientList';
import {
  fetchProvidersAssociationsList,
  fetchUserMetadataWithKeys,
  postUserMetadata,
} from 'pages/Dashboard/services/provider.services';
import { providers } from 'utils/apiUrls';
import SearchBar from 'pages/Dashboard/components/SearchBar';
import { UserMetadata, UserMetadataFlags } from 'pages/Dashboard/types';
import { PracticeProvider } from 'pages/Dashboard/types/providers.types';
import { useAuth0 } from '@auth0/auth0-react';
import { logSentryInfo, trackMixpanelEvent } from 'utils/utilMethods';
import { MixpanelEventName } from 'utils/constants';
import Theme from 'theme';

const batchSize = 50;

export default function DashboardV2() {
  const { http, providerId } = useHttp();
  const { user } = useAuth0();
  const [statusFilter, setStatusFilter] = useSearchParamsState('status', PATIENT_LIST_VIEW.READY);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [sortField, setSortField] = React.useState('');
  const [sortDirection, setSortDirection] = React.useState('');
  const [lastReadySortField, setlastReadySortField] = React.useState('');
  const [lastReadySortDirection, setlastReadySortDirection] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(0);
  const [expandedPatientId, setExpandedPatientId] = React.useState<string | null>(null);
  const [providerIdFilter, setProviderIdFilter] = React.useState<string>('');
  const handleProviderIdFilterChange = (provider: string) => {
    setProviderIdFilter(provider);
    trackMixpanelEvent(MixpanelEventName.PROVIDER_FILTER_SELECTED, {
      selectedProviderId: provider,
    });
  };
  const handleSortFieldChange = (value: string) => {
    if (statusFilter === PATIENT_LIST_VIEW.READY) {
      setAddPatientPreference(UserMetadataFlags.DefaultSortField, value);
      setlastReadySortField(value);
    }
    setSortField(value);
    setPage(0);
  };
  const handleSortDirectionChange = (value: string) => {
    if (statusFilter === PATIENT_LIST_VIEW.READY) {
      setAddPatientPreference(UserMetadataFlags.DefaultSortDirection, value);
      setlastReadySortDirection(value);
    }
    setSortDirection(value);
    setPage(0);
  };

  const filters = React.useMemo(
    () => ({
      status: statusFilter,
    }),
    [statusFilter],
  );
  const processFlagData = async (data: UserMetadata) => {
    const finalData: UserMetadata = await data;
    trackMixpanelEvent(MixpanelEventName.PROVIDER_METADATA_LOADED, {
      flag: finalData.flag.name,
      value: finalData.flag.value ?? '',
    });
    if (finalData.flag.name === UserMetadataFlags.NumberOfRowsPerPage) {
      setRowsPerPage(parseInt(finalData.flag.value ?? '', 10) ?? 0);
    }
    if (finalData.flag.name === UserMetadataFlags.DefaultSortDirection) {
      if (statusFilter === PATIENT_LIST_VIEW.READY) {
        setSortDirection(finalData.flag.value ?? '');
      }
      setlastReadySortDirection(finalData.flag.value ?? '');
    }
    if (finalData.flag.name === UserMetadataFlags.DefaultSortField) {
      if (statusFilter === PATIENT_LIST_VIEW.READY) {
        setSortField(finalData.flag.value ?? '');
      }
      setlastReadySortField(finalData.flag.value ?? '');
    }
  };

  const metadataQueries = useQueries([
    {
      queryKey: [providerId, UserMetadataFlags.NumberOfRowsPerPage],
      queryFn: fetchUserMetadataWithKeys(http.post),
      onSuccess: processFlagData,
      onError: () => {
        setRowsPerPage(batchSize);
      },
    },
    {
      queryKey: [providerId, UserMetadataFlags.DefaultSortDirection],
      queryFn: fetchUserMetadataWithKeys(http.post),
      onSuccess: processFlagData,
      onError: () => {
        // Handle error
        setSortDirection('desc');
      },
    },
    {
      queryKey: [providerId, UserMetadataFlags.DefaultSortField],
      queryFn: fetchUserMetadataWithKeys(http.post),
      onSuccess: processFlagData,
      onError: () => {
        // Handle error
        setSortField('dateAdded');
      },
    },
  ]);
  const [numberOfRows, sortDirectionVal, sortFieldVal] = metadataQueries;
  const isLoading = numberOfRows.isLoading || sortDirectionVal.isLoading || sortFieldVal.isLoading;

  const {
    isFetching,
    isError,
    filteredPatientList,
    patientsCount,
    refetchPatientsList,
    refetchPatientsCount,
  } = usePatientList(
    providerId,
    filters,
    null,
    searchQuery,
    sortDirection,
    sortField,
    rowsPerPage * page,
    rowsPerPage,
    isLoading,
    providerIdFilter,
    true,
  );
  const handleChangePage = (_: unknown, newPage: number) => {
    trackMixpanelEvent(MixpanelEventName.PAGE_CHANGED, { currentPage: page?.toString() ?? '', nextPage: newPage?.toString() ?? '' });
    setPage(newPage);
  };
  const handleChangeExpandedPatientId = (patientId: string) => {
    if (patientId === expandedPatientId) {
      setExpandedPatientId(null);
    }
    else {
      setExpandedPatientId(patientId);
    }
  };
  const providerAssociations = useQuery(
    [`${providers.providerAssociationList.queryUrl}`, providerId, 'dashboard'],
    {
      queryFn: fetchProvidersAssociationsList(http.get),
    },
  );

  const updateStatusFilter = React.useCallback(
    (updatedStatusFilter: string) => {
      setStatusFilter(updatedStatusFilter);
      trackMixpanelEvent(MixpanelEventName.STATUS_FILTER_SELECTED, { status: updatedStatusFilter });
    },
    [setStatusFilter],
  );
  React.useEffect(() => {
    logSentryInfo('Dashboard_loaded');
  }, []);

  React.useEffect(() => {
    if (statusFilter !== PATIENT_LIST_VIEW.READY) {
      setSortField('dateAdded');
      setSortDirection('desc');
    }
    else {
      setSortField(lastReadySortField);
      setSortDirection(lastReadySortDirection);
    }
  }, [statusFilter, lastReadySortField, lastReadySortDirection]);

  React.useEffect(() => {
    if (statusFilter !== PATIENT_LIST_VIEW.READY) {
      setSortField('dateAdded');
      setSortDirection('desc');
    }
    else {
      setSortField(lastReadySortField);
      setSortDirection(lastReadySortDirection);
    }
  }, [statusFilter, lastReadySortField, lastReadySortDirection]);

  const refetchPatients = React.useCallback(() => {
    refetchPatientsList();
    refetchPatientsCount();
  }, [refetchPatientsList, refetchPatientsCount]);

  const refetchPatientsCtx = React.useMemo(
    () => ({
      refetchPatients,
    }),
    [refetchPatients],
  );
  const setUserMetadataMutation = useMutation({
    mutationFn: (payload: UserMetadata) => postUserMetadata(http.post, { ...payload }),
  });
  const setAddPatientPreference = React.useCallback(
    (name: UserMetadataFlags, val: string) => {
      setUserMetadataMutation.mutate({
        userId: providerId,
        flag: {
          name,
          value: val,
        },
      });
    },
    [setUserMetadataMutation, providerId],
  );

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setAddPatientPreference(UserMetadataFlags.NumberOfRowsPerPage, event.target.value.toString());
    trackMixpanelEvent(MixpanelEventName.ROWS_PER_PAGE_CHANGED, {
      numberOfRows: event?.target?.value?.toString() ?? '',
    });
    setPage(0);
  };

  const currentPatientCount = patientsCount?.[statusFilter as PATIENT_LIST_VIEW] ?? (0 as number);
  const handleSearch = (value: string) => {
    setPage(0);
    setSearchQuery(value);
    trackMixpanelEvent(MixpanelEventName.QUERY_SEARCHED, {});
  };

  React.useEffect(() => {
    setPage(0);
  }, [searchQuery, statusFilter, providerIdFilter]);
  React.useEffect(() => {
    if (sortField && sortDirection) {
      trackMixpanelEvent(MixpanelEventName.APPLIED_SORTING, { sortField, sortDirection });
    }
  }, [sortField, sortDirection]);
  React.useEffect(() => {
    setExpandedPatientId(null);
  }, [statusFilter, page, searchQuery, sortDirection, sortField]);
  const debouncedSearchQuery = debounce(handleSearch, 500);
  const isSomethingExpanded = expandedPatientId !== null;
  const providerIdFilterList = React.useMemo(() => {
    if (
      providerAssociations.data?.providers?.length
      && providerAssociations.data.providers.length > 0
    ) {
      return [
        {
          id: 'all',
          firstName: 'All',
          lastName: '',
          npiNumber: '',
          email: '',
          designation: '',
        } as PracticeProvider,
        {
          id: providerId,
          firstName: `${user?.name} (You)`,
          lastName: '',
          npiNumber: '',
          email: '',
          designation: '',
        } as PracticeProvider,
        ...providerAssociations.data.providers,
      ];
    }
    else {
      return [];
    }
  }, [providerAssociations, providerId, user?.name]);
  return (
    <RefetchPatientsCtx.Provider value={refetchPatientsCtx}>
      <PageHeader title='Patients' />
      <Grid container>
        <SearchBar onSearch={debouncedSearchQuery} />
        {searchQuery && (
          <Typography sx={{ color: Theme.custom.colors.lightTextPrimary, fontSize: '24px', marginTop: 1 }}>{`Showing results for "${searchQuery}"`}</Typography>
        )}

        <Grid item xs={12}>
          <ListPatientFilters
            statusFilter={statusFilter}
            patientsCount={patientsCount}
            updateStatusFilter={updateStatusFilter}
            isFetching={isFetching}
            providerAssociations={providerIdFilterList}
            setProviderIdFilter={handleProviderIdFilterChange}
            providerIdFilter={providerIdFilter}
          />
        </Grid>
      </Grid>
      <>
        <Box
          sx={{
            paddingLeft: 2,
            backgroundColor: isSomethingExpanded ? '#E9E9E9' : '#FFFFFF',
            paddingRight: 2,
          }}
        >
          <ListPatients
            filteredPatientList={filteredPatientList}
            isError={isError}
            isFetching={isFetching}
            setStatusFilter={setStatusFilter}
            showProvider={!!providerAssociations.data?.providers?.length}
            statusFilter={statusFilter as PATIENT_LIST_VIEW}
            sortField={sortField}
            sortDirection={sortDirection}
            setSortDirection={handleSortDirectionChange}
            setSortField={handleSortFieldChange}
            expandedPatientId={expandedPatientId}
            setExpandedPatientId={handleChangeExpandedPatientId}
          />
        </Box>
        <TablePagination
          sx={{ backgroundColor: '#FFFFFF' }}
          component='div'
          count={currentPatientCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[25, 50, 100]}
        />
      </>
    </RefetchPatientsCtx.Provider>
  );
}
