import * as React from 'react';
import { Box, Card, CardContent, CardHeader, Grid, Stack, Typography } from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { MoodOptions, MoodTrackingList, MoodV2 } from 'pages/Dashboard/types/moods.types';
import {
  getAverageText,
  getFormattedChartData,
  getGaugeColorV2,
  getGaugeDataV2,
  getMoodValueLabel,
} from 'pages/Dashboard/utils/trackingUtils';
import GaugeChart from 'pages/Dashboard/components/GaugeChartV2';
import theme from 'theme';
import SingleLineChart from 'pages/Dashboard/components/SingleLineChart';
import ContentLoader from 'components/Common/ContentLoader';

interface MoodCardProps {
  timeFrame: string;
  cardData: MoodOptions;
  chartData: MoodTrackingList[];
  noData: boolean;
  isMoodLoading: boolean;
  gridSize: number;
}

export default function MoodCard({
  timeFrame,
  cardData,
  chartData,
  noData,
  isMoodLoading,
  gridSize,
}: MoodCardProps): JSX.Element {
  const timeFrameString = getAverageText(timeFrame, false, true);

  const moodDifference = cardData.currentValue - cardData.previousValue;
  const formattedChartData = getFormattedChartData(chartData);

  const renderComparison = () => {
    const isPositive = moodDifference > 0;
    const isZero = moodDifference === 0;

    return (
      <>
        {isPositive ? (
          <ArrowDropUp
            htmlColor={isZero ? 'transparent' : theme.custom.colors.lightTextSecondary}
            fontSize='large'
          />
        ) : (
          <ArrowDropDown
            htmlColor={isZero ? 'transparent' : theme.custom.colors.lightTextSecondary}
            fontSize='large'
          />
        )}
        <Typography variant='body1' color={theme.custom.colors.lightTextSecondary}>
          {getMoodValueLabel(cardData.mood as MoodV2, cardData.previousValue)}
        </Typography>
      </>
    );
  };

  return (
    <Grid item xs={12} md={gridSize} key='mood-card'>
      <Card
        sx={{
          height: '100%',
          borderRadius: '5px',
          overflow: 'visible',
          display: 'flex',
          flexDirection: 'column',
          '& .MuiCardContent-root:last-child': {
            paddingBottom: 1,
          },
        }}
      >
        <CardHeader
          sx={{
            borderRadius: '5px 5px 0 0',
            paddingBottom: 0,
            '& .MuiCardHeader-action': {
              margin: 0,
              alignSelf: 'center',
              cursor: 'pointer',
            },
          }}
          title={(
            <Box display='flex' alignItems='center'>
              <Typography variant='h5' fontWeight='normal'>
                {cardData.label}
                <Typography variant='body1' color={theme.custom.colors.lightTextSecondary}>
                  {timeFrameString}
                </Typography>
              </Typography>
            </Box>
          )}
        />
        <CardContent sx={{
          paddingTop: 0,
        }}
        >
          <ContentLoader
            isFetching={isMoodLoading}
            isError={false}
            noDataText={noData ? 'No data exists for the selected timeframe' : ''}
            minHeight={100}
          >
            <Stack>
              <Box width='100%' textAlign='center'>
                <GaugeChart
                  color={getGaugeColorV2(cardData.mood as MoodV2, cardData.currentValue)}
                  data={getGaugeDataV2(cardData.currentValue)}
                  label={getMoodValueLabel(cardData.mood as MoodV2, cardData.currentValue)}
                />
              </Box>
              {cardData.previousValue > 0 && (
              <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                {renderComparison()}
                <Typography variant='subtitle2' color={theme.custom.colors.lightTextSecondary} mb={1}>
                  (prior
                  {' '}
                  {timeFrame}
                  )
                </Typography>
              </Box>
              )}
              <Box width='100%' mt={3}>
                <SingleLineChart
                  data={formattedChartData}
                  dataKeyX='date'
                  dataKeyLine='value'
                  v2
                  tickFormatter={(value) => (getMoodValueLabel(cardData.mood as MoodV2, value))}
                />
              </Box>
            </Stack>
          </ContentLoader>
        </CardContent>
      </Card>
    </Grid>
  );
}
