import { SourceType } from 'pages/Dashboard/types';
import { GoogleDate } from 'utils/dateUtils';

export enum LegalHistoryType {
    LEGAL_HISTORY_TYPE_UNSPECIFIED = 'LEGAL_HISTORY_TYPE_UNSPECIFIED',
    LEGAL_HISTORY_TYPE_ARRESTED = 'LEGAL_HISTORY_TYPE_ARRESTED',
    LEGAL_HISTORY_TYPE_BANKRUPT= 'LEGAL_HISTORY_TYPE_BANKRUPT',
    LEGAL_HISTORY_TYPE_FRAUD = 'LEGAL_HISTORY_TYPE_FRAUD',
}

export enum EducationLevel {
    EDUCATION_LEVEL_UNSPECIFIED = 'EDUCATION_LEVEL_UNSPECIFIED',
    EDUCATION_LEVEL_HIGH_SCHOOL = 'EDUCATION_LEVEL_HIGH_SCHOOL',
    EDUCATION_LEVEL_HIGH_SCHOOL_GRADUATE = 'EDUCATION_LEVEL_HIGH_SCHOOL_GRADUATE',
    EDUCATION_LEVEL_COLLEGE_NO_DEGREE = 'EDUCATION_LEVEL_COLLEGE_NO_DEGREE',
    EDUCATION_LEVEL_ASSOCIATE_DEGREE = 'EDUCATION_LEVEL_ASSOCIATE_DEGREE',
    EDUCATION_LEVEL_BACHELOR_DEGREE = 'EDUCATION_LEVEL_BACHELOR_DEGREE',
    EDUCATION_LEVEL_GRADUATE_DEGREE = 'EDUCATION_LEVEL_GRADUATE_DEGREE',
}

export enum PatientRelationshipCategory {
    PATIENT_RELATIONSHIP_CATEGORY_UNSPECIFIED = 'PATIENT_RELATIONSHIP_CATEGORY_UNSPECIFIED',
    PATIENT_RELATIONSHIP_CATEGORY_SINGLE = 'PATIENT_RELATIONSHIP_CATEGORY_SINGLE',
    PATIENT_RELATIONSHIP_CATEGORY_PARTNERED = 'PATIENT_RELATIONSHIP_CATEGORY_PARTNERED',
    PATIENT_RELATIONSHIP_CATEGORY_MARRIED = 'PATIENT_RELATIONSHIP_CATEGORY_MARRIED',
    PATIENT_RELATIONSHIP_CATEGORY_DIVORCED = 'PATIENT_RELATIONSHIP_CATEGORY_DIVORCED',
    PATIENT_RELATIONSHIP_CATEGORY_WIDOWED = 'PATIENT_RELATIONSHIP_CATEGORY_WIDOWED'
}

export enum PatientBackgroundCategory {
    PATIENT_BACKGROUND_CATEGORY_UNSPECIFIED = 'PATIENT_BACKGROUND_CATEGORY_UNSPECIFIED',
    PATIENT_BACKGROUND_CATEGORY_RELATIONSHIP = 'PATIENT_BACKGROUND_CATEGORY_RELATIONSHIP',
    PATIENT_BACKGROUND_CATEGORY_EDUCATION = 'PATIENT_BACKGROUND_CATEGORY_EDUCATION',
    PATIENT_BACKGROUND_CATEGORY_LEGAL_HISTORY = 'PATIENT_BACKGROUND_CATEGORY_LEGAL_HISTORY',
}

export interface PatientRelationship {
    id: string;
    relationshipStatus: PatientRelationshipCategory;
    startDate: GoogleDate;
    endDate: GoogleDate;
    current: boolean;
    source: SourceType;
}

interface PatientEducation {
    id: string;
    educationLevel: EducationLevel;
    startDate: GoogleDate;
    endDate: GoogleDate;
    current: boolean;
    source: SourceType;
}

export interface PatientLegalHistory {
    id: string;
    legalHistoryType: LegalHistoryType;
    legalHistory: boolean;
    startDate: GoogleDate;
    endDate: GoogleDate;
    current: boolean;
    source: SourceType;
}

export interface PatientBackground {
    category: PatientBackgroundCategory;
    relationship?: PatientRelationship;
    education?: PatientEducation;
    legalHistory?: PatientLegalHistory;
}

export interface PatientBackgroundAPIResponse {
    patientBackgrounds: PatientBackground[];
}

