import { CacheKeys } from 'utils/constants';
import { isDev } from 'utils/env';
import * as Sentry from '@sentry/react';

export enum levels {
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
}

export interface LogObject {
  action: string;
  level: levels;
  pageUrl: string;
  userID: string;
}

const getLogObject = (level: levels, action: string): LogObject => {
  const userID = localStorage.getItem(CacheKeys.userId) || 'unknown-user';
  return {
    action,
    level,
    pageUrl: `${window.location.hostname}${window.location.pathname}`,
    userID,
  };
};

const handleLogging = (logObject: LogObject, args?: unknown) => {
  if (process.env.REACT_APP_ENV === 'test' || process.env.REACT_APP_ENV === 'ci') {
    return;
  }
  // eslint-disable-next-line no-console
  console[logObject.level](logObject, args);
};

const log = (level: levels) => (action: string, args?: unknown) => {
  const logObject = getLogObject(level, action);
  handleLogging(logObject, args);
};

export const Logger = {
  error: log(levels.ERROR),
  info: log(levels.INFO),
  warn: log(levels.WARN),
};

export const captureException = (err: Error) => {
  if (!isDev) {
    Sentry.captureException(err);
  }
};
