import { MedicationAPIResponse } from 'pages/Dashboard/types/medication.types';
import { QueryFunctionContext } from 'react-query';
import { medicationsUrls } from 'utils/apiUrls';
import { HttpType } from 'utils/http';

export const fetchMedicationList = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<MedicationAPIResponse> {
  const [, providerId, patientId] = queryKey;
  return (
    method(medicationsUrls.listMedications.apiUrls(
      providerId as string,
      patientId as string,
    ).list)
  );
};

export const fetchCurrentMedicationList = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<MedicationAPIResponse> {
  const [, providerId, patientId] = queryKey;
  return (
    method(medicationsUrls.listMedications.apiUrls(
      providerId as string,
      patientId as string,
    ).listCurrent)
  );
};

