import { ProviderConsent } from 'pages/Dashboard/types';
import {
  ProviderConsentResponse,
} from 'pages/Dashboard/types/providers.types';
import { QueryFunctionContext } from 'react-query';
import { providerConsentUrls } from 'utils/apiUrls';
import { HttpType } from 'utils/http';

export const giveProviderConsent = (method: HttpType['post'], providerId: string, payload: ProviderConsent) => method(providerConsentUrls.giveConsent.apiUrl(providerId), payload);

export const fetchProviderConsent = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<ProviderConsentResponse> {
  const [, providerId] = queryKey;

  return method(providerConsentUrls.consent.apiUrl(providerId as string));
};
