import { UserMetadata, UserMetadataFlags, UserMetadataGetPayload } from 'pages/Dashboard/types';
import {
  PracticeProvidersListResponse,
  ProviderDesgination,
} from 'pages/Dashboard/types/providers.types';
import { QueryFunctionContext } from 'react-query';
import { providers } from 'utils/apiUrls';
import { HttpType } from 'utils/http';

export const fetchPracticeProvidersList = (
  method: HttpType['get'],
  practiceId: string,
  designations?: ProviderDesgination[],
) => function async({ queryKey }: QueryFunctionContext): Promise<PracticeProvidersListResponse> {
  return method(
    providers.practiceProvidersList.apiUrl(
        practiceId as string,
        designations?.length ? designations.toString() : '',
    ),
  );
};

export const fetchUserMetadata = (method: HttpType['post'], payload: UserMetadataGetPayload) => function async(): Promise<UserMetadata> {
  return method(providers.metadata.apiUrl(), payload);
};

export const fetchUserMetadataWithKeys = (method: HttpType['post']) => function async({ queryKey }: QueryFunctionContext): Promise<UserMetadata> {
  const [providerId, flag] = queryKey;
  const payload : UserMetadataGetPayload = {
    userId: providerId as string,
    name: flag as UserMetadataFlags,
  };
  return method(providers.metadata.apiUrl(), payload);
};

export const postUserMetadata = (method: HttpType['post'], payload: UserMetadata) => method(providers.postMetadata.apiUrl(), payload);

export const fetchProvidersAssociationsList = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<PracticeProvidersListResponse> {
  const [, providerId] = queryKey;

  return method(providers.providerAssociationList.apiUrl(providerId as string));
};
