import { Box, Button, CardActions, Stack, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import AddConnectionForm from 'pages/Admin/AddConnectionForm';
import * as React from 'react';
import { Connection, CredentialType, Practice, Source } from 'types/Entitites';
import { generateRandomKeys } from 'utils/service';


interface ConnectionFormProps{
  practice: Practice,
  updateProviderConnection: (e: Practice) => void,
  providerConnection: { practices: Practice[]}
}

const defaultConnection = () => ({
  id: generateRandomKeys(),
  source: Source.HealthGorilla,
  type: 'R4',
  credentials: [
    { name: CredentialType.ClientId, value: '' },
    { name: CredentialType.ClientSecret, value: '' },
    { name: CredentialType.Audience, value: '' },
    { name: CredentialType.Issuer, value: '' },
    { name: CredentialType.Sub, value: '' },
  ],
});


export default function PracticeConnectionForm({
  practice,
  updateProviderConnection,
  providerConnection,
}: ConnectionFormProps) {
  const [connections, setConnections] = React.useState<Connection[]>([]);

  React.useEffect(() => {
    const i = providerConnection.practices.findIndex((e: Practice) => e.id === practice.id);
    const storedConnections = providerConnection.practices[i]?.connections || [];
    if (i > -1) {
      setConnections([...storedConnections]);
    }
    else {
      setConnections([defaultConnection()]);
    }
  }, [practice, providerConnection.practices]);

  const addConnection = () => {
    const newConnection = defaultConnection();
    setConnections([
      ...connections,
      newConnection,
    ]);
  };

  const removeConnection = (index: number) => {
    const data = connections;
    data.splice(index, 1);
    setConnections([...data]);
  };

  const setConnectionData = (connection: Connection, idx: number) => {
    const data = connections;
    data.splice(idx, 1, connection);
    setConnections([
      ...data,
    ]);
  };

  const hasValidConnections = React.useCallback(() => {
    if (connections.length === 0) {
      return false;
    }

    let valid = true;

    connections.forEach((conn) => {
      if (!conn.id || !conn.source || !conn.type) {
        valid = false;
      }

      conn.credentials.forEach((cred) => {
        if (!cred.value) {
          valid = false;
        }
      });
    });

    return valid;
  }, [connections]);

  const onSave = () => {
    updateProviderConnection({
      id: practice.id,
      name: practice.name,
      address: practice.address,
      action: 1,
      connections: [...connections],
    });
  };

  const onSaveWithoutCredentials = () => {
    updateProviderConnection({
      id: practice.id,
      name: practice.name,
      address: practice.address,
      action: 1,
      connections: [],
    });
  };

  return (
    <Box>
      <Card>
        <CardContent>
          <Stack spacing={2}>
            <Typography variant='h6'>{practice.name}</Typography>
            <Stack spacing={2}>
              <Typography>Connections</Typography>
              <Stack spacing={10}>
                {
                  connections.map((connection, idx) => (
                    <AddConnectionForm
                      key={connection.id}
                      idx={idx}
                      connection={connection}
                      addConnection={addConnection}
                      removeConnection={removeConnection}
                      setConnectionData={setConnectionData}
                      practice={practice}
                      showButton={idx !== connections.length - 1}
                      showRemove={connections.length > 1}
                    />
                  ))
                }
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
          <Button
            onClick={onSave}
            variant='outlined'
            disabled={!hasValidConnections()}
          >
            Save
          </Button>
          <Button
            onClick={onSaveWithoutCredentials}
            variant='outlined'
          >
            Save without credentials
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}
