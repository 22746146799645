import * as React from 'react';
import { CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import SectionHeader from 'components/Common/SectionHeader';
import DiagnosisCard from 'pages/Dashboard/components/DiagnosisCard';
import MedicationCard from 'pages/Dashboard/components/MedicationCard';
import ProvidersCard from 'pages/Dashboard/components/ProvidersCard';
import { Diagnosis, DiagnosisAPIResponse } from 'pages/Dashboard/types/diagnosis.types';
import ShowMoreDrawer from 'pages/Dashboard/ShowMoreDrawer';
import { MedicationAPIResponse, PatientMedication } from 'pages/Dashboard/types/medication.types';
import { UseQueryResult } from 'react-query';
import {
  ListMentalHealthProvidersAPIResponse,
  ListPrimaryCareProvidersAPIResponse,
  ProvidersAPIResponse,
} from 'pages/Dashboard/types/providers.types';

enum EDrawerItem {
  CURRENT_MEDICATIONS = 'Current medications',
  CURRENT_PROVIDERS = 'Current providers',
  CURRENT_DIAGNOSES = 'Current diagnoses',
}

function getFilteredDiagnosis(patientDiagnosis?: Diagnosis[]) {
  return patientDiagnosis?.filter((diagnosis) => !diagnosis.stopped);
}

interface ICurrentRegimeProps {
  currentDiagnosis: UseQueryResult<DiagnosisAPIResponse>;
  currentMedications: UseQueryResult<MedicationAPIResponse>;
  currentProviders: UseQueryResult<ProvidersAPIResponse>;
  currentPrimaryCareProviders: UseQueryResult<ListPrimaryCareProvidersAPIResponse>;
  currentMentalHealthProviders: UseQueryResult<ListMentalHealthProvidersAPIResponse>;
}

export default function CurrentRegimen({
  currentDiagnosis,
  currentMedications,
  currentProviders,
  currentPrimaryCareProviders,
  currentMentalHealthProviders,
}: ICurrentRegimeProps) {
  const { data: currentDiagnosisResponse, isFetching: diagnosisFetching } = currentDiagnosis;
  const { data: currentMedicationResponse, isFetching: medicationFetching } = currentMedications;
  const { data: currentProvidersResponse, isFetching: currentProvidersFetching } = currentProviders;
  const {
    data: currentPrimaryCareProvidersResponse,
    isFetching: currentPrimaryCareProvidersFetching,
  } = currentPrimaryCareProviders;

  const {
    data: currentMentalHealthProvidersResponse,
    isFetching: currentMentalHealthProvidersFetching,
  } = currentMentalHealthProviders;

  const providersFetching = currentProvidersFetching
    || currentPrimaryCareProvidersFetching
    || currentMentalHealthProvidersFetching;

  const [drawerItem, setDrawerItem] = React.useState('');
  const [drawerData, setDrawerData] = React.useState<PatientMedication[] | Diagnosis[]>([]);

  const closeDrawer = React.useCallback(() => {
    setDrawerItem('');
  }, [setDrawerItem]);

  const drawerChildProps = {
    drawerItem,
    closeDrawer,
    drawerMode: true,
  };

  const drawerTriggerProp = {
    drawerItem,
    closeDrawer,
    drawerMode: false,
  };

  const onShowMoreMedication = (data: PatientMedication[]) => {
    setDrawerItem(EDrawerItem.CURRENT_MEDICATIONS);
    setDrawerData(data);
  };

  const onShowMoreDiagnosis = (data: Diagnosis[]) => {
    setDrawerItem(EDrawerItem.CURRENT_DIAGNOSES);
    setDrawerData(data);
  };

  return (
    <>
      <ShowMoreDrawer drawerItem={drawerItem} closeDrawer={closeDrawer}>
        {drawerItem === EDrawerItem.CURRENT_DIAGNOSES ? (
          <DiagnosisCard
            {...drawerChildProps}
            diagnosis={currentDiagnosisResponse?.patientDiagnosis}
          />
        ) : null}
        {drawerItem === EDrawerItem.CURRENT_MEDICATIONS ? (
          <MedicationCard
            isCurrentRegime
            showTabs={false}
            {...drawerChildProps}
            medication={drawerData as PatientMedication[]}
          />
        ) : null}
        {drawerItem === EDrawerItem.CURRENT_PROVIDERS ? (
          <ProvidersCard
            patientProviders={currentProvidersResponse?.patientProviders}
            primaryCareProviders={currentPrimaryCareProvidersResponse?.primaryCareProviders}
            mentalHealthProviders={currentMentalHealthProvidersResponse?.mentalHealthProviders}
            {...drawerChildProps}
          />
        ) : null}
      </ShowMoreDrawer>
      <SectionHeader title='Current regimen' />
      <Grid container spacing={1}>
        {medicationFetching ? (
          <CircularProgress />
        ) : (
          <MedicationCard
            {...drawerTriggerProp}
            onShowMoreMedication={onShowMoreMedication}
            medication={currentMedicationResponse?.patientMedications}
            showTabs={false}
            isCurrentRegime
          />
        )}
        {providersFetching ? (
          <CircularProgress />
        ) : (
          <ProvidersCard
            patientProviders={currentProvidersResponse?.patientProviders}
            primaryCareProviders={currentPrimaryCareProvidersResponse?.primaryCareProviders}
            mentalHealthProviders={currentMentalHealthProvidersResponse?.mentalHealthProviders}
            onShowMore={() => setDrawerItem(EDrawerItem.CURRENT_PROVIDERS)}
            {...drawerTriggerProp}
          />
        )}
        {diagnosisFetching ? (
          <CircularProgress />
        ) : (
          <DiagnosisCard
            {...drawerTriggerProp}
            diagnosis={getFilteredDiagnosis(currentDiagnosisResponse?.patientDiagnosis)}
            showTabs={false}
            onShowMoreDiagnosis={onShowMoreDiagnosis}
          />
        )}
      </Grid>
    </>
  );
}
