import React, { useEffect } from 'react';
import { Tooltip, Box, styled, keyframes } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { SideNavCtx } from 'components/Layouts/constants';

const bounceKeyframe = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
`;

// Styled components
const IndicatorContainer = styled(Box)((props: { delay: string }) => ({
  position: 'fixed',
  bottom: 20,
  right: 20,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  animation: `${bounceKeyframe} 0.6s ease`,
  animationDelay: props.delay,
  animationFillMode: 'forwards',
  zIndex: 1000,

  '&:hover': {
    animation: 'none',
  },
}));

const IndicatorCircle = styled(Box)(() => ({
  width: 48,
  height: 48,
  backgroundColor: 'grey',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

function ScrollIndicator() {
  const { isScrolledToBottom } = React.useContext(SideNavCtx);
  const [delay, setDelay] = React.useState('3s');

  useEffect(
    () => {
      let timeInterval: NodeJS.Timeout;
      if (!isScrolledToBottom) {
        timeInterval = setTimeout(() => {
          setDelay('8.6s');
        }, 3600);
      }
      else {
        setDelay('3s');
      }

      return () => {
        clearTimeout(timeInterval);
      };
    },
    [isScrolledToBottom],
  );

  if (isScrolledToBottom) {
    return null;
  }

  return (
    <IndicatorContainer delay={delay}>
      <Tooltip title='Scroll down for more' arrow>
        <IndicatorCircle>
          <ArrowDownwardIcon fontSize='large' style={{ color: '#fff' }} />
        </IndicatorCircle>
      </Tooltip>
    </IndicatorContainer>
  );
}

export default ScrollIndicator;
