import React from 'react';
import TextField from '@mui/material/TextField';
import { Controller, Control, RegisterOptions, FieldValues } from 'react-hook-form';
import { useTheme } from '@mui/material';

interface InputProps {
  name: string;
  control: Control;
  label: string;
  required?: boolean;
  placeholder?: string;
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
}

export const formatPhoneNumber = (inputPhoneNumber: string) => {
  // Remove non-numeric characters from the input
  const numericValue = inputPhoneNumber.replace(/\D/g, '');

  // Ensure the inputPhoneNumber does not exceed 10 digits
  const formattedValue = numericValue.slice(0, 10);

  // Format the phone number as (XXX)XXX-XXXX
  if (formattedValue.length >= 1 && formattedValue.length < 4) {
    return `(${formattedValue.substring(0, 3)}`;
  }
  if (formattedValue.length >= 4 && formattedValue.length < 7) {
    return `(${formattedValue.substring(0, 3)})${formattedValue.substring(3, 6)}`;
  }
  if (formattedValue.length >= 7) {
    return `(${formattedValue.substring(0, 3)})${formattedValue.substring(
      3,
      6,
    )}-${formattedValue.substring(6, 10)}`;
  }

  return formattedValue;
};

export default function PhoneNumberInput({
  name,
  control,
  label,
  required,
  placeholder,
  rules = {},
}: InputProps) {
  const theme = useTheme();
  return (
    <Controller
      name={name}
      control={control}
      rules={{ minLength: { value: 13, message: 'Invalid phone number' }, ...rules }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          helperText={error ? error.message : ''}
          error={!!error}
          FormHelperTextProps={{ style: { fontSize: theme.typography.body2.fontSize } }}
          onChange={(e) => {
            const cursorPosition = e.target.selectionStart ?? 0; // Get the current cursor position
            const originalLength = e.target.value.length;

            e.target.value = formatPhoneNumber(e.target.value);
            onChange(e);

            // Calculate the new cursor position
            const newLength = e.target.value.length;
            const cursorOffset = newLength - originalLength;

            // Set the cursor to the right position
            requestAnimationFrame(() => {
              e.target.setSelectionRange(
                cursorPosition + cursorOffset,
                cursorPosition + cursorOffset,
              );
            });
          }}
          value={value}
          fullWidth
          autoComplete='off'
          label={label}
          variant='outlined'
          sx={{
            'div[data-lastpass-icon-root]': {
              display: 'none',
            },
          }}
          required={required}
          inputProps={{ 'data-lpignore': 'true' }}
          placeholder={placeholder}
        />
      )}
    />
  );
}
