import { Card, CardContent, Grid, Typography } from '@mui/material';
import CardHeader from 'pages/Dashboard/components/CardHeader';
import NoData from 'pages/Dashboard/components/NoData';
import HealthRecordCategories from 'pages/Dashboard/HealthRecordCategories';
import { ShowMoreButton, ShowMoreDrawerChildProps, ShowMoreDrawerTitle, sliceForShowMore } from 'pages/Dashboard/ShowMoreDrawer';
import { Allergy } from 'pages/Dashboard/types/allergies.types';
import * as React from 'react';
import theme from 'theme';
import { googleTimestampToDateString } from 'utils/dateUtils';


interface AllergiesCardProps extends ShowMoreDrawerChildProps{
  allergies?: Allergy[];
}

interface AllergyDetaisProps {
  allergies: Allergy[];
}

function AlleryDetails({ allergies }: AllergyDetaisProps) : JSX.Element {
  const nonDatedAllergies = allergies.filter((allergy) => !allergy.started);

  return (
    <>
      {
       allergies.map((currentAllergy) => {
         if (!currentAllergy.started) return null;
         return (
           <React.Fragment key={`health-history-${currentAllergy.id}`}>
             <Typography variant='body1' color={theme.custom.colors.lightTextSecondary}>
               {googleTimestampToDateString(currentAllergy.started)}
             </Typography>
             <Typography>{currentAllergy.substance}</Typography>
             <Typography variant='subtitle1' color={theme.custom.colors.lightTextSecondary} mb={2}>
               {currentAllergy.reactions?.map((r) => `${r.name} ${r.severity ? `(${r.severity})` : ''}`).join(', ')}
             </Typography>
           </React.Fragment>
         );
       })
      }
      {nonDatedAllergies.length ? (
        <>
          <Typography mb={2} variant='body1' color={theme.custom.colors.lightTextSecondary}>
            Unknown dates
          </Typography>
          {
            nonDatedAllergies.map((currentAllergy) => (
              <React.Fragment key={`health-history-${currentAllergy.id}`}>
                <Typography>{currentAllergy.substance}</Typography>
                <Typography variant='subtitle1' color={theme.custom.colors.lightTextSecondary} mb={2}>
                  {currentAllergy.reactions?.map((r) => `${r.name} ${r.severity ? `(${r.severity})` : ''}`).join(', ')}
                </Typography>
              </React.Fragment>
            ))
          }
        </>
      ) : null}
    </>
  );
}

export default function AllergiesCard({
  closeDrawer,
  drawerItem,
  drawerMode = false,
  allergies = [],
  onShowMore,
}: AllergiesCardProps): JSX.Element {
  const datedAllergies = allergies.filter((allergy) => allergy.started);
  const nonDatedAllergies = allergies.filter((allergy) => !allergy.started)
    .sort((a, b) => (a.substance).localeCompare(b.substance));

  const sortedAllergies = [...datedAllergies, ...nonDatedAllergies];
  const data = sliceForShowMore(sortedAllergies, !drawerMode);

  return (
    <Grid item xs={12} md={!drawerMode ? 4 : undefined} key='health-history-allergies'>
      <Card sx={{ height: '100%' }}>
        {!drawerMode && (
          <CardHeader
            icon={HealthRecordCategories.diagnosis.value.icon}
            title='Allergies'
          />
        )}
        {drawerMode && <ShowMoreDrawerTitle closeDrawer={closeDrawer} drawerItem={drawerItem} />}

        <CardContent>
          {!allergies.length ? (
            <NoData />
          ) : (
            <AlleryDetails allergies={data} />
          )}
          <ShowMoreButton
            isDrawer={drawerMode}
            onShowMore={onShowMore as VoidFunction}
            items={allergies}
          />
        </CardContent>
      </Card>
    </Grid>
  );
}
