import MobileFriendly from '@mui/icons-material/MobileFriendly';
import Add from '@mui/icons-material/Add';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Downloading from '@mui/icons-material/Downloading';
import Home from '@mui/icons-material/Home';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import * as spaUrls from 'utils/spaUrls';
import React from 'react';
import { Preview } from '@mui/icons-material';
import { Link } from 'react-router-dom';

interface AddPatientSuccessProps {
  onAddAnotherPatientClick: VoidFunction;
}

export default function AddPatientSuccess({ onAddAnotherPatientClick }: AddPatientSuccessProps) {
  return (
    <Grid container spacing={2} textAlign='center'>
      <Grid item xs={12}>
        <Box display='flex' flexDirection='row' justifyContent='center'>
          <CheckCircle color='success' fontSize='medium' />
          <Typography variant='h5'>&nbsp;Success</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h5'>&nbsp;What&apos;s Next?</Typography>
      </Grid>
      <Box display='flex' flexDirection='column' alignItems='center' width='100%'>
        <Grid item xs={12}>
          <Box display='flex' flexDirection='row' justifyContent='center'>
            <Downloading color='primary' fontSize='small' />
            <Typography variant='body1'>
              &nbsp;We&apos;ll start pulling the patient&apos;s health history now
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display='flex' flexDirection='row' justifyContent='center'>
            <MobileFriendly color='primary' fontSize='small' />
            <Typography variant='body1'>
              &nbsp;The patient has been sent instructions to download our mobile app and complete
              an initial health assessment
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display='flex' flexDirection='row' justifyContent='center'>
            <Preview color='primary' fontSize='small' />
            <Typography variant='body1'>
              When ready, you can view their information from the home page
            </Typography>
          </Box>
        </Grid>
      </Box>
      <Grid item xs={12}>
        <Button
          startIcon={<Add />}
          onClick={onAddAnotherPatientClick}
          variant='contained'
          color='primary'
        >
          Add Another Patient
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          component={Link}
          to={spaUrls.mainNav.dashboard}
          startIcon={<Home />}
          variant='outlined'
          color='primary'
        >
          Return Home
        </Button>
      </Grid>
    </Grid>
  );
}
