import * as React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { PATIENT_LIST_VIEW } from 'pages/Dashboard/enums';
import { mainNav } from 'utils/spaUrls';
import { Link } from 'react-router-dom';

interface TProps {
    statusFilter: string;
    lastNameFilter: string | undefined,
    alphabetFilterList: string[];
    patientsCount: {[key in PATIENT_LIST_VIEW]: number};
    setLastNameFilter: React.Dispatch<string>
    updateStatusFilter: React.Dispatch<string>
    isFetching: boolean;
}

export default function ListPatientFiltersOld({
  statusFilter,
  lastNameFilter,
  alphabetFilterList,
  setLastNameFilter,
  patientsCount,
  updateStatusFilter,
  isFetching,
}: TProps) {
  const alphabets = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

  return (
    <>
      <Grid container spacing={2} marginBottom={1}>
        {Object.values(PATIENT_LIST_VIEW).map((filter) => (
          <Grid item key={filter}>
            <Button
              component={Link}
              to={`${mainNav.dashboard}?status=${filter}`}
              color='primary'
              onClick={() => updateStatusFilter(filter)}
              variant={statusFilter === filter ? 'outlined' : 'text'}
              disabled={isFetching}
              data-testid={`status-filter-${filter}`}
            >
              {`${filter} (${patientsCount[filter] || 0})`}
            </Button>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={1}>
        {alphabets.map((alphabet) => (
          <Grid item key={alphabet}>
            <Button
              color='primary'
              disabled={!alphabetFilterList.includes(alphabet) || isFetching}
              key={alphabet}
              variant={lastNameFilter === alphabet ? 'outlined' : 'text'}
              onClick={() => setLastNameFilter(alphabet !== lastNameFilter ? alphabet : '')}
              sx={{
                minWidth: '9px',
              }}
              data-testid={`alphabetic-filter-${alphabet}`}
            >
              {alphabet}
            </Button>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
