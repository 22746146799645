import Warning from '@mui/icons-material/WarningRounded';
import { Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';


export default function EmailAlreadySignedUp() {
  return (
    <Box display='flex' justifyContent='center'>
      <Stack alignItems='center' spacing={4}>
        <Box display='flex' gap={1} alignItems='center'>
          <Warning fontSize='large' color='warning' />
          <Typography>Error!</Typography>
        </Box>
        <Typography>
          This email address was already
          {' '}
          used to sign up with Headlamp Health.
        </Typography>
        <Box textAlign='center'>
          <Typography>
            Please allow up to 24 hours for verification.
          </Typography>
          <Typography mt={1}>
            Once verified, we will email you to activate your account.
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
}
