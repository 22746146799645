import TTransactionsResponse from 'pages/Rewards/types/transactions.types';
import { QueryFunctionContext } from 'react-query';
import { rewardsUrls } from 'utils/apiUrls';
import { HttpType } from 'utils/http';

const fetchTransactions = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<TTransactionsResponse> {
  const [, providerId] = queryKey;

  return method(rewardsUrls.transactions.apiUrl(providerId as string));
};

export default fetchTransactions;
