export enum PLACE_DETAILS {
    STREET_NUMBER = 'street_number',
    STREET = 'route',
    CITY = 'locality',
    STATE = 'administrative_area_level_1',
    ZIP_CODE = 'postal_code',
    FORMATTED_ADDRESS = 'formatted_address',
    COUNTRY = 'country',
}


export type AddressValue = {
    longName: string
    shortName: string
  } | null

export type Address = {
    streetNumber?: AddressValue
    street?: AddressValue
    city?: AddressValue
    state?: AddressValue
    zipCode?: AddressValue
    formattedAddress?: string
    country?: string
  }


export interface ResultItem{
    id?: string,
    label: string
}


export type Name = {
  long_name: string,
  short_name: string
}
