import * as React from 'react';
import { Grid, TableCell, Typography } from '@mui/material';
import { NotSpecified } from 'utils/constants';
import { DiscColors, DiscIdentifyingTerms } from 'pages/Dashboard/utils/whoiamUtils';
import {
  DISCKeys,
  PatientPersonalityTestResultTextProps,
} from 'pages/Dashboard/types/whoiam.types';

export default function DiscInfoText({
  result,
  isSummary = false,
}: PatientPersonalityTestResultTextProps): JSX.Element {
  function combineTerms(resultDescription: string) {
    if (!resultDescription) {
      return '';
    }

    const termLetters: DISCKeys[] = resultDescription.split('/') as DISCKeys[];

    return termLetters.reduce((acc, letter) => {
      const term = DiscIdentifyingTerms[letter];
      if (term) {
        if (acc) {
          return `${acc}, ${term}`;
        }
        return term;
      }
      return acc;
    }, '').trim();
  }


  const metaInfo = result && JSON.parse(result?.metaInfo as string);
  const Container = isSummary ? TableCell : React.Fragment;
  return (
    <Container>
      {result ? (
        <Grid container>
          <Grid item display='flex' xs={12} gap={1}>
            <Typography color={DiscColors.D}>(D)</Typography>
            <Typography>{`${metaInfo.percents.D}%`}</Typography>
            <Typography color={DiscColors.I}>(I)</Typography>
            <Typography>{`${metaInfo.percents.I}%`}</Typography>
            <Typography color={DiscColors.S}>(S)</Typography>
            <Typography>{`${metaInfo.percents.S}%`}</Typography>
            <Typography color={DiscColors.C}>(C)</Typography>
            <Typography>{`${metaInfo.percents.C}%`}</Typography>
          </Grid>
          <Grid item>
            <Typography>{combineTerms(result?.description)}</Typography>
          </Grid>
        </Grid>
      ) : (
        <Typography>{NotSpecified}</Typography>
      )}
    </Container>
  );
}
