import mixpanel from 'mixpanel-browser';
import { MixpanelEventName } from 'utils/constants';
import * as Sentry from '@sentry/react';


export const logSentryInfo = (
  message: string,
  tags?: Record<string, string>,
) => {
  Sentry.withScope((scope) => {
    scope.setTags({
      ...tags ?? {},
    });
    scope.setLevel('info');
    Sentry.captureMessage(message);
  });
};

export const trackMixpanelEvent = (
  eventName: MixpanelEventName,
  payload?: Record<string, string | Record<string, string>>,
  trackSentryInfo = false,
) => {
  mixpanel.track(eventName, { 'datetime clicked': new Date().toISOString(), ...payload });
  if (trackSentryInfo) {
    logSentryInfo(eventName, payload as Record<string, string>);
  }
};

export const getBrowserName = () => {
  const { userAgent } = navigator;
  let browserName = 'Unknown';

  if (userAgent.indexOf('Firefox') > -1) {
    browserName = 'Firefox';
  }
  else if (userAgent.indexOf('Trident') > -1) {
    browserName = 'Internet Explorer';
  }
  else if (userAgent.indexOf('Edge') > -1) {
    browserName = 'Edge';
  }
  else if (userAgent.indexOf('Chrome') > -1) {
    browserName = 'Chrome';
  }
  else if (userAgent.indexOf('Safari') > -1) {
    browserName = 'Safari';
  }

  return browserName;
};

export default trackMixpanelEvent;
