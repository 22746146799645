import { SourceType } from 'pages/Dashboard/types';
import { GoogleDate } from 'utils/dateUtils';

export interface Doctor {
  id: string;
  name: string;
  designation: string;
  npiNumber: number;
  current: boolean;
}

export enum DiagnosisCategory {
  DIAGNOSIS_CATEGORY_UNSPECIFIED = 'DIAGNOSIS_CATEGORY_UNSPECIFIED',
  DIAGNOSIS_CATEGORY_PSYCH = 'DIAGNOSIS_CATEGORY_PSYCH',
  DIAGNOSIS_CATEGORY_OTHER = 'DIAGNOSIS_CATEGORY_OTHER',
}

export type DiagnosisCode = { code: string; codeType: string };

export interface Diagnosis {
  id: string;
  type: string;
  status: string;
  diagnosis: {
    id: string;
    name: string;
    displayName: string;
    codes: DiagnosisCode[];
  };
  started: GoogleDate | null;
  stopped: GoogleDate | null;
  doctor: Doctor | null;
  category: DiagnosisCategory;
  source: SourceType;
}

export interface DiagnosisAPIResponse {
  patientDiagnosis: Diagnosis[];
}

