import React, { useMemo } from 'react';
import { useMutation } from 'react-query';
import { sendScaleSuggestions } from 'pages/ScalesManifest/services/scales-manifest.services';
import { useHttp } from 'hooks/use-fetch';
import { useParams } from 'react-router-dom';
import { TSuggestion } from 'pages/ScalesManifest/types/scales-manifest.types';
import { Category } from 'pages/Dashboard/types/whoiam.types';
import { Dialog } from 'components/Common/Dialog';
import {
  Checkbox,
  // Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { MixpanelEventName } from 'utils/constants';
import { trackMixpanelEvent } from 'utils/utilMethods';

interface ConfigureScalesDialogProps {
  open: boolean;
  scales: Category[];
  onClose: () => void;
}

export function ConfigureScalesDialog({
  open,
  scales,
  onClose,
}: ConfigureScalesDialogProps): JSX.Element {
  const { http, providerId } = useHttp();
  const { id: patientId } = useParams();
  const [suggestScales, setSuggestScales] = React.useState({} as Record<string, boolean>);
  // const [enableScales, setEnableScales] = React.useState({} as Record<string, boolean>);
  const [isEnableScaleChanged, setIsEnableScaleChanged] = React.useState(false);

  const allSelected = useMemo(() => scales.every(
    (scale) => suggestScales[scale.id] ?? false,
  ), [scales, suggestScales]);

  const numberOfSelectedRows = useMemo(() => Object.values(
    suggestScales,
  ).filter(Boolean).length, [suggestScales]);

  const resetAndClose = () => {
    setSuggestScales({});
    // setEnableScales({});
    setIsEnableScaleChanged(false);
    onClose();
  };

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (suggestions: TSuggestion[]) => sendScaleSuggestions(http.post, patientId ?? '', providerId, suggestions),
    onSettled: () => {
      resetAndClose();
    },
  });

  const onSuggest = async () => {
    trackMixpanelEvent(MixpanelEventName.SCALES_SUGGESTED, {
      scales: Object.entries(suggestScales).map(([categoryId, isSelected]) => (isSelected ? categoryId : null)).join(', '),
    });
    const suggestions = Object.entries(suggestScales).reduce((acc, [categoryId, isSelected]) => {
      if (isSelected) {
        acc.push({
          category_id: categoryId,
          action: 'ATTEMPT' as const,
        });
      }
      return acc;
    }, [] as TSuggestion[]);
    mutateAsync(suggestions);
  };

  const onEnabled = async () => null;

  const handleSuggestScalesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;
    if (name === 'select-all') {
      setSuggestScales(Object.fromEntries(scales.map((category) => [category.id, checked])));
    }
    else {
      setSuggestScales((prev) => ({
        ...prev,
        [event.target.name]: checked,
      }));
    }
  };

  // const handleEnableScalesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const isChecked = event.target.checked;
  //   setEnableScales((prev) => ({
  //     ...prev,
  //     [event.target.name]: isChecked,
  //   }));
  //   setIsEnableScaleChanged(true);
  // };

  return (
    <Dialog
      open={open}
      size='md'
      title='Suggest Scales'
      actionBtnLabel='SUGGEST SCALE(S)'
      actionBtnLoading={isLoading}
      actionBtnDisabled={!Object.values(suggestScales).some((isSelected) => isSelected)}
      secondaryActionBtnDisabled={!isEnableScaleChanged}
      // secondaryActionBtnLabel='UPDATE CONFIGURATION'
      secondaryActionBtnLoading={isLoading}
      secondaryActionBtnHandler={onEnabled}
      actionBtnHandler={onSuggest}
      onClose={resetAndClose}
      renderContent={() => (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align='left'>
                  <Checkbox
                    color='primary'
                    indeterminate={numberOfSelectedRows > 0 && numberOfSelectedRows < scales.length}
                    name='select-all'
                    checked={allSelected}
                    onChange={handleSuggestScalesChange}
                  />
                </TableCell>
                <TableCell width='100%'>Scale</TableCell>
                {/* <TableCell align='center'>Enable</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {scales.map((category) => (
                <TableRow key={category.id}>
                  {/* <TableCell align='center'>
                    <Switch
                      name={category.id}
                      checked={enableScales[category.id] ?? true}
                      onChange={handleEnableScalesChange}
                    />
                  </TableCell> */}
                  <TableCell align='left'>
                    <Checkbox
                      color='primary'
                      name={category.id}
                      checked={suggestScales[category.id] ?? false}
                      onChange={handleSuggestScalesChange}
                    />
                  </TableCell>
                  <TableCell width='100%'>
                    {category.description}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    />
  );
}

export default ConfigureScalesDialog;
