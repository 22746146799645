import { Box, Typography } from '@mui/material';
import { convertDecimalToHoursMinutes } from 'pages/Dashboard/components/AppleHealthSleepChart';
import { SleepCategory, SleepCategoryMap } from 'pages/Dashboard/types/waypoints.types';
import { TimeFrameOptions, getBarProps, getTickFormat } from 'pages/Dashboard/utils/trackingUtils';
import React, { useMemo } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import ChartAxisLabel from 'pages/Dashboard/components/ChartAxisLabel';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';

type CustomTooltipProps = {
  timeFrame: string;
  selectedCategories: Record<SleepCategory, boolean>;
  payload?: Payload<string | number | (string | number)[], string | number>[] | undefined;
  active?: boolean;
};

function CustomTooltip({ active, payload, timeFrame, selectedCategories }: CustomTooltipProps) {
  if (active && payload?.[0]?.payload) {
    const data = payload[0].payload;
    const label = data.date;
    const isInDays = [TimeFrameOptions.LAST_7_DAYS, TimeFrameOptions.LAST_30_DAYS].includes(
      timeFrame as TimeFrameOptions,
    );
    const { day, date, year } = getTickFormat(label, isInDays, 'MMM d');
    const finalLabel = isInDays ? `${day}, ${date}` : `${date}, ${year}`;

    return (
      <div
        style={{
          background: 'white',
          border: '1px solid #f5f5f5',
          padding: '10px',
          borderRadius: '5px',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography>{finalLabel}</Typography>
        {Object.keys(SleepCategoryMap).map((key: string) => {
          const value = convertDecimalToHoursMinutes(data[key as SleepCategory], key, '700', true);
          const isValid = (key !== SleepCategory.INBED_TIME || !data[SleepCategory.ASLEEP_TIME])
            && selectedCategories[key as SleepCategory];
          return (
            isValid && value ? (
              <Typography color={SleepCategoryMap[key as SleepCategory]?.color} marginTop={2}>
                <Typography>{SleepCategoryMap[key as SleepCategory]?.label}</Typography>
                <Typography display='flex'>
                  <Typography variant='h6' lineHeight='100%'>
                    {value}
                  </Typography>
                </Typography>
              </Typography>
            ) : null);
        })}
      </div>
    );
  }

  return null;
}

function StackedBarChart({
  data,
  timeFrame,
  selectedCategories,
  yAxisProps: {
    ticks,
    startTime,
    endTime,
    isInDays,
  },
}: {
  selectedCategories: Record<SleepCategory, boolean>;
  data: Record<string, string | number>[];
  timeFrame: string;
  yAxisProps: {
    ticks: number[];
    startTime: number;
    endTime: number;
    isInDays: boolean;
  };
}) {
  const [max, setMax] = React.useState<number | undefined>(undefined);

  const isAsleepCategorySelected = React.useMemo(
    () => Object.keys(selectedCategories).some(
      (key) => SleepCategoryMap[key as SleepCategory]?.isAsleepCategory
          && selectedCategories[key as SleepCategory],
    ),
    [selectedCategories],
  );

  const restOfCategories = Object.keys(SleepCategoryMap).reduce((acc, key) => {
    if (key === SleepCategory.ASLEEP_TIME && isAsleepCategorySelected) {
      return acc;
    }

    if (!SleepCategoryMap[key as SleepCategory].isAsleepCategory
      && selectedCategories[key as SleepCategory]) {
      acc.unshift(key);
    }

    return acc;
  }, [] as string[]);

  const asleepCategories = Object.keys(SleepCategoryMap).reduce((a, key) => {
    if (SleepCategoryMap[key as SleepCategory].isAsleepCategory && isAsleepCategorySelected) {
      if (selectedCategories[key as SleepCategory]) {
        a.unshift(key);
      }
      else {
        a.push(key);
      }
    }
    return a;
  }, [] as string[]);

  const hasData = useMemo(() => data.length > 0, [data]);

  return (
    <Box sx={{ position: 'relative' }}>
      {!hasData && (
      <Box sx={{
        position: 'absolute',
        top: 'calc((100% - 30px)/2)',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        padding: 2,
        background: 'white',
        zIndex: 1,
      }}
      >
        no data exists
      </Box>
      )}
      <ResponsiveContainer width='100%' height={325}>
        <BarChart
          data={data}
          barGap={0}
          margin={{ left: 0, bottom: 0, top: 0, right: 5 }}
          {...getBarProps(timeFrame)}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            dataKey='date'
            type='number'
            domain={[startTime, endTime]}
            tick={<ChartAxisLabel isInDays={isInDays} />}
            tickFormatter={() => ''}
            ticks={ticks}
            height={30}
            tickLine={false}
            tickSize={2}
            allowDataOverflow
          />
          <YAxis
            domain={[0, (m: number) => {
              if (!hasData) { return 10; }
              if (!max) setMax(m);
              return Math.ceil(max ?? m);
            }]}
            tick={{ dy: 2 }}
            tickFormatter={(hr: number) => `${hr} hr`}
            width={30}
            tickLine={false}
            tickSize={2}
            fontSize={12}
          />
          <Tooltip
            content={(
              <CustomTooltip
                timeFrame={timeFrame}
                selectedCategories={selectedCategories}
              />
            )}
            cursor={false}
            shared={false}
          />
          {[...asleepCategories, ...restOfCategories].map((key) => {
            const { isAsleepCategory } = SleepCategoryMap[key as SleepCategory];
            const hide = isAsleepCategory
              ? !selectedCategories[SleepCategory.ASLEEP_TIME]
            || !selectedCategories[key as SleepCategory]
              : !selectedCategories[key as SleepCategory];
            const color = hide
              ? SleepCategoryMap[SleepCategory.ASLEEP_TIME].color
              : SleepCategoryMap[key as SleepCategory].color;
            return (
              <Bar
                key={key}
                dataKey={key}
                stackId='a'
                fill={color}
                stroke={color}
                isAnimationActive={false}
                hide={hide && !selectedCategories[SleepCategory.ASLEEP_TIME]}
              />
            );
          })}
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}

export default StackedBarChart;
