import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import theme from 'theme';
import { Grid } from '@mui/material';
import RewardsContext, { TComponent } from 'pages/Rewards/context/RewardsContext';
import ReferralForm, { IReferralFormProps } from 'pages/Rewards/components/ReferralForm';
import DrawerHeader from 'pages/Rewards/components/DrawerHeader';
import DrawerContent from 'pages/Rewards/components/DrawerContent';
import QuestionBankForm from 'pages/Rewards/components/QuestionBankForm';

type ComponentConfig = {
  headerTitle: string;
  component: (props?: unknown) => React.ReactElement | null;
  showBackIcon: boolean;
};

const componentMap: {
  [key in TComponent]: ComponentConfig;
} = {
  [TComponent.DEFAULT]: {
    headerTitle: 'Headlamp gives back',
    component: () => <DrawerContent />,
    showBackIcon: false,
  },
  [TComponent.REFERRAL]: {
    headerTitle: 'Refer a clinician to Headlamp',
    component: (props?: unknown) => <ReferralForm {...(props || {}) as IReferralFormProps} />,
    showBackIcon: true,
  },
  [TComponent.QUESTION]: {
    headerTitle: 'Contribute to our question bank',
    component: () => <QuestionBankForm />,
    showBackIcon: true,
  },
};

export interface IProps {
  isOpen: boolean;
  onClose: (event: React.KeyboardEvent | React.MouseEvent) => void;
}

export default function RewardsDrawer({ isOpen, onClose }: IProps) {
  const { activeComponent } = React.useContext(RewardsContext);

  return (
    <Drawer
      anchor='right'
      open={isOpen}
      onClose={onClose}
      sx={{ zIndex: theme.zIndex.drawer + 2 }}
      PaperProps={{ sx: { maxWidth: '650px', width: '100%' } }}
      data-testid='rewards-drawer'
    >
      <Box role='grid'>
        <DrawerHeader
          title={componentMap[activeComponent.component].headerTitle}
          onClose={onClose}
          showBackIcon={!!componentMap[activeComponent.component].showBackIcon}
        />
        <Divider />

        <Grid container padding={2}>
          {componentMap[activeComponent.component].component(activeComponent.props)}
        </Grid>
      </Box>
    </Drawer>
  );
}
